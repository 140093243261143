import { Picker } from '@react-native-community/picker';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MspGetNpiInfoResponse, PhoneNumberWithToken } from 'vacctrack';
import MspFunctionsService from '../../services/MspFunctionsService';
import VTButton from '../UI/VTButton';
import VTPicker from '../UI/VTPicker';
import VTText from '../UI/VTText';

interface Props {
  npiInfo: MspGetNpiInfoResponse;
  onSubmit: (value: PhoneNumberWithToken) => void;
  onCancel: () => void;
}

export default ({ npiInfo, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const requestCall = async () => {
    setLoading(true);

    try {
      await MspFunctionsService.instance.mspRequestPhoneVerification(
        npiInfo.npi,
        npiInfo.phoneNumbers[value],
      );
      onSubmit(npiInfo.phoneNumbers[value]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const hasPhoneNumber = !!npiInfo.phoneNumbers.length;

  return (
    <>
      <VTText variant="heading1">{t('mspRegister.verifyPhoneNumber')}</VTText>
      <VTText paragraph>
        {t('mspRegister.npiNameInfo', { name: npiInfo.name })}
      </VTText>
      {hasPhoneNumber ? (
        <>
          <VTText paragraph>{t('mspRegister.verifyCodeInfo')}</VTText>
          {npiInfo.phoneNumbers.length > 1 ? (
            <VTPicker
              value={value}
              onChange={setValue}
              label={t('mspRegister.selectPhoneNumber')}
            >
              {npiInfo.phoneNumbers.map((p, i) => (
                <Picker.Item key={p.token} label={p.phoneNumber} value={i} />
              ))}
            </VTPicker>
          ) : (
            <VTText paragraph>
              {t('mspRegister.verifyPhoneNumberInfo')}
              {'\n'}
              {npiInfo.phoneNumbers[0].phoneNumber}
            </VTText>
          )}
          <VTText variant="caption" paragraph>
            {t('mspRegister.numberNotListed')}
          </VTText>
          <VTButton
            variant="contained"
            title={t('mspRegister.requestCall')}
            disabled={loading}
            onPress={requestCall}
          />
        </>
      ) : (
        <VTText paragraph>{t('mspRegister.noPhoneNumber')}</VTText>
      )}
      <VTButton
        title={t('action.cancel')}
        disabled={loading}
        onPress={onCancel}
      />
    </>
  );
};
