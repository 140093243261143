import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

export function openLink(link: string) {
  if (Platform.OS === 'web') {
    window.open(link, '_blank');
  } else {
    void Linking.openURL(link);
  }
}
