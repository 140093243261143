import { Formik } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { UserPatient } from 'vacctrack';
import { object, SchemaOf, string } from 'yup';
import { dateInputProps } from '../../../util/date';
import { StepValueProps } from '../../Navigation/Navigation';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import VTTextInput from '../../UI/VTTextInput';

export interface PatientDataInputData
  extends Pick<UserPatient, 'firstName' | 'middleName' | 'lastName'> {
  dateOfBirthString: string;
}

const SCHEMA = (): SchemaOf<Omit<PatientDataInputData, 'middleName'>> => object({
  firstName: string().required(),
  lastName: string().required(),
  dateOfBirthString: string().dateOfBirthString().required(),
});

export default ({ value, onSubmit }: StepValueProps<PatientDataInputData>) => {
  const { t } = useTranslation();
  const field1 = useRef<TextInput>(null);
  const field2 = useRef<TextInput>(null);
  const field3 = useRef<TextInput>(null);

  return (
    <Formik initialValues={value} validationSchema={SCHEMA} onSubmit={onSubmit}>
      <>
        <VTText paragraph>{t('tickets.createPatientDataDescription')}</VTText>
        <VTTextInput
          formik="firstName"
          nextField={field1}
          label={t('settings.patientFirstName')}
          autoCompleteType="name"
          autoCapitalize="words"
        />
        <VTTextInput
          formik="middleName"
          ref={field1}
          nextField={field2}
          label={t('settings.patientMiddleName')}
          autoCompleteType="name"
          autoCapitalize="words"
        />
        <VTTextInput
          formik="lastName"
          ref={field2}
          nextField={field3}
          label={t('settings.patientLastName')}
          autoCompleteType="name"
          autoCapitalize="words"
        />
        <VTTextInput
          formik="dateOfBirthString"
          ref={field3}
          nextField={false}
          label={t('settings.patientDateOfBirth')}
          placeholder={t('settings.patientDateOfBirthPlaceholder')}
          {...dateInputProps(t)}
        />
        <VTButton
          formik="submit"
          variant="contained"
          title={t('action.next')}
          margin
        />
      </>
    </Formik>
  );
};
