import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import RemindersService from '../../services/RemindersService';
import theme from '../../util/theme';
import { CertificatesNavigationProp } from '../Certificates/CertificatesNavigator';
import useRouteServiceItem from '../Certificates/hooks/useRouteServiceItem';
import BreakoutSection from '../UI/BreakoutSection';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

interface Props {
  disabled: boolean;
}

export default ({ disabled }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const c = useRouteServiceItem();

  if (!c.serviceItem || RemindersService.isReminder(c.serviceItem)) {
    return null;
  }

  return (
    <BreakoutSection style={styles.root}>
      <VTText variant="label">{t('issues.title')}</VTText>
      <VTText paragraph>
        {c.serviceItem.issueCount
          ? t('issues.issueCount', { count: c.serviceItem.issueCount })
          : t('issues.intro')}
      </VTText>
      <VTButton
        disabled={disabled}
        variant="contained"
        startIcon="issue"
        title={t('issues.startReport')}
        onPress={() =>
          navigation.navigate('IssuesCreateScreen', {
            id: c.serviceItem?.id ?? '',
          })
        }
      />
    </BreakoutSection>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
});
