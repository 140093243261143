import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/useUser';
import { useAppSelector } from '../../store';
import { CertificatesNavigationProp } from '../Certificates/CertificatesNavigator';
import IconButton from '../UI/IconButton';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const readTime = useUser()?.notificationsReadTime ?? 0;

  const broadcasts = useAppSelector(({ root: { broadcasts } }) => broadcasts);
  const unreadCount = broadcasts.filter((b) => b.created > readTime).length;

  return (
    <IconButton
      color={unreadCount ? 'secondaryLight' : 'background'}
      iconName={unreadCount ? 'notificationsUnread' : 'notifications'}
      label={t('action.notifications')}
      onPress={() => navigation.navigate('NotificationsScreen')}
    />
  );
};
