import { LinkingOptions } from '@react-navigation/native';
import config from './config';

export const linkingOptions: LinkingOptions = {
  prefixes: [config.appUrl],
  config: {
    screens: {
      LandingScreen: 'landing',
      MainNavigator: {
        screens: {
          CertificatesNavigator: {
            path: 'certificates',
            initialRouteName: 'CertificatesScreen',
            screens: {
              CertificatesScreen: '',
              CertificatesDetailScreen: 'view',
              ReportsDetailScreen: 'report',
              RemindersCreateScreen: 'new-reminder',
              RemindersDetailScreen: 'reminder',
              CertificatesGetNewScreen: 'new',
              ReportsCreateScreen: 'new-report',
              CertificatesShareScreen: 'share',
              IssuesCreateScreen: 'vaccwatch',
              NotificationsScreen: 'notifications',
            },
          },
          VerifyNavigator: {
            screens: {
              VerifyScreen: 'verify',
            },
          },
          TicketsNavigator: {
            path: 'tickets',
            initialRouteName: 'TicketsNavigator',
            screens: {
              TicketsScreen: '',
              CreateTicketScreen: 'create',
            },
          },
          MspRegisterNavigator: {
            screens: {
              MspRegisterScreen: 'msp-register',
            },
          },
          LoginNavigator: {
            screens: {
              LoginScreen: 'login',
            },
          },
          SettingsNavigator: {
            path: 'settings',
            initialRouteName: 'SettingsScreen',
            screens: {
              SettingsScreen: '',
              ChangeEmailScreen: 'email',
              ChangePasswordScreen: 'password',
              ChangeLanguageScreen: 'language',
              PatientInfoScreen: 'patient',
              MspInfoScreen: 'msp',
            },
          },
        },
      },
      EmailVerifiedScreen: 'email-verified',
      NotFound: '*',
    },
  },
};
