import { Certificate, ServiceItem } from 'vacctrack';
import { Id } from './FirestoreService';
import FirestoreSubcollectionService from './FirestoreSubcollectionService';

export default class CertificatesService extends FirestoreSubcollectionService<Certificate> {
  static instance = new CertificatesService();

  static isCertificate(item: ServiceItem): item is Certificate {
    return !!(item as Certificate).msp;
  }

  constructor() {
    super('users', 'certificates');
  }

  getDefaultReleaseCode(certificate: Certificate & Id) {
    const defaultRelease = certificate.releases.find((r) => r.isDefault);

    if (!defaultRelease) {
      throw Error('no default release');
    }

    return `${certificate.userId}${certificate.id}${defaultRelease.id}`;
  }

  getPdfFileName(releaseCode: string) {
    const userId = releaseCode.substr(0, 28);
    const rest = releaseCode.substr(28);

    return `/${userId}/certificate-${rest}.pdf`;
  }
}
