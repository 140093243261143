import { ViewStyle } from 'react-native';

export type Color = keyof typeof theme.palette;

const SPACING = 8;

const palette = {
  primaryLight: '#99d3ff',
  primary: '#007FDD',
  secondary: '#f2b416',
  secondaryLight: '#F2E122',
  accent: '#01E6BE',
  // success: '#37AB09',
  error: '#E64100',
  text: '#252525',
  textSecondary: '#616161',
  divider: '#d6d6d6',
  backgroundSecondary: '#f5f5f5',
  background: '#ffffff',
};

const border = {
  default: {
    borderStyle: 'solid',
    borderWidth: 1,
    color: palette.text,
  } as ViewStyle,
};

const fontFamily = {
  regular: 'SourceSansPro_400Regular',
  semiBold: 'SourceSansPro_600SemiBold',
  bold: 'SourceSansPro_700Bold',
};

const typography = {
  default: {
    fontFamily: fontFamily.regular,
    color: palette.text,
  } as ViewStyle,
};

const action = {
  disabled: {
    opacity: 0.666,
  } as ViewStyle,
};

const spacing = (n: number) => {
  return n * SPACING;
};

const theme = {
  border,
  breakpoints: {
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  action,
  spacing,
  palette,
  typography: {
    fontFamily,
    heading1: {
      ...typography.default,
      fontSize: 26,
      fontFamily: fontFamily.bold,
      marginBottom: spacing(2),
    },
    heading2: {
      ...typography.default,
      fontSize: 20,
    },
    listHeading: {
      ...typography.default,
      fontFamily: fontFamily.semiBold,
      fontSize: 16,
    },
    body1: {
      ...typography.default,
      fontSize: 16,
    },
    label: {
      ...typography.default,
      fontFamily: fontFamily.semiBold,
    },
    caption: {
      ...typography.default,
      fontSize: 14,
    },
  },
};

const shape = {
  borderRadius: theme.spacing(1),
};

export default {
  ...theme,
  border: {
    ...theme.border,
    black: {
      ...theme.border.default,
      borderColor: theme.palette.text,
      borderRadius: shape.borderRadius,
    },
  },
  shape,
  surface: {
    card: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.background,
    },
  },
};
