import * as React from 'react';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import IconButton from '../UI/IconButton';
import VTTextInput, { VTTextInputProps } from '../UI/VTTextInput';

export default forwardRef<TextInput, VTTextInputProps>((props, ref) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <VTTextInput
      ref={ref}
      textContentType="password"
      autoCompleteType="password"
      endButton={
        <IconButton
          color="textSecondary"
          iconName={showPassword ? 'hidePassword' : 'showPassword'}
          label={
            showPassword ? t('login.hidePassword') : t('login.showPassword')
          }
          onPress={() => setShowPassword((value) => !value)}
          size="small"
          edge="end"
        />
      }
      secureTextEntry={!showPassword}
      passwordRules="minlength: 6;"
      autoCorrect={false}
      {...props}
    />
  );
});
