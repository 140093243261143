import * as React from 'react';
import Svg, {
  Circle,
  Path,
  Polygon,
  Polyline,
  Rect,
  SvgProps,
} from 'react-native-svg';

export default function MicroReport(props: SvgProps) {
  return (
    <Svg viewBox="0 0 348.94 256" {...props}>
      <Polygon
        points="348.94 256 0 256 0 0.01 349.3 0.01 348.94 256"
        fill="#275873"
      />
      <Rect x="14.43" y="11.15" width="320.61" height="233.72" fill="#fafafa" />
      <Rect x="29.94" y="224.63" width="118.76" height="6.36" fill="#394d79" />
      <Rect x="29.94" y="210.71" width="98.65" height="6.36" fill="#394d79" />
      <Rect x="29.94" y="196.8" width="109.78" height="6.36" fill="#394d79" />
      <Path
        d="M299.37,132.79a4.05,4.05,0,0,1,2.89,1.29l9.72,9.73a4,4,0,0,1,0,5.85l-7.6,7.6L288.8,141.68l7.6-7.6a4.21,4.21,0,0,1,3-1.29"
        fill="#f2b416"
      />
      <Polyline
        points="284.39 146.09 299.98 161.67 253.92 207.81 238.26 207.81 238.26 192.15 284.39 146.09"
        fill="#f2b416"
      />
      <Path
        d="M223.06,185l-15.2,15.2H177.45a15.24,15.24,0,0,1-15.2-15.2V78.6a15.25,15.25,0,0,1,15.2-15.21h31.77a22.71,22.71,0,0,1,42.87,0h31.77a15.25,15.25,0,0,1,15.2,15.21V109l-15.2,15.2V78.6h-15.2V93.8h-76V78.6h-15.2V185h45.61"
        fill="#f2b416"
      />
      <Circle cx="230.66" cy="70.99" r="7.6" fill="#ffffff" />
    </Svg>
  );
}
