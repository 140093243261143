import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../../../hooks/useAuthUser';
import RemindersService from '../../../services/RemindersService';
import { INITIAL_PRODUCT_SELECTOR_DATA } from '../../../util/constants';
import { stringToDate } from '../../../util/date';
import dialog from '../../../util/dialog';
import ProductSelector, {
  ProductSelectorData,
} from '../../Shared/ProductSelector';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import { CertificatesNavigationProp } from '../CertificatesNavigator';
import RemindersDateStep, { RemindersDateData } from './RemindersDateStep';

enum Step {
  ProductSelect,
  DateSelect,
}

const INITIAL_DATE_DATA: RemindersDateData = {
  shotNumber: 1,
  shotNumberOf: 1,
  reminderDateString: '',
};

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const uid = useAuthUser()!.uid;

  const [step, setStep] = useState<Step>(0);
  const [
    productSelectorData,
    setProductSelectorData,
  ] = useState<ProductSelectorData>(INITIAL_PRODUCT_SELECTOR_DATA);
  const [dateData, setDateData] = useState<RemindersDateData>(
    INITIAL_DATE_DATA,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setStep(0);
        setProductSelectorData(INITIAL_PRODUCT_SELECTOR_DATA);
        setIsSubmitting(false);
      };
    }, []),
  );

  const submit = async (data: RemindersDateData) => {
    setDateData(data);
    setIsSubmitting(true);

    try {
      await RemindersService.instance.collection(uid).add({
        id: '',
        created: Date.now(),
        userId: uid,
        description: productSelectorData.name!,
        reminderDate: stringToDate(data.reminderDateString!)!.getTime(),
        shotNumber: data.shotNumber,
        shotNumberOf: data.shotNumberOf,
      });

      await dialog.alert(t('certificates.remindersCreateSuccess'));

      navigation.popToTop();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const next = () =>
    setStep((s) => Math.min(s + 1, Object.keys(Step).length - 1));
  const prev = () => setStep((s) => Math.max(0, s - 1));

  const content = (() => {
    switch (step) {
      case Step.ProductSelect:
        return (
          <ProductSelector
            value={productSelectorData}
            onSubmit={(d) => {
              setProductSelectorData(d);

              next();
            }}
            nameOnly
          />
        );
      case Step.DateSelect:
        return (
          <RemindersDateStep
            value={dateData}
            onSubmit={submit}
            onBack={prev}
            isSubmitting={isSubmitting}
          />
        );
    }
  })();

  return (
    <DefaultLayout scrollable>
      <Card title={t('certificates.createReminder')} margin>
        {content}
      </Card>
    </DefaultLayout>
  );
};
