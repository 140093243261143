import { useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { actions as rootActions } from '../../store/root.slice';
import theme from '../../util/theme';
import Card from '../UI/Card';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { name: routeName } = useRoute();

  const flashMessage = useAppSelector(
    ({ root: { flashMessage } }) => flashMessage,
  );

  const onClose = useCallback(() => {
    dispatch(rootActions.setFlashMessage(undefined));
  }, [dispatch]);

  const isCurrentScreen = flashMessage?.routeName === routeName;

  useEffect(() => {
    if (!isCurrentScreen) {
      return;
    }

    return navigation.addListener('blur', onClose);
  }, [isCurrentScreen, navigation, onClose, routeName]);

  if (!isCurrentScreen || !flashMessage) {
    return null;
  }

  return (
    <Card style={styles.root}>
      <VTText>{flashMessage.message}</VTText>
      <VTButton
        title={t('action.close')}
        size="small"
        onPress={onClose}
        style={styles.button}
      />
    </Card>
  );
};

const styles = StyleSheet.create({
  root: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    justifyContent: 'flex-end',
  },
});
