import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import usePersistentScreen from '../../../hooks/usePersistentScreen';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import { TicketsNavigationProp } from '../TicketsNavigator';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<TicketsNavigationProp>();

  usePersistentScreen('TicketsNavigator');

  return (
    <DefaultLayout scrollable>
      <Card title={t('tickets.title')}>
        <VTText paragraph>{t('tickets.homeDescription')}</VTText>
        <VTButton
          variant="contained"
          title={t('tickets.start')}
          onPress={() => navigation.navigate('CreateTicketScreen')}
        />
      </Card>
    </DefaultLayout>
  );
};
