import { Formik } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { object, SchemaOf, string } from 'yup';
import { REASONABLE_REMINDER_DATE_RANGE } from '../../util/constants';
import { dateInputProps } from '../../util/date';
import { DateOptions } from '../../util/schema';
import strings from '../../util/strings';
import { StepValueProps } from '../Navigation/Navigation';
import VTButton from '../UI/VTButton';
import VTTextInput from '../UI/VTTextInput';

export interface ServiceDateData {
  serviceDateString: string;
  reminderDateString?: string;
}

interface Props extends StepValueProps<ServiceDateData> {
  description?: React.ReactNode;
  isTest?: boolean;
  submitTitle?: string;
  isSubmitting?: boolean;
}

const REASONABLE_SERVICE_DATE_RANGE: DateOptions = {
  min: 2524554080000, // 80 years in the past
  max: 31556926000, // 1 year in the future
};

const SCHEMA = (): SchemaOf<ServiceDateData> => object({
  serviceDateString: string()
    .dateString(REASONABLE_SERVICE_DATE_RANGE)
    .required(),
  reminderDateString: string().dateString({
    ...REASONABLE_REMINDER_DATE_RANGE,
    optional: true,
  }),
});

export default ({
  value,
  onSubmit,
  description,
  isTest,
  submitTitle,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation();
  const reminderDateField = useRef<TextInput>(null);


  return (
    <Formik initialValues={value} validationSchema={SCHEMA} onSubmit={onSubmit}>
      <>
        {description}
        <VTTextInput
          formik="serviceDateString"
          nextField={reminderDateField}
          label={t('certificates.serviceDate', {
            context: strings.serviceContext({ isTest }),
          })}
          placeholder={t('certificates.serviceDatePlaceholder')}
          {...dateInputProps(t)}
        />
        {!isTest && (
          <VTTextInput
            formik="reminderDateString"
            ref={reminderDateField}
            label={t('tickets.reminderDate')}
            placeholder={t('tickets.expiryDatePlaceholder')}
            nextField={false}
            {...dateInputProps(t)}
            helperText={t('tickets.reminderDateHelper')}
          />
        )}
        <VTButton
          formik="submit"
          variant="contained"
          title={submitTitle ?? t('action.next')}
          pending={isSubmitting}
          margin
        />
      </>
    </Formik>
  );
};
