import { User } from 'vacctrack';
import FirestoreService, { ref$ } from './FirestoreService';

export default class UsersService extends FirestoreService<User> {
  static instance = new UsersService();

  constructor() {
    super('users');
  }

  get$(id: string) {
    return ref$(this.collection().doc(id));
  }
}
