import { useFormikContext } from 'formik';
import * as React from 'react';
import { forwardRef } from 'react';
import { StyleSheet, TextInput, TextInputProps, View } from 'react-native';
import useErrorTranslation from '../../hooks/useErrorTranslation';
import theme from '../../util/theme';
import HelperText from './HelperText';
import VTText from './VTText';

export interface VTTextInputProps extends TextInputProps {
  formik?: string;
  label?: string;
  endButton?: React.ReactNode;
  helperText?: React.ReactNode;
  nextField?: React.RefObject<TextInput> | boolean;
  error?: boolean;
}

export const COMMON_TEXT_INPUT_PROPS: Record<
  string,
  Partial<TextInputProps>
> = {
  email: {
    keyboardType: 'email-address',
    autoCompleteType: 'email',
    textContentType: 'emailAddress',
    autoCapitalize: 'none',
  },
};

export default forwardRef<TextInput, VTTextInputProps>(
  (
    {
      formik,
      helperText,
      label,
      endButton,
      nextField,
      error = false,
      style,
      value,
      ...props
    },
    ref,
  ) => {
    const tError = useErrorTranslation();
    const ctx = formik
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useFormikContext<{
          [key: string]: string;
        }>()
      : undefined;

    const onSubmit = () => {
      if (nextField) {
        if (typeof nextField === 'boolean') {
          return;
        }

        nextField?.current?.focus();
        return;
      }

      if (!ctx?.isSubmitting) {
        ctx?.submitForm();
      }
    };

    const hasError = ctx && ctx.touched[formik!] && !!ctx.errors[formik!];
    const helperOrErrorText =
      ctx && hasError ? tError(ctx.errors[formik!]) : helperText;

    return (
      <View style={style}>
        {label && (
          <VTText variant="label" style={styles.label}>
            {label}
          </VTText>
        )}
        <View style={[styles.root, !helperOrErrorText && styles.margin]}>
          <TextInput
            ref={ref}
            enablesReturnKeyAutomatically
            returnKeyType={nextField === false ? 'send' : 'next'}
            blurOnSubmit={nextField === false}
            value={`${ctx ? ctx.values[formik!] : value}`}
            onChangeText={ctx?.handleChange(formik)}
            onSubmitEditing={onSubmit}
            accessibilityLabel={label ?? props.placeholder}
            {...props}
            style={styles.textInput}
          />
          {endButton}
        </View>
        {helperOrErrorText && (
          <HelperText error={error || hasError} style={styles.margin}>
            {helperOrErrorText}
          </HelperText>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInput: {
    maxWidth: '100%',
    flex: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background,
    // margin: theme.spacing(0.5),
    ...theme.border.black,
    ...theme.typography.body1,
  },
  margin: {
    marginBottom: theme.spacing(1.5),
  },
  label: {
    marginVertical: theme.spacing(0.5),
    marginHorizontal: theme.spacing(1) + 2,
  },
});
