import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/app';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import useAuthUser from '../../hooks/useAuthUser';
import useUserSpecificStorage from '../../hooks/useUserSpecificStorage';
import UsersFunctionsService from '../../services/UsersFunctionsService';
import { actions as rootActions } from '../../store/root.slice';
import { STORAGE_MAIN_SCREEN, STORAGE_PUSH_TOKEN, STORAGE_STORED_TICKET_PARAM } from '../../util/constants';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import LogoHeader from '../UI/LogoHeader';

export default (props: DrawerContentComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<RootNavigationProp>();
  const authUser = useAuthUser();
  const userSpecificStorage = useUserSpecificStorage();

  const logOut = async () => {
    const token = await userSpecificStorage.getItem(STORAGE_PUSH_TOKEN);

    if (token) {
      // noinspection ES6MissingAwait
      UsersFunctionsService.instance.usersPushTokenRemove(token);
    }

    await Promise.all([
      firebase.auth().signOut(),
      AsyncStorage.removeItem(STORAGE_STORED_TICKET_PARAM),
      userSpecificStorage.removeItem(STORAGE_PUSH_TOKEN),
      userSpecificStorage.removeItem(STORAGE_MAIN_SCREEN),
    ]);

    dispatch(rootActions.reset());
    navigation.navigate('LandingScreen');
  };

  return (
    <DrawerContentScrollView {...props}>
      <TouchableOpacity
        onPress={() =>
          navigation.navigate(authUser ? 'MainNavigator' : 'LandingScreen')
        }
      >
        <LogoHeader />
      </TouchableOpacity>
      <DrawerItemList {...props} />
      {authUser ? (
        <DrawerItem label={t('login.logOut')} onPress={logOut} />
      ) : (
        <>
          <DrawerItem
            label={t('landing.getCertificate')}
            onPress={() =>
              navigation.navigate('MainNavigator', {
                screen: 'LoginNavigator',
                params: {
                  screen: 'LoginScreen',
                  params: {
                    mode: 'signUp',
                    type: 'patient',
                  },
                },
              })
            }
          />
          <DrawerItem
            label={t('landing.mspRegister', { context: 'short' })}
            onPress={() =>
              navigation.navigate('MainNavigator', {
                screen: 'LoginNavigator',
                params: {
                  screen: 'LoginScreen',
                  params: {
                    mode: 'signUp',
                    type: 'msp',
                  },
                },
              })
            }
          />
        </>
      )}
    </DrawerContentScrollView>
  );
};
