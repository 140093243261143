import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { Platform } from 'react-native';
import config from './config';

export function initFirebase() {
  // well, this is awkward
  // https://github.com/expo/browser-polyfill/issues/35
  if (Platform.OS !== 'web') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.Image = undefined;
  }

  firebase.initializeApp(config.firebaseConfig);

  if (config.functionsEmulatorUrl) {
    firebase.functions().useFunctionsEmulator(config.functionsEmulatorUrl);
  }
}
