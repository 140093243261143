import AsyncStorage from '@react-native-async-storage/async-storage';
import { DefaultTheme, NavigationContainer, NavigationContainerProps, Theme } from '@react-navigation/native';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useAppLifecycle from '../../hooks/useAppLifecycle';
import { linkingOptions } from '../../util/linking';
import theme from '../../util/theme';
import useInitialLoading from './hooks/useInitialLoading';
import RootNavigator from './RootNavigator';

export interface StepValueProps<T> {
  value: T;
  onSubmit: (data: T) => void;
  onBack?: () => void;
}

const STORAGE_NAVIGATION_STATE = 'storage.navigation-state';

const themeOptions: Theme = merge(DefaultTheme, {
  colors: pick(theme.palette, ['primary', 'background']),
});
const shouldPersistState = __DEV__ && Platform.OS !== 'web';

export default () => {
  const [devStateReady, setDevStateReady] = useState(!__DEV__);
  const [initialState, setInitialState] = useState();

  const isLoadingComplete = useInitialLoading();

  useAppLifecycle();

  useEffect(() => {
    const restoreState = async () => {
      try {
        const savedStateString = await AsyncStorage.getItem(
          STORAGE_NAVIGATION_STATE,
        );
        const state = savedStateString
          ? JSON.parse(savedStateString)
          : undefined;

        if (state !== undefined) {
          setInitialState(state);
        }
      } finally {
        setDevStateReady(true);
      }
    };

    if (shouldPersistState && !devStateReady) {
      void restoreState();
    }
  }, [devStateReady]);

  const onStateChange: NavigationContainerProps['onStateChange'] = shouldPersistState
    ? (state) =>
        AsyncStorage.setItem(STORAGE_NAVIGATION_STATE, JSON.stringify(state))
    : undefined;

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer
        linking={linkingOptions}
        theme={themeOptions}
        initialState={initialState}
        onStateChange={onStateChange}
      >
        <RootNavigator />
      </NavigationContainer>
    </SafeAreaProvider>
  );
};
