import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CertificatesFunctionsService from '../../../services/CertificatesFunctionsService';
import { TICKET_PARAM_NAME } from '../../../util/constants';
import dialog from '../../../util/dialog';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import VTActivityIndicator from '../../UI/VTActivityIndicator';
import VTText from '../../UI/VTText';
import {
  CertificatesNavigationProp,
  CertificatesNavigatorParamList,
} from '../CertificatesNavigator';
import CodeInputManual from './CodeInputManual';
import CodeInputScan from './CodeInputScan';
import CreateNewCard from './CreateNewCard';
import ProofInput from './ProofInput';

export type InputMode = 'scan' | 'manual' | 'url' | 'proof';

export interface CodeInputProps {
  value?: string;
  onResult: (code: string) => void;
  onCancel: () => void;
  loading: boolean;
}

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const route = useRoute<
    RouteProp<CertificatesNavigatorParamList, 'CertificatesGetNewScreen'>
  >();
  const [loading, setLoading] = useState(false);
  const inputMode = route.params?.mode;
  const ticketParam = route.params?.[TICKET_PARAM_NAME];

  const onResult: CodeInputProps['onResult'] = useCallback(
    async (ticketId) => {
      setLoading(true);

      try {
        const {
          certificateId,
        } = await CertificatesFunctionsService.instance.certificatesCreate({
          ticketId,
        });

        await dialog.alert(t('certificates.createSuccess'));

        navigation.replace('CertificatesDetailScreen', {
          id: certificateId,
        });
      } catch (e) {
        await dialog.alert(
          t('certificates.error', t('error.generic'), {
            context: e.code,
          }),
        );

        if (ticketParam) {
          navigation.popToTop();
        }
      } finally {
        setLoading(false);
      }
    },
    [navigation, t, ticketParam],
  );

  useEffect(() => {
    if (ticketParam) {
      onResult(ticketParam);

      navigation.setParams({
        mode: 'url',
        [TICKET_PARAM_NAME]: undefined,
      });
    }
  }, [navigation, onResult, ticketParam]);

  const content = (() => {
    if (!inputMode && !ticketParam) {
      return <CreateNewCard />;
    }

    const props: CodeInputProps = {
      onResult,
      onCancel: () => navigation.popToTop(),
      loading,
    };

    switch (inputMode) {
      case 'scan':
        return <CodeInputScan {...props} />;
      case 'manual':
        return <CodeInputManual {...props} />;
      case 'proof':
        return <ProofInput onCancel={props.onCancel} />;
      default:
        return (
          <>
            <VTActivityIndicator />
            <VTText variant="caption" style={{ textAlign: 'center' }}>
              {t('certificates.validatePending')}
            </VTText>
          </>
        );
    }
  })();

  return (
    <DefaultLayout scrollable>
      <Card title={t('certificates.create')} margin>
        {content}
      </Card>
    </DefaultLayout>
  );
};
