import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import useAuthUser from '../../hooks/useAuthUser';
import useMspVerified from '../../hooks/useMspVerified';
import useUserTypeCheck from '../../hooks/useUserTypeCheck';
import theme from '../../util/theme';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import Divider from '../UI/Divider';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import { SettingsNavigationProp } from './SettingsNavigator';

export default () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const navigation = useNavigation<SettingsNavigationProp>();
  const showPatientFeatures = useUserTypeCheck('patient');
  const mspVerified = useMspVerified();

  return (
    <DefaultLayout>
      <Card padding="dense">
        {authUser && (
          <>
            <VTText variant="label" style={styles.loginInfo}>
              {t('settings.loginInfo', { username: authUser.email })}
            </VTText>
            <Divider />
            <VTButton
              title={t('settings.changeEmail')}
              onPress={() => navigation.navigate('ChangeEmailScreen')}
              margin
            />
            <Divider />
            <VTButton
              title={t('settings.changePassword')}
              onPress={() => navigation.navigate('ChangePasswordScreen')}
              margin
            />
          </>
        )}
        {showPatientFeatures && (
          <>
            <Divider />
            <VTButton
              title={t('settings.patientInfo')}
              onPress={() => navigation.navigate('PatientInfoScreen')}
              margin
            />
          </>
        )}
        {mspVerified && (
          <>
            <Divider />
            <VTButton
              title={t('settings.mspInfo')}
              onPress={() => navigation.navigate('MspInfoScreen')}
              margin
            />
          </>
        )}
        {Platform.OS === 'web' && (
          <VTButton
            style={{
              marginTop: authUser ? theme.spacing(4) : theme.spacing(1),
            }}
            size={authUser ? 'small' : undefined}
            startIcon={authUser ? 'changeLanguage' : undefined}
            title={t('settings.changeLanguage')}
            onPress={() => navigation.navigate('ChangeLanguageScreen')}
          />
        )}
      </Card>
    </DefaultLayout>
  );
};

const styles = StyleSheet.create({
  loginInfo: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
    textAlign: 'center',
  },
});
