import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { CertificatesVerifyWithCodeResponse } from 'vacctrack';
import theme from '../../util/theme';
import CertificateLayout, {
  certificateLayoutStyles,
} from '../UI/CertificateLayout';
import MicroCertInvalid from '../UI/SVG/MicroCertInvalid';
import MicroCertValid from '../UI/SVG/MicroCertValid';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

type ErrorResult = { result: null };

type SimpleResult = { result: boolean };

export type VerifyResultType =
  | CertificatesVerifyWithCodeResponse
  | SimpleResult
  | ErrorResult;

interface Props {
  data: VerifyResultType;
  onBack: () => void;
}

export default ({ data, onBack }: Props) => {
  const { t } = useTranslation();

  const innerContent = (() => {
    if (data.result === null || data.result === false) {
      return (
        <View
          style={[certificateLayoutStyles.row, certificateLayoutStyles.section]}
        >
          <MicroCertInvalid style={certificateLayoutStyles.icon} />
          <View style={certificateLayoutStyles.cell}>
            <VTText variant="heading2" style={styles.titleError}>
              {t('verify.errorTitle')}
            </VTText>
            <VTText paragraph>
              {t('verify.error', {
                context: data.result === false ? 'file' : 'code',
              })}
            </VTText>
          </View>
        </View>
      );
    }

    if (data.result === true) {
      return (
        <View
          style={[certificateLayoutStyles.row, certificateLayoutStyles.section]}
        >
          <MicroCertValid style={certificateLayoutStyles.icon} />
          <View style={certificateLayoutStyles.cell}>
            <VTText variant="heading2" style={styles.title}>
              {t('verify.validSimpleTitle')}
            </VTText>
            <VTText paragraph>{t('verify.validSimpleDescription')}</VTText>
          </View>
        </View>
      );
    }

    return (
      <CertificateLayout
        title={
          <VTText variant="heading2" style={styles.title}>
            {t('verify.valid')}
          </VTText>
        }
        variant="verify"
        data={data.result}
      />
    );
  })();

  return (
    <>
      {innerContent}
      <VTButton variant="contained" title={t('action.back')} onPress={onBack} />
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    color: theme.palette.primary,
  },
  titleError: {
    color: theme.palette.error,
  },
});
