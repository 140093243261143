import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../util/theme';
import MonospaceText from './MonospaceText';

export default (props: PropsWithChildren<object>) => (
  <MonospaceText {...props} style={styles.code} />
);

const styles = StyleSheet.create({
  code: {
    fontSize: 36,
    textAlign: 'center',
    marginVertical: theme.spacing(2),
  },
});
