import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import i18next, { BackendModule, LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Platform } from 'react-native';
import { loadLanguage, locales } from '../locales';
import { STORAGE_LANGUAGE } from './constants';

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  async detect(callback) {
    const stored =
      Platform.OS === 'web'
        ? await AsyncStorage.getItem(STORAGE_LANGUAGE)
        : null;

    return callback(stored ?? Localization.locale.split('-')[0]);
  },
  init: () => {
    return undefined;
  },
  cacheUserLanguage: () => {
    return undefined;
  },
};

const languageLoader: BackendModule = {
  type: 'backend',
  init: () => undefined,
  create: () => undefined,
  async read(language, namespace, callback) {
    try {
      callback(null, await loadLanguage(language, namespace));
    } catch (e) {
      callback(e, false);
    }
  },
};

export default () => {
  let instance = i18next.use(languageDetector).use(initReactI18next);

  if (Platform.OS === 'web') {
    instance = instance.use(languageLoader);
  }

  return instance.init({
    ...(Platform.OS !== 'web' && locales),
    fallbackLng: 'en',
    ns: 'default',
    defaultNS: 'default',
    supportedLngs: ['en', 'es', 'fr'],
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
  });
};
