import * as React from 'react';
import { useTranslation } from 'react-i18next';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

interface Props {
  loading: boolean;
  onActivate: () => void;
}

export default ({ loading, onActivate }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <VTText paragraph>
        {loading ? t('verify.verifyPending') : t('verify.scanInstructions')}
      </VTText>
      <VTButton
        variant="contained"
        title={t('verify.activate')}
        startIcon="qrCode"
        onPress={onActivate}
        pending={loading}
      />
    </>
  );
};
