import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../util/theme';

const COLORS = [theme.palette.primary, theme.palette.accent];

export default ({
  style,
  ...props
}: PropsWithChildren<Omit<LinearGradientProps, 'colors'>>) => (
  <LinearGradient colors={COLORS} style={[style, styles.root]} {...props} />
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
