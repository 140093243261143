import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import theme from '../../util/theme';

interface Props {
  style?: StyleProp<ViewStyle>;
  paddingBottom?: boolean;
}

export default ({
  style,
  children,
  paddingBottom,
}: PropsWithChildren<Props>) => {
  return (
    <View style={[styles.root, style, paddingBottom && styles.paddingBottom]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    ...theme.border.default,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    paddingTop: theme.spacing(1.25),
    paddingHorizontal: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginHorizontal: -theme.spacing(1) - 1,
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1.5),
  },
});
