// dummy export. on web we use code splitting
// noinspection JSUnusedGlobalSymbols
export const locales = {};

// namespace is currently hardcoded
// noinspection JSUnusedLocalSymbols
export const loadLanguage = (lang: string, namespace: string) => {
  switch (lang) {
    case 'en':
      return import('./en/default.json');
    case 'es':
      return import('./es/default.json');
    case 'fr':
      return import('./fr/default.json');
  }
};
