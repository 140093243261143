import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VTButton from '../../UI/VTButton';
import VTSwitch from '../../UI/VTSwitch';
import VTText from '../../UI/VTText';

interface Props {
  onNext: () => void;
  onBack: () => void;
  isSubmitting: boolean
}

export default ({ onNext, onBack, isSubmitting }: Props) => {
  const { t } = useTranslation();
  const [agreed, setAgreed] = useState(false)

  return (
    <>
      <VTText paragraph>{t('settings.patientHipaaInfo')}</VTText>
      <VTText variant="label">
        {t('settings.patientHipaaInfo', { context: '1' })}
      </VTText>
      <VTText paragraph>
        {t('settings.patientHipaaInfo', { context: '1a' })}
        {'\n'}
        {t('settings.patientHipaaInfo', { context: '1b' })}
        {'\n\n'}
        {t('settings.patientHipaaInfo', { context: '1c' })}
        {'\n'}
        {format(addDays(addYears(new Date(), 3), -1), 'P')}
      </VTText>
      <VTText variant="label">
        {t('settings.patientHipaaInfo', { context: '2' })}
      </VTText>
      <VTText paragraph>
        {t('settings.patientHipaaInfo', { context: '2a' })}
        {'\n\n'}
        {t('settings.patientHipaaInfo', { context: '2b' })}
        {'\n\n'}
        {t('settings.patientHipaaInfo', { context: '2c' })}
        {'\n\n'}
        {t('settings.patientHipaaInfo', { context: '2d' })}
        {'\n\n'}
        {t('settings.patientHipaaInfo', { context: '2e' })}
      </VTText>
      <VTSwitch
        label={t('settings.agreedToTerms')}
        value={agreed}
        onValueChange={setAgreed}
      />
      <VTButton
        variant="contained"
        disabled={!agreed}
        title={t('settings.signAgreement')}
        onPress={onNext}
        pending={isSubmitting}
      />
      <VTButton title={t('action.back')} onPress={onBack} />
    </>
  );
};
