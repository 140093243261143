import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CertificatesFunctionsService from '../../../services/CertificatesFunctionsService';
import config from '../../../util/config';
import {
  INITIAL_DATE_DATA,
  INITIAL_PATIENT_DATA,
  INITIAL_PRODUCT_DATA,
  INITIAL_PRODUCT_SELECTOR_DATA,
} from '../../../util/constants';
import { stringToDate } from '../../../util/date';
import ProductDataInput, {
  ProductDataInputData,
} from '../../Shared/ProductDataInput';
import ProductSelector, {
  ProductSelectorData,
} from '../../Shared/ProductSelector';
import ServiceDateInput, {
  ServiceDateData,
} from '../../Shared/ServiceDateInput';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import CreateShowTicket from './CreateShowTicket';
import CreateSuccess from './CreateSuccess';
import PatientDataInput, { PatientDataInputData } from './PatientDataInput';

enum Step {
  PatientData,
  ProductSelect,
  DateData,
  ProductData,
  Success,
  ShowTicket,
}

function isServiceDateData(
  data: ServiceDateData | ProductDataInputData,
): data is ServiceDateData {
  return !!(data as ServiceDateData).serviceDateString;
}

export default () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<Step>(0);
  const [patientData, setPatientData] = useState<PatientDataInputData>(
    INITIAL_PATIENT_DATA,
  );
  const [dateData, setDateData] = useState<ServiceDateData>(
    INITIAL_DATE_DATA(),
  );
  const [
    productSelectorData,
    setProductSelectorData,
  ] = useState<ProductSelectorData>(INITIAL_PRODUCT_SELECTOR_DATA);
  const [ticketId, setTicketId] = useState<string>();
  const [productData, setProductData] = useState<ProductDataInputData>(
    INITIAL_PRODUCT_DATA,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setStep(0);
        setDateData(INITIAL_DATE_DATA());
        setProductSelectorData(INITIAL_PRODUCT_SELECTOR_DATA);
        setTicketId(undefined);
        setPatientData(INITIAL_PATIENT_DATA);
        setProductData(INITIAL_PRODUCT_DATA);
        setIsSubmitting(false);
      };
    }, []),
  );

  const submit = async (data: ServiceDateData | ProductDataInputData) => {
    let pd: ProductDataInputData;
    let sd: ServiceDateData;

    if (isServiceDateData(data)) {
      sd = data;
      pd = productData;
    } else {
      sd = dateData;
      pd = data;
    }

    setProductData(pd);
    setIsSubmitting(true);

    const expiryDate = stringToDate(pd.expiryDateString!)?.getTime();
    const reminderDate = stringToDate(sd.reminderDateString!)?.getTime();

    try {
      const {
        ticketId,
      } = await CertificatesFunctionsService.instance.certificatesCreateTicket({
        firstName: patientData.firstName,
        middleName: patientData.middleName,
        lastName: patientData.lastName,
        dateOfBirth: stringToDate(patientData.dateOfBirthString)!.getTime(),
        serviceDate: stringToDate(sd.serviceDateString)!.getTime(),
        productId: productSelectorData.productId!,
        ...(pd.lotNumber && {
          lotNumber: pd.lotNumber,
        }),
        ...(expiryDate && { expiryDate }),
        ...(reminderDate && { reminderDate }),
        shotNumber: pd.shotNumber ?? 1,
        shotNumberOf: pd.shotNumberOf ?? 1,
      });

      setTicketId(ticketId);
      setStep(Step.Success);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const next = () =>
    setStep((s) => Math.min(s + 1, Object.keys(Step).length - 1));
  const prev = () => setStep((s) => Math.max(0, s - 1));

  const content = (() => {
    const formattedCode = ticketId
      ? [ticketId.substr(0, 4), ticketId.substr(4, 4), ticketId.substr(8)].join(
          '-',
        )
      : '';

    const codeValue = `${config.appUrl}/certificates/new?t=${ticketId}`;

    switch (step) {
      case Step.PatientData:
        return (
          <PatientDataInput
            value={patientData}
            onSubmit={(d) => {
              setPatientData(d);
              next();
            }}
          />
        );
      case Step.ProductSelect:
        return (
          <ProductSelector
            description={t('tickets.createProductDescription')}
            value={productSelectorData}
            onSubmit={(d) => {
              setProductSelectorData(d);

              if (d) {
                setProductData((pd) => ({
                  ...pd,
                  shotNumberOf: d.shotNumberOf,
                  isTest: d.isTest,
                }));
                next();
              }
            }}
            onBack={prev}
          />
        );
      case Step.DateData:
        return (
          <ServiceDateInput
            value={dateData}
            onSubmit={(v) => {
              if (productSelectorData.isTest) {
                submit(v);
              } else {
                setDateData(v);
                next();
              }
            }}
            submitTitle={
              productSelectorData.isTest ? t('tickets.create') : undefined
            }
            isSubmitting={isSubmitting}
            isTest={productSelectorData.isTest}
            onBack={prev}
          />
        );
      case Step.ProductData:
        return (
          <ProductDataInput
            submitTitle={t('tickets.create')}
            value={productData}
            onSubmit={submit}
            onBack={prev}
            isSubmitting={isSubmitting}
          />
        );
      case Step.Success:
        return (
          <CreateSuccess
            formattedCode={formattedCode}
            codeValue={codeValue}
            onShowTicket={next}
          />
        );
      case Step.ShowTicket:
        return (
          <CreateShowTicket
            formattedCode={formattedCode}
            codeValue={codeValue}
            onBack={prev}
          />
        );
    }
  })();

  return (
    <DefaultLayout scrollable>
      <Card>{content}</Card>
    </DefaultLayout>
  );
};
