import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import theme from '../../util/theme';

export default () => <View style={styles.root} />;

const styles = StyleSheet.create({
  root: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.divider,
  },
});
