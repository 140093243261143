import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RootNavigationProp } from '../../Navigation/RootNavigator';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';

export default () => {
  const { t } = useTranslation();

  const navigation = useNavigation<RootNavigationProp>();

  return (
    <>
      <VTText paragraph>{t('certificates.hipaaPromptInfo')}</VTText>
      <VTButton
        title={t('certificates.createHipaa')}
        startIcon="signHipaa"
        variant="contained"
        onPress={() =>
          navigation.navigate('MainNavigator', {
            screen: 'SettingsNavigator',
            params: {
              screen: 'PatientInfoScreen',
              initial: false,
            },
          })
        }
      />
    </>
  );
};
