import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import theme from '../../util/theme';
import MenuButton from './MenuButton';

export const navigationHeaderOptions = (
  showMenuButton = false,
): StackNavigationOptions => ({
  headerLeft: showMenuButton ? () => <MenuButton /> : undefined,
  headerStyle: {
    backgroundColor: theme.palette.primary,
  },
  headerBackTitleStyle: {
    color: theme.palette.background,
  },
  headerTintColor: theme.palette.background,
  headerTitleStyle: {
    color: theme.palette.background,
  },
});
