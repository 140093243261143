import * as React from 'react';
import Svg, { Path, Polygon, Rect, SvgProps } from 'react-native-svg';

export default function MicroCertInvalid(props: SvgProps) {
  return (
    <Svg viewBox="0 0 348.94 256" {...props}>
      <Polygon
        points="348.94 256 0 256 0 0.01 349.3 0.01 348.94 256"
        fill="#727272"
      />
      <Rect x="14.43" y="11.15" width="320.61" height="233.72" fill="#fafafa" />
      <Rect x="29.94" y="224.63" width="118.76" height="6.36" fill="#777" />
      <Rect x="29.94" y="210.71" width="98.65" height="6.36" fill="#777" />
      <Rect x="29.94" y="196.8" width="109.78" height="6.36" fill="#777" />
      <Polygon
        points="0 179.82 109.68 70.02 38.82 0.01 0 0.01 0 179.82"
        fill="#dbdbdb"
      />
      <Polygon
        points="162.81 0.01 135.54 27.16 122.43 13.59 131.64 0.01 162.81 0.01"
        fill="#b2b2b2"
      />
      <Polygon
        points="68.95 72.15 142.62 0.01 36.01 0.01 51.6 55.85 68.95 72.15"
        fill="#727272"
      />
      <Polygon
        points="47.34 0.01 77.64 30.9 9.18 101.03 0 92.34 0 0.01 47.34 0.01"
        fill="#b2b2b2"
      />
      <Polygon
        points="350.94 104.57 262.95 192.07 327.76 256 350.94 256 350.94 104.57"
        fill="#ccc"
      />
      <Polygon
        points="155.78 256 225.98 183.82 299.58 256 155.78 256"
        fill="#ccc"
      />
      <Polygon
        points="348.94 245.57 289.35 183.82 215.96 256 348.94 256 348.94 245.57"
        fill="#777"
      />
      <Path
        d="M275.64,127.58l-22.38-25.51,3.12-33.75-33.12-7.53L205.93,31.62,174.74,45l-31.19-13.4L126.21,60.79,93.1,68.22,96.21,102l-22.38,25.6,22.38,25.5L93.1,186.93l33.11,7.52,17.34,29.17,31.19-13.49,31.19,13.4,17.33-29.17,33.12-7.53-3.12-33.75,22.38-25.5m-91.73,45.86H165.56V155.09h18.35v18.35m0-36.69H165.56v-55h18.35Z"
        fill="#e64100"
      />
    </Svg>
  );
}
