import format from 'date-fns/format';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
  Certificate,
  CertificatesVerifyWithCodeResponse,
  Report,
} from 'vacctrack';
import { useAppSelector } from '../../store';
import { PROOF_PROVIDED_ID } from '../../util/constants';
import strings from '../../util/strings';
import theme from '../../util/theme';
import MicroCert from './SVG/MicroCert';
import MicroCertTest from './SVG/MicroCertTest';
import MicroCertValid from './SVG/MicroCertValid';
import MicroReport from './SVG/MicroReport';
import VTText from './VTText';

type VerifyResult = CertificatesVerifyWithCodeResponse['result'];

type Props = {
  title: React.ReactNode;
} & (PropsWithCertificate | PropsWithVerify | PropsWithReport);

type PropsWithCertificate = {
  variant: 'detail';
  data: Certificate;
};

type PropsWithVerify = {
  variant: 'verify';
  data: VerifyResult;
};

type PropsWithReport = {
  variant: 'report';
  data: Report;
};

// we don't destructure the props here just yet
// because we want all that discriminated union goodness
export default (props: Props) => {
  const { t } = useTranslation();
  const {
    title,
    variant,
    data: { product, serviceDate },
  } = props;
  const userPatient = useAppSelector(({ root: { user } }) => user?.patient);
  const patient = props.variant === 'report' ? userPatient : props.data.patient;
  const msp = props.variant === 'report' ? undefined : props.data.msp;
  const isTest = props.variant !== 'report';
  const authority =
    props.variant === 'report' ? undefined : props.data.authority;

  const icon = (() => {
    switch (variant) {
      case 'detail':
        if (isTest) {
          return <MicroCertTest style={certificateLayoutStyles.icon} />;
        } else {
          return <MicroCert style={certificateLayoutStyles.icon} />;
        }
      case 'verify':
        return <MicroCertValid style={certificateLayoutStyles.icon} />;
      case 'report':
        return <MicroReport style={certificateLayoutStyles.icon} />;
    }
  })();

  const additionalInfo = (() => {
    if (props.variant === 'verify') {
      return null;
    }

    const strs: string[] = [];

    if (props.data.product.lotNumber) {
      strs.push(`${t('tickets.lotNumber')}: ${props.data.product.lotNumber}`);
    }

    if (props.data.product.expiryDate) {
      strs.push(
        `${t('tickets.expiryDate')}: ${format(
          props.data.product.expiryDate,
          'P',
        )}`,
      );
    }

    if (!strs.length) {
      return null;
    }

    return <VTText variant="caption">{strs.join(', ')}</VTText>;
  })();

  if (!patient) {
    return null;
  }

  const [firstLine, secondLine, thirdLine, thirdLineValue] = (() => {
    if (authority) {
      return [
        t('certificates.integration'),
        authority.facilityName,
        authority.labName,
        '',
      ];
    }

    if (msp?.id === PROOF_PROVIDED_ID) {
      return [
        t('certificates.proofProvided'),
        t('certificates.proofProvidedRecordCard'),
        `${t('certificates.proofConfidence')}: `,
        msp.npi,
      ];
    }

    return [
      t('certificates.medicalServiceProvider'),
      msp?.name,
      `${t('settings.mspNpiNumber')}: `,
      msp?.npi,
    ];
  })();

  return (
    <>
      <View
        style={[certificateLayoutStyles.row, certificateLayoutStyles.section]}
      >
        {icon}
        <View>
          {title}
          <VTText>{strings.fullName(patient)}</VTText>
          <VTText variant="caption" paragraph>
            {t('settings.patientDateOfBirth')}:{' '}
            {patient.dateOfBirth && format(patient.dateOfBirth, 'P')}
          </VTText>
        </View>
      </View>
      <View style={certificateLayoutStyles.section}>
        <VTText variant="label">
          {t('certificates.serviceName', {
            context: strings.serviceContext(props.data as Certificate),
          })}
        </VTText>
        <VTText>{strings.productTitle(product.name, props.data)}</VTText>
        <VTText variant="caption">
          {product.brand}, {product.manufacturer}
        </VTText>
        {additionalInfo}
        <VTText variant="caption" paragraph>
          {t('certificates.serviceDate', {
            context: strings.serviceContext(props.data as Certificate),
          })}
          : {format(serviceDate, 'P')}
        </VTText>
      </View>
      {msp && (
        <View style={certificateLayoutStyles.section}>
          <VTText variant="label">{firstLine}</VTText>
          <VTText>{secondLine}</VTText>
          <VTText variant="caption" paragraph>
            {thirdLine}
            {thirdLineValue}
          </VTText>
        </View>
      )}
    </>
  );
};

export const certificateLayoutStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  section: {
    marginBottom: theme.spacing(1),
  },
  cell: {
    flex: 1,
  },
  icon: {
    width: theme.spacing(10),
    height: theme.spacing(8.6),
    marginRight: theme.spacing(2),
  },
});
