import { IssuesCreateRequest } from 'vacctrack';
import FunctionsService from './FunctionsService';

export default class IssuesFunctionsService extends FunctionsService {
  static instance = new IssuesFunctionsService();

  async issuesCreate(data: IssuesCreateRequest) {
    return this.call<IssuesCreateRequest>('issuesCreate', data);
  }
}
