import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MspGetNpiInfoResponse, PhoneNumberWithToken } from 'vacctrack';
import useMspVerified from '../../hooks/useMspVerified';
import usePersistentScreen from '../../hooks/usePersistentScreen';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import NpiPrompt from './NpiPrompt';
import PhoneNumberPrompt from './PhoneNumberPrompt';
import CodeInput from './VerificationCodeInput';

export default ({ navigation }: { navigation: RootNavigationProp }) => {
  const dispatch = useDispatch();
  const [npiInfo, setNpiInfo] = useState<MspGetNpiInfoResponse>();
  const [
    selectedPhoneNumber,
    setSelectedPhoneNumber,
  ] = useState<PhoneNumberWithToken>();

  const isMspVerified = useMspVerified();

  usePersistentScreen('MspRegisterNavigator');

  useEffect(() => {
    if (isMspVerified) {
      navigation.navigate('MainNavigator', {
        screen: 'TicketsNavigator',
      });
    }
  }, [dispatch, navigation, isMspVerified]);

  const content = (() => {
    if (selectedPhoneNumber) {
      return <CodeInput onCancel={() => setSelectedPhoneNumber(undefined)} />;
    }

    if (npiInfo) {
      return (
        <PhoneNumberPrompt
          npiInfo={npiInfo}
          onSubmit={setSelectedPhoneNumber}
          onCancel={() => setNpiInfo(undefined)}
        />
      );
    }

    return <NpiPrompt onInfoReceived={setNpiInfo} />;
  })();

  return (
    <DefaultLayout scrollable>
      <Card>{content}</Card>
    </DefaultLayout>
  );
};
