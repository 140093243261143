import { Formik } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { StepValueProps } from '../../Navigation/Navigation';
import VTButton from '../../UI/VTButton';
import VTTextInput from '../../UI/VTTextInput';

export interface DataThree {
  preexistingConditions: string;
  comment: string;
}

interface Props extends StepValueProps<DataThree> {
  isSubmitting: boolean;
}

export default ({ value, onSubmit, onBack, isSubmitting }: Props) => {
  const { t } = useTranslation();
  const commentField = useRef<TextInput>(null);

  return (
    <Formik initialValues={value} onSubmit={onSubmit}>
      <>
        <VTTextInput
          formik="preexistingConditions"
          label={t('issues.preexistingConditions')}
          placeholder={t('issues.preexistingConditionsPlaceholder')}
          nextField={commentField}
        />
        <VTTextInput
          formik="comment"
          ref={commentField}
          label={t('issues.comment')}
          placeholder={t('issues.commentPlaceholder')}
          nextField={false}
        />
        <VTButton
          formik="submit"
          variant="contained"
          title={t('issues.createIssue')}
          pending={isSubmitting}
          margin
        />
        {onBack && <VTButton title={t('action.back')} onPress={onBack} />}
      </>
    </Formik>
  );
};
