import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import UsersFunctionsService from '../../../services/UsersFunctionsService';
import { useAppSelector } from '../../../store';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import Divider from '../../UI/Divider';
import NotificationsListItem from './NotificationsListItem';

export default () => {
  const [
    staticNotificationsReadTime,
    setStaticNotificationsReadTime,
  ] = useState<number>();
  const isFocused = useIsFocused();
  const user = useUser();
  const broadcasts = useAppSelector(({ root: { broadcasts } }) => broadcasts);

  useEffect(() => {
    if (isFocused) {
      if (staticNotificationsReadTime !== undefined) {
        return;
      }

      setStaticNotificationsReadTime(user?.notificationsReadTime ?? 0);
      void UsersFunctionsService.instance.usersSetNotificationsRead();
    } else {
      setStaticNotificationsReadTime(undefined);
    }
  }, [isFocused, staticNotificationsReadTime, user]);

  return (
    <DefaultLayout scrollable>
      <Card padding="none">
        {staticNotificationsReadTime !== undefined &&
          broadcasts.map((b, i) => (
            <Fragment key={b.id}>
              {!!i && <Divider />}
              <NotificationsListItem
                notification={b}
                read={b.created < staticNotificationsReadTime}
              />
            </Fragment>
          ))}
      </Card>
    </DefaultLayout>
  );
};
