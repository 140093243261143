import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MspGetNpiInfoResponse } from 'vacctrack';
import { object, SchemaOf, string } from 'yup';
import MspFunctionsService from '../../services/MspFunctionsService';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import VTTextInput from '../UI/VTTextInput';

interface Props {
  onInfoReceived: (info: MspGetNpiInfoResponse) => void;
}

interface FormData {
  npi: string;
}

// https://npiprofile.com/validation
function checkNpiTypo(value: string | null | undefined) {
  if (!value) {
    return false;
  }

  const CONSTANT = 24;
  const num = value.substr(0, 9);
  const checkNum = parseInt(value.substr(9, 1), 10);

  const sum = num.split('').reduce((acc, s, i) => {
    const n = parseInt(s, 10);

    if (i % 2 === 0) {
      return `${n * 2}`
        .split('')
        .reduce((acc2, s2) => acc2 + parseInt(s2, 10), acc);
    } else {
      return acc + n;
    }
  }, CONSTANT);

  let mod = 10 - (sum % 10);

  if (mod === 10) {
    mod = 0;
  }

  return mod === checkNum;
}

const NPI_REGEX = /^\d{10}$/;

const SCHEMA = (): SchemaOf<FormData> => object({
  npi: string()
    .matches(NPI_REGEX, () => ({
      key: 'mspRegister.npiError',
      options: { context: 'format' },
    }))
    .test(
      'is-typo',
      () => ({
        key: 'mspRegister.npiError',
        options: { context: 'typo' },
      }),
      checkNpiTypo,
    )
    .required(),
});

export default ({ onInfoReceived }: Props) => {
  const { t } = useTranslation();

  const onSubmit = async (
    { npi }: FormData,
    { setFieldError, setSubmitting }: FormikHelpers<FormData>,
  ) => {
    try {
      onInfoReceived(await MspFunctionsService.instance.mspGetNpiInfo(npi));
    } catch (e) {
      if (e.message === 'taxonomy') {
        setFieldError('npi', 'mspRegister.npiError_taxonomy');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ npi: '' }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      <>
        <VTText paragraph>{t('mspRegister.enterNPINumber')}:</VTText>
        <VTTextInput
          formik="npi"
          placeholder="NPI"
          keyboardType="numeric"
          helperText={t('mspRegister.enterNPINumberHelp')}
        />
        <VTButton
          formik="submit"
          variant="contained"
          title={t('action.next')}
        />
      </>
    </Formik>
  );
};
