import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMemo } from 'react';
import useAuthUser from './useAuthUser';

export default function useUserSpecificStorage() {
  const uid = useAuthUser()?.uid ?? '';

  return useMemo(
    () => ({
      getItem(key: string) {
        return AsyncStorage.getItem(key + uid);
      },
      setItem(key: string, value: string) {
        return AsyncStorage.setItem(key + uid, value);
      },
      removeItem(key: string) {
        return AsyncStorage.removeItem(key + uid);
      },
    }),
    [uid],
  );
}
