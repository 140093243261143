import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewProps,
} from 'react-native';
import theme from '../../util/theme';

export interface ContainerProps extends ViewProps {
  maxWidth?: keyof typeof WIDTH_LOOKUP_TABLE;
}

const WIDTH_LOOKUP_TABLE = {
  sm: 320,
  md: 420,
};

export function getContainerWidth(
  windowWidth: number,
  maxWidth: ContainerProps['maxWidth'] = 'md',
) {
  return Math.min(windowWidth, WIDTH_LOOKUP_TABLE[maxWidth]);
}

export default ({
  maxWidth = 'md',
  ...props
}: PropsWithChildren<ContainerProps>) => {
  const { width } = useWindowDimensions();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        {...props}
        style={[
          styles.root,
          { maxWidth: getContainerWidth(width, maxWidth) },
          props.style,
        ]}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(12),
    alignSelf: 'center',
  },
});
