import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CodeText from '../../UI/CodeText';
import VTButton from '../../UI/VTButton';
import VTQRCode from '../../UI/VTQRCode';

interface Props {
  codeValue: string;
  formattedCode: string;
  onBack: () => void;
}

export default ({ codeValue, formattedCode, onBack }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <VTQRCode
        value={codeValue}
        logo={require('../../../../assets/images/vacctrack-outline.png')}
      />
      <CodeText>{formattedCode}</CodeText>
      <VTButton title={t('action.back')} onPress={onBack} />
    </>
  );
};
