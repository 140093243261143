import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorPayload {
  key: string;
  options?: object;
}

function transformKey(input: string) {
  return input.replace('/', '_');
}

export default () => {
  const { t } = useTranslation();

  /*
  A string is handled like a firebase error, an object is passed to t
   */
  return useCallback(
    (codeOrObj?: string | ErrorPayload) => {
      if (codeOrObj === undefined) {
        return;
      }

      if (typeof codeOrObj === 'object') {
        return t(codeOrObj.key, codeOrObj.options);
      }

      if (codeOrObj.includes('.')) {
        return t(codeOrObj);
      }

      return t(`error.${transformKey(codeOrObj)}`, t('error.generic'));
    },
    [t],
  );
};
