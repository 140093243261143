import { Formik } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { number, object, SchemaOf, string } from 'yup';
import { dateInputProps } from '../../util/date';
import { DateOptions } from '../../util/schema';
import { StepValueProps } from '../Navigation/Navigation';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import VTTextInput from '../UI/VTTextInput';
import ShotNumberInput from './ShotNumberInput';

export interface ProductDataInputData {
  shotNumber: number;
  shotNumberOf: number;
  isTest?: boolean;
  lotNumber?: string;
  expiryDateString?: string;
}

interface Props extends StepValueProps<ProductDataInputData> {
  submitTitle: string;
  isSubmitting: boolean;
}

const REASONABLE_EXPIRY_DATE_RANGE: DateOptions = {
  min: 315569260000, // 10 years in the past
  max: 631138520000, // 20 years in the future
};

const SCHEMA = (): SchemaOf<Omit<ProductDataInputData, 'isTest'>> => object({
  shotNumber: number().min(0).max(20).required(),
  shotNumberOf: number().min(1).max(20).required(),
  lotNumber: string(),
  expiryDateString: string().dateString({
    ...REASONABLE_EXPIRY_DATE_RANGE,
    optional: true,
  }),
});

export default ({
  value,
  onSubmit,
  submitTitle,
  isSubmitting,
  onBack,
}: Props) => {
  const { t } = useTranslation();
  const lotNumberField = useRef<TextInput>(null);
  const expiryDateField = useRef<TextInput>(null);

  return (
    <Formik initialValues={value} validationSchema={SCHEMA} onSubmit={onSubmit}>
      <>
        <ShotNumberInput nextField={lotNumberField} />
        <VTText paragraph>{t('tickets.createProductDataDescription')}</VTText>
        <VTTextInput
          formik="lotNumber"
          ref={lotNumberField}
          nextField={expiryDateField}
          label={t('tickets.lotNumber')}
          placeholder={t('tickets.lotNumberPlaceholder')}
        />
        <VTTextInput
          formik="expiryDateString"
          ref={expiryDateField}
          label={t('tickets.expiryDate')}
          placeholder={t('tickets.expiryDatePlaceholder')}
          nextField={false}
          {...dateInputProps(t)}
        />
        <VTText variant="caption" paragraph>
          {t('tickets.expiryDateCaption')}
        </VTText>
        <VTButton
          formik="submit"
          variant="contained"
          title={submitTitle}
          pending={isSubmitting}
          margin
        />
        <VTButton
          title={t('action.back')}
          disabled={isSubmitting}
          onPress={onBack}
        />
      </>
    </Formik>
  );
};
