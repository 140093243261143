import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CertificatesFunctionsService, {
  isError,
} from '../../../../services/CertificatesFunctionsService';
import {
  INITIAL_DATE_DATA,
  INITIAL_PRODUCT_DATA,
  INITIAL_PRODUCT_SELECTOR_DATA,
} from '../../../../util/constants';
import { stringToDate } from '../../../../util/date';
import dialog from '../../../../util/dialog';
import ProductDataInput, {
  ProductDataInputData,
} from '../../../Shared/ProductDataInput';
import ProductSelector, {
  ProductSelectorData,
} from '../../../Shared/ProductSelector';
import ServiceDateInput, {
  ServiceDateData,
} from '../../../Shared/ServiceDateInput';
import VTText from '../../../UI/VTText';
import { CertificatesNavigationProp } from '../../CertificatesNavigator';
import StepPhotoUpload from './StepPhotoUpload';

enum Step {
  DateData,
  ProductSelect,
  ProductData,
  PhotoUpload,
}

interface Props {
  onCancel: () => void;
}

export default ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const [step, setStep] = useState<Step>(0);
  const [dateData, setDateData] = useState<ServiceDateData>(
    INITIAL_DATE_DATA(),
  );
  const [
    productSelectorData,
    setProductSelectorData,
  ] = useState<ProductSelectorData>(INITIAL_PRODUCT_SELECTOR_DATA);
  const [productData, setProductData] = useState<ProductDataInputData>(
    INITIAL_PRODUCT_DATA,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const next = () =>
    setStep((s) => Math.min(s + 1, Object.keys(Step).length - 1));
  const prev = () => setStep((s) => Math.max(0, s - 1));

  const onSubmit = async (base64Data: string) => {
    setIsSubmitting(true);
    try {
      const reminderDate = stringToDate(
        dateData.reminderDateString!,
      )?.getTime();
      const expiryDate = stringToDate(productData.expiryDateString!)?.getTime();

      const result = await CertificatesFunctionsService.instance.certificatesCreateWithProof(
        {
          proofDataBase64: base64Data,
          serviceDate: stringToDate(dateData.serviceDateString)!.getTime(),
          productId: productSelectorData.productId!,
          ...productData,
          reminderDate,
          expiryDate,
        },
      );

      if (isError(result)) {
        // noinspection ES6MissingAwait
        dialog.alert(
          `${t('error.proof', t('error.generic'), {
            context: result.error,
          })}`,
          t('error.proofFailed'),
        );
        return;
      }

      await dialog.alert(
        t('certificates.createSuccessProof', { confidence: result.confidence }),
      );

      navigation.replace('CertificatesDetailScreen', {
        id: result.certificateId,
      });
    } catch (e) {
      // noinspection ES6MissingAwait
      dialog.alert(t('error.generic'));
    } finally {
      setIsSubmitting(false);
    }
  };

  switch (step) {
    case Step.DateData:
      return (
        <ServiceDateInput
          description={
            <VTText paragraph>{t('certificates.proofMetaDescription')}</VTText>
          }
          value={dateData}
          onBack={onCancel}
          onSubmit={(v) => {
            setDateData(v);
            next();
          }}
        />
      );
    case Step.ProductSelect:
      return (
        <ProductSelector
          limitProductNameTo="COVID-19"
          value={productSelectorData}
          onSubmit={(d) => {
            setProductSelectorData(d);

            if (d) {
              setProductData((pd) => ({
                ...pd,
                shotNumberOf: d.shotNumberOf,
              }));
              next();
            }
          }}
          onBack={prev}
        />
      );
    case Step.ProductData:
      return (
        <ProductDataInput
          submitTitle={t('action.next')}
          value={productData}
          onSubmit={(data) => {
            setProductData(data);
            next();
          }}
          onBack={prev}
          isSubmitting={isSubmitting}
        />
      );
    case Step.PhotoUpload:
      return (
        <StepPhotoUpload
          onBack={prev}
          value="__unused"
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      );
  }
};
