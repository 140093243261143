import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VERIFY_PARAM_NAME } from '../../util/constants';
import { navigationHeaderOptions } from '../Main/shared';
import VerifyScreen from './VerifyScreen';

export type VerifyNavigatorParamList = {
  VerifyScreen?: {
    [K in typeof VERIFY_PARAM_NAME]?: string;
  };
};

const Stack = createStackNavigator<VerifyNavigatorParamList>();

export default () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="VerifyScreen"
        component={VerifyScreen}
        options={{
          title: t('verify.title'),
          ...navigationHeaderOptions(true),
        }}
      />
    </Stack.Navigator>
  );
};
