import * as React from 'react';
import { useMemo, useState } from 'react';
import CertificatesService from '../../../services/CertificatesService';
import config from '../../../util/config';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import useRouteServiceItem from '../hooks/useRouteServiceItem';
import CertificatesShareQR from './CertificatesShareQR';
import CertificatesShareSelector from './CertificatesShareSelector';

export type Mode = 'qr';

export default () => {
  const { serviceItem, pdfUrl } = useRouteServiceItem();
  const [mode, setMode] = useState<Mode>();

  const code = useMemo(
    () =>
      serviceItem && CertificatesService.isCertificate(serviceItem)
        ? CertificatesService.instance.getDefaultReleaseCode(serviceItem)
        : undefined,
    [serviceItem],
  );

  const codeValue = `${config.appUrl}/verify?c=${code}`;

  const content = (() => {
    if (!serviceItem || !CertificatesService.isCertificate(serviceItem)) {
      return null;
    }

    switch (mode) {
      case 'qr':
        return (
          <Card padding="dense">
            <CertificatesShareQR
              value={codeValue}
              onBack={() => setMode(undefined)}
            />
          </Card>
        );
      default:
        return (
          <Card>
            <CertificatesShareSelector
              certificate={serviceItem}
              pdfUrl={pdfUrl}
              onSelect={setMode}
              value={codeValue}
            />
          </Card>
        );
    }
  })();

  return <DefaultLayout scrollable>{content}</DefaultLayout>;
};
