import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppState,
  AppStateStatus,
  Linking,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import theme from '../../../util/theme';
import { getContainerWidth } from '../Container';
import ErrorBoundary from '../ErrorBoundary';
import VTActivityIndicator from '../VTActivityIndicator';
import VTButton from '../VTButton';
import VTText from '../VTText';
import BarcodeScanViewInner from './BarcodeScanViewInner';
import useCamera from './hooks/useCamera';

interface Props {
  onResult: (code: string) => void;
  onCancel?: () => void;
  helperText?: React.ReactNode;
  loading: boolean;
  paramName: string;
}

const COOLDOWN = 4000;

export default ({
  loading,
  onCancel,
  onResult,
  helperText,
  paramName,
}: Props) => {
  const [appState, setAppState] = useState<AppStateStatus>('active');
  const [navStateActive, setNavStateActive] = useState(true);
  const {
    cameraType,
    hasTwoCameras,
    permission,
    askPermission,
    toggleCameraType,
  } = useCamera();
  const [isCooldown, setIsCooldown] = useState(false);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const containerWidth = getContainerWidth(width) - theme.spacing(8);

  useEffect(() => {
    const l1 = navigation.addListener('focus', () => setNavStateActive(true));
    const l2 = navigation.addListener('blur', () => setNavStateActive(false));

    AppState.addEventListener('change', setAppState);

    return () => {
      l1();
      l2();
      AppState.removeEventListener('change', setAppState);
    };
  }, [navigation]);

  const onBarCodeScanned = ({ data }: { data: string }) => {
    setIsCooldown(true);

    setTimeout(() => {
      setIsCooldown(false);
    }, COOLDOWN);

    const [, code] = data.split(`?${paramName}=`);

    if (code) {
      onResult(code);
    }
  };

  const paused =
    isCooldown || loading || appState !== 'active' || !navStateActive;
  const canShowCamera = permission?.status === 'granted';

  if (!permission) {
    return null;
  }

  // noinspection JSSuspiciousNameCombination
  return (
    <View
      style={
        Platform.OS === 'web'
          ? (({ display: 'block' } as unknown) as ViewStyle)
          : undefined
      }
    >
      {canShowCamera ? (
        <ErrorBoundary message={t('error.camera')}>
          <View
            style={[
              styles.camera,
              { width: containerWidth, height: containerWidth },
            ]}
          >
            <BarcodeScanViewInner
              type={cameraType}
              onBarCodeScanned={paused ? undefined : onBarCodeScanned}
              style={[
                paused && styles.paused,
                {
                  width: containerWidth,
                  height:
                    // android has a problem with square aspect ratio
                    Platform.OS === 'android'
                      ? (containerWidth * 16) / 9
                      : containerWidth,
                },
              ]}
            />
          </View>
          {helperText && (
            <View>
              <VTText style={styles.helpText} variant="label">
                {helperText}
              </VTText>
            </View>
          )}
          {hasTwoCameras && (
            <VTButton
              title={t('camera.switchCamera')}
              onPress={toggleCameraType}
            />
          )}
        </ErrorBoundary>
      ) : (
        <View
          style={[
            styles.camera,
            styles.placeholder,
            { width: containerWidth, height: containerWidth },
          ]}
        >
          <VTText variant="label" style={styles.helpText}>
            {t('permissions.cameraRequestText')}
          </VTText>
          {permission?.canAskAgain ? (
            <VTButton
              variant="contained"
              title={t('permissions.cameraRequestAction')}
              onPress={askPermission}
            />
          ) : (
            <VTButton
              variant="contained"
              title={t('permissions.openSettings')}
              onPress={() => Linking.openSettings()}
            />
          )}
        </View>
      )}
      {onCancel ? (
        <VTButton
          title={t('action.cancel')}
          onPress={onCancel}
          pending={loading}
        />
      ) : (
        loading && <VTActivityIndicator style={styles.loading} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  camera: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.backgroundSecondary,
    overflow: 'hidden',
    ...theme.border.black,
  },
  paused: {
    opacity: 0.5,
  },
  loading: {
    marginVertical: theme.spacing(1),
  },
  placeholder: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpText: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
});
