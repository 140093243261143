import { useFormikContext } from 'formik';
import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import theme from '../../util/theme';
import VTActivityIndicator from './VTActivityIndicator';
import VTIcon, { VTIconProps } from './VTIcon';

export interface VTButtonProps {
  formik?: 'submit' | 'button';
  title?: React.ReactNode;
  onPress?: () => void;
  variant?: 'contained' | 'text';
  size?: 'normal' | 'small';
  style?: ViewStyle;
  margin?: boolean;
  disabled?: boolean;
  pending?: boolean;
  titleStyle?: TextStyle;
  startIcon?: VTIconProps['name'];
}

export default ({
  title,
  onPress: _onPress,
  formik,
  variant = 'text',
  size = 'normal',
  margin = false,
  disabled: _disabled = false,
  pending: _pending = false,
  style,
  titleStyle,
  startIcon,
}: VTButtonProps) => {
  const ctx = formik
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useFormikContext<{
        [key: string]: string;
      }>()
    : undefined;

  const disabled = _disabled || (formik !== 'submit' && ctx?.isSubmitting);
  const pending = _pending || (formik === 'submit' && ctx?.isSubmitting);

  const onPress = formik === 'submit' ? () => ctx?.submitForm() : _onPress;

  if (pending) {
    return <VTActivityIndicator style={containerStyles.pending} />;
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View
        style={[
          containerStyles.root,
          margin && containerStyles.margin,
          disabled && containerStyles.disabled,
          containerStyles[variant],
          containerStyles[size],
          style,
        ]}
      >
        {startIcon && (
          <VTIcon
            name={startIcon}
            style={styles.leadingIcon}
            presetSize={size === 'small' ? 'buttonIconSmall' : 'buttonIcon'}
            color={
              variant === 'contained' ? theme.palette.background : undefined
            }
          />
        )}
        <Text
          style={[
            textStyles.root,
            textStyles[variant],
            textStyles[size],
            titleStyle,
          ]}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  leadingIcon: {
    marginRight: theme.spacing(1),
  },
});

const containerStyles = StyleSheet.create({
  root: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(2.25),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  disabled: {
    ...theme.action.disabled,
  },
  default: {},
  contained: {
    backgroundColor: theme.palette.primary,
  },
  text: {},
  normal: {},
  small: {
    padding: theme.spacing(0.5),
  },
  pending: {
    marginVertical: theme.spacing(0.5) + 1,
  },
});

const textStyles = StyleSheet.create({
  root: {
    fontSize: 18,
    color: theme.palette.text,
    textAlign: 'center',
  },
  default: {
    color: theme.palette.text,
  },
  contained: {
    color: theme.palette.background,
  },
  text: {},
  normal: {},
  small: {
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
});
