import { Picker } from '@react-native-community/picker';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import truncate from 'lodash/truncate';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { Product } from 'vacctrack';
import useUserTypeCheck from '../../hooks/useUserTypeCheck';
import { Id } from '../../services/FirestoreService';
import ProductsService from '../../services/ProductsService';
import theme from '../../util/theme';
import { StepValueProps } from '../Navigation/Navigation';
import VTButton from '../UI/VTButton';
import VTPicker from '../UI/VTPicker';
import VTText from '../UI/VTText';

interface Props extends StepValueProps<ProductSelectorData> {
  description?: string;
  nameOnly?: boolean;
  limitProductNameTo?: string;
}

export interface ProductSelectorData {
  productId?: string;
  name?: string;
  shotNumberOf: number;
  isTest?: boolean;
}

const MAX_CHARS = Platform.select({
  ios: 24,
  android: 40,
  default: 60,
});

const INITIAL_IPRODUCT = 0;

function truncateProductName(product: Product, truncateName = false) {
  const name = `${product.brand} (${product.manufacturer})`;
  return truncateName ? truncate(name, { length: MAX_CHARS }) : name;
}

export default ({
  value: { productId },
  nameOnly = false,
  onSubmit,
  onBack,
  description,
  limitProductNameTo,
}: Props) => {
  const { t } = useTranslation();

  const [iName, setIName] = useState(-1);
  const [iProduct, setIProduct] = useState(INITIAL_IPRODUCT);
  const [products, setProducts] = useState<(Product & Id)[]>();

  const isMsp = useUserTypeCheck('msp');

  useEffect(() => {
    async function getProducts() {
      setProducts(
        (await ProductsService.instance.list()).filter((p) =>
          limitProductNameTo ? p.name === limitProductNameTo : true,
        ),
      );
      if (limitProductNameTo) {
        setIName(0);
      }
    }

    void getProducts();
  }, [limitProductNameTo]);

  const grouped = useMemo(
    () =>
      products
        ? groupBy(
            products.filter((p) => !p.hidden && (isMsp || !p.isTest)),
            'name',
          )
        : undefined,
    [isMsp, products],
  );

  const groupedKeys = useMemo(
    () =>
      grouped
        ? sortBy(Object.keys(grouped), (g) => g.toLowerCase())
        : undefined,
    [grouped],
  );

  const productsByName =
    grouped && groupedKeys && iName > -1
      ? sortBy(grouped[groupedKeys[iName]], (p) => p.brand.toLowerCase())
      : undefined;

  useEffect(() => {
    if (productId && products && groupedKeys) {
      const selectedProduct = products.find((p) => p.id === productId);

      if (!selectedProduct) {
        return;
      }

      const iN = groupedKeys.findIndex((g) => g === selectedProduct.name);

      if (iN > -1) {
        setIName(iN);
      }
    }
  }, [products, groupedKeys, productId]);

  const currentProduct = productsByName ? productsByName[iProduct] : undefined;

  return (
    <>
      {description && <VTText paragraph>{description}</VTText>}
      {groupedKeys && !limitProductNameTo && (
        <VTPicker
          value={iName}
          onChange={(i) => {
            setIProduct(INITIAL_IPRODUCT);
            setIName(i - 1);
          }}
          label={t('tickets.selectDisease')}
        >
          <Picker.Item label="---" value={-1} />
          {groupedKeys.map((name, i) => (
            <Picker.Item key={i} label={name} value={i} />
          ))}
        </VTPicker>
      )}
      {!nameOnly && productsByName && (
        <>
          <VTPicker
            value={iProduct}
            onChange={setIProduct}
            label={t('tickets.selectProduct')}
            enabled={productsByName.length > 1}
          >
            {productsByName.map((p, i) => (
              <Picker.Item
                key={i}
                label={truncateProductName(p, true)}
                value={i}
              />
            ))}
          </VTPicker>
          {currentProduct && (
            <VTText
              variant="caption"
              style={{
                marginTop: theme.spacing(-1.5),
                marginHorizontal: theme.spacing(1.25),
              }}
              paragraph
            >
              {currentProduct.manufacturer}
              {isMsp && currentProduct.description && (
                <>
                  {'\n'}
                  {currentProduct.description}
                </>
              )}
            </VTText>
          )}
        </>
      )}
      <VTButton
        variant="contained"
        title={t('action.next')}
        onPress={() =>
          onSubmit({
            productId: currentProduct!.id,
            name: currentProduct!.name,
            shotNumberOf: currentProduct?.numberOfShots ?? 1,
            isTest: currentProduct?.isTest,
          })
        }
        pending={!products}
        disabled={!currentProduct}
      />
      {onBack && <VTButton title={t('action.back')} onPress={onBack} />}
    </>
  );
};
