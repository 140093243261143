import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../UI/IconButton';
import { MainNavigatorParamList } from './MainNavigator';

interface Props {
  popToTopFallback?: string;
  onPress?: () => void;
}

export default ({ onPress, popToTopFallback }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<
    StackNavigationProp<MainNavigatorParamList>
  >();

  const _onPress = () => {
    if (onPress) {
      onPress();
      return;
    }

    if (!popToTopFallback || navigation.canGoBack()) {
      navigation.popToTop();
    } else {
      navigation.navigate(popToTopFallback as keyof MainNavigatorParamList);
    }
  };

  return (
    <IconButton
      color="background"
      iconName="close"
      label={t('action.close')}
      onPress={_onPress}
      edge="end"
    />
  );
};
