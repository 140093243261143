import { Picker } from '@react-native-community/picker';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import useErrorTranslation from '../../hooks/useErrorTranslation';
import theme from '../../util/theme';
import HelperText from './HelperText';
import VTText from './VTText';

interface Props {
  formik?: string;
  label: string;
  value?: number;
  onChange?: (value: number) => void;
  children: React.ReactNode;
  enabled?: boolean;
  helperText?: React.ReactNode;
  hasEmptyItem?: boolean;
}

export default ({
  formik,
  value,
  label,
  helperText,
  onChange,
  children,
  enabled,
  hasEmptyItem,
}: Props) => {
  const tError = useErrorTranslation();
  const ctx = formik
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useFormikContext<{
        [key: string]: string;
      }>()
    : undefined;

  const hasError = ctx && ctx.touched[formik!] && !!ctx.errors[formik!];
  const helperOrErrorText =
    ctx && hasError ? tError(ctx.errors[formik!]) : helperText;

  return (
    <>
      <VTText variant="label" style={styles.label}>
        {label}
      </VTText>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={ctx ? ctx.values[formik!] : value}
          style={styles.picker}
          enabled={enabled}
          mode="dropdown"
          onValueChange={(_, itemIndex) => {
            const newValue = hasEmptyItem ? itemIndex - 1 : itemIndex;

            void (ctx
              ? ctx.setFieldValue(formik!, newValue)
              : onChange?.(newValue));
          }}
          accessibilityLabel={label}
          prompt={label}
        >
          {children}
        </Picker>
        {helperOrErrorText && (
          <HelperText style={styles.helperText} error={hasError}>
            {helperOrErrorText}
          </HelperText>
        )}
      </View>
    </>
  );
};

const sharedSpacing = {
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),
};

const styles = StyleSheet.create({
  pickerContainer: {
    ...Platform.select({
      ios: {
        overflow: 'hidden',
        ...theme.border.black,
        ...sharedSpacing,
      },
      android: {
        ...theme.border.black,
        ...sharedSpacing,
      },
    }),
  },
  picker: {
    ...Platform.select({
      ios: {
        marginVertical: theme.spacing(-4.5),
      },
      web: {
        ...theme.border.black,
        ...sharedSpacing,
        padding: theme.spacing(1),
      },
    }),
  },
  label: {
    marginHorizontal: theme.spacing(1) + 2,
  },
  helperText: {
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(1.5),
  },
});
