import React, { ErrorInfo } from 'react';
import VTText from './VTText';

interface Props {
  message?: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <VTText variant="label">{this.props.message}</VTText>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
