import * as React from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import theme, { Color } from '../../util/theme';
import VTIcon, { VTIconProps } from './VTIcon';

interface Props {
  iconName: VTIconProps['name'];
  size?: 'normal' | 'small';
  label: string;
  onPress: () => void;
  color?: Color;
  edge?: 'start' | 'end';
  style?: ViewStyle;
}

export default ({
  iconName,
  label,
  onPress,
  size = 'normal',
  edge,
  color = 'text',
  style,
}: Props) => (
  <TouchableOpacity
    accessibilityRole="button"
    accessibilityLabel={label}
    style={[styles[size], edge && styles[edge], style]}
    onPress={onPress}
  >
    <VTIcon
      color={theme.palette[color]}
      name={iconName}
      size={theme.spacing(3)}
    />
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  normal: {
    marginTop: -2,
    paddingVertical: theme.spacing(1.5),
    paddingHorizontal: theme.spacing(2),
  },
  small: {
    paddingVertical: theme.spacing(0.75),
    paddingHorizontal: theme.spacing(1.5),
  },
  start: {
    paddingLeft: 0,
  },
  end: {
    paddingRight: 0,
  },
});
