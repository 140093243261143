import { UserPatient } from 'vacctrack';

export default {
  fullName({
    firstName,
    middleName,
    lastName,
  }: Pick<UserPatient, 'firstName' | 'middleName' | 'lastName'>) {
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  },
  pageTitle(title?: string) {
    const value: string[] = [];

    if (title) {
      value.push(title);
    }

    value.push('VaccTrack');

    return value.join(' – ');
  },
  productTitle(
    name: string,
    product: { shotNumber: number; shotNumberOf: number },
  ) {
    return product.shotNumberOf > 1
      ? `${name} (${product.shotNumber}/${product.shotNumberOf})`
      : name;
  },
  serviceContext({ isTest }: { isTest?: boolean }) {
    return isTest ? 'test' : 'vaccination';
  },
};
