import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import CertificatesFunctionsService from '../../services/CertificatesFunctionsService';
import CertificatesService from '../../services/CertificatesService';
import dialog from '../../util/dialog';
import { openLink } from '../../util/platform';
import strings from '../../util/strings';
import theme from '../../util/theme';
import IssuesCreateSection from '../Shared/IssuesCreateSection';
import Card from '../UI/Card';
import CertificateLayout from '../UI/CertificateLayout';
import DefaultLayout from '../UI/DefaultLayout';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import { CertificatesNavigationProp } from './CertificatesNavigator';
import useRouteServiceItem from './hooks/useRouteServiceItem';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const { serviceItem, pdfUrl, loading } = useRouteServiceItem();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [deletePending, setDeletePending] = useState(false);

  const serviceItemNotFound = !loading && !serviceItem

  useEffect(() => {
    if (!serviceItemNotFound) {
      return
    }

    navigation.popToTop()
  }, [navigation, serviceItemNotFound])

  const createRelease = async () => {
    if (!serviceItem) {
      return;
    }

    try {
      navigation.navigate('CertificatesShareScreen', {
        id: serviceItem.id,
      });
    } catch (e) {
      console.error(e);
    }
    // setReleaseLoading(true);
    //
    // try {
    //   const {
    //     code,
    //   } = await CertificatesFunctionsService.instance.certificatesCreateRelease(
    //     {
    //       certificateId: item!.id,
    //       description: 'Test',
    //     },
    //   );
    //
    //   navigation.navigate('CertificatesShareScreen', {
    //     code,
    //   });
    // } catch (e) {
    //   // TODO
    //   console.error(e);
    // } finally {
    //   setReleaseLoading(false);
    // }
  };

  const onDownload = () => {
    openLink(pdfUrl!);
  };

  const onDelete = async () => {
    if (
      !(await dialog.confirm(t, {
        message: t('certificates.certificateDeleteConfirm'),
        destructive: true,
      }))
    ) {
      return;
    }

    setDeletePending(true);

    try {
      await CertificatesFunctionsService.instance.certificatesDelete({
        certificateId: serviceItem!.id,
      });

      setDeletePending(false);
    } catch (e) {
      setDeletePending(false);
    }

    navigation.popToTop();
  };

  if (
    loading ||
    !serviceItem ||
    !CertificatesService.isCertificate(serviceItem)
  ) {
    return null;
  }

  return (
    <DefaultLayout scrollable>
      <Card>
        <CertificateLayout
          variant="detail"
          data={serviceItem}
          title={
            <VTText variant="heading2">
              {t('certificates.heading', {
                context: strings.serviceContext(serviceItem),
              })}
            </VTText>
          }
        />
        <VTButton
          variant="contained"
          startIcon="share"
          title={t('certificates.share')}
          onPress={createRelease}
          disabled={deletePending}
        />
        <VTButton
          title={t('certificates.share', { context: 'download' })}
          onPress={onDownload}
          disabled={!pdfUrl || deletePending}
          startIcon="download"
          margin
        />
        {!serviceItem.isTest && (
          <IssuesCreateSection disabled={deletePending} />
        )}
        <View style={{ marginBottom: theme.spacing(2) }} />
        {showAdvanced ? (
          <VTButton
            startIcon="delete"
            title={t('certificates.certificateDelete')}
            onPress={onDelete}
            pending={deletePending}
          />
        ) : (
          <VTButton
            title={t('certificates.advanced')}
            onPress={() => setShowAdvanced(true)}
          />
        )}
      </Card>
    </DefaultLayout>
  );
};
