import orderBy from 'lodash/orderBy';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import RemindersService from '../../../services/RemindersService';
import { useAppSelector } from '../../../store';
import Card from '../../UI/Card';
import Divider from '../../UI/Divider';
import VTActivityIndicator from '../../UI/VTActivityIndicator';
import VTText from '../../UI/VTText';
import CertificatesListItem from './CertificatesListItem';
import ListCreateNew from './ListCreateNew';

export default () => {
  const { t } = useTranslation();
  const { certificates, reminders, reports } = useAppSelector(
    ({ certificates: { certificates, reminders, reports } }) => ({
      certificates,
      reminders,
      reports,
    }),
    shallowEqual,
  );
  const [isCreateExpanded, setIsCreateExpanded] = useState(false);

  const items =
    certificates && reports && reminders
      ? orderBy(
          [...certificates, ...reports, ...reminders],
          (it) => {
            if (RemindersService.isReminder(it)) {
              return it.reminderDate;
            }

            return it.serviceDate;
          },
          'desc',
        )
      : undefined;

  const content = (() => {
    if (isCreateExpanded) {
      return null;
    }

    if (!items) {
      return <VTActivityIndicator />;
    }

    if (!items.length) {
      return <VTText>{t('certificates.empty')}</VTText>;
    }

    return items.map((c) => (
      <React.Fragment key={c.id}>
        <Divider />
        <CertificatesListItem item={c} />
      </React.Fragment>
    ));
  })();

  return (
    <Card title={t('certificates.title')}>
      <ListCreateNew
        isExpanded={isCreateExpanded}
        setIsExpanded={setIsCreateExpanded}
      />
      {content}
    </Card>
  );
};
