import firebase from 'firebase/app';
import { useEffect } from 'react';
import useAuthUser from '../../../hooks/useAuthUser';
import UsersFunctionsService from '../../../services/UsersFunctionsService';
import { useAppSelector } from '../../../store';

export default (useInterval: boolean) => {
  const authUser = useAuthUser();

  const userLoading = useAppSelector(
    ({ root: { user } }) => user === undefined,
  );

  const emailVerificationConfirmed = useAppSelector(
    ({ root: { user } }) => !!user?.emailVerificationConfirmed,
  );

  useEffect(() => {
    let interval: number;

    if (authUser?.emailVerified) {
      if (!userLoading && !emailVerificationConfirmed) {
        void UsersFunctionsService.instance.usersEmailConfirm();
      }
    } else if (useInterval) {
      interval = window.setInterval(async () => {
        try {
          await firebase.auth().currentUser?.reload();
          if (firebase.auth().currentUser?.emailVerified) {
            window.clearInterval(interval);

            await Promise.all([
              firebase.auth().currentUser?.getIdToken(true),
              UsersFunctionsService.instance.usersEmailConfirm(),
            ]);
          }
        } catch (e) {
          console.error(e);
        }
      }, 7500);

      return () => window.clearInterval(interval);
    }
  }, [useInterval, authUser, emailVerificationConfirmed, userLoading]);
};
