import pick from 'lodash/pick';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dialog from '../../../util/dialog';
import { StepValueProps } from '../../Navigation/Navigation';
import IconTextButton from '../../UI/IconTextButton';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import PersonalDataInput, { PersonalDataFormData } from './PersonalDataInput';
import { personalDataToPersonalDataFormData } from './util/helpers';

export type DependentsFormData = PersonalDataFormData[];

interface Props extends StepValueProps<DependentsFormData> {
  patient: PersonalDataFormData;
  onChange: (value: DependentsFormData) => void;
}

const MAX_DEPENDENTS = 15;

const ADD_INDEX = -1;

const DependentsInput = ({
  value,
  onSubmit,
  onChange,
  patient,
  onBack,
}: Props) => {
  const { t } = useTranslation();
  const [editIndex, setEditIndex] = useState<number>();
  const isAdd = editIndex === ADD_INDEX;

  const onAdd = () => {
    setEditIndex(ADD_INDEX);
  };

  const onEditSubmit = (data: PersonalDataFormData) => {
    setEditIndex(undefined);

    if (isAdd) {
      onChange([...value, data]);
    } else {
      const v = [...value];
      v[editIndex!] = data;
      onChange(v);
    }
  };

  const onDelete = async () => {
    if (
      !(await dialog.confirm(t, {
        title: t('settings.dependentsDelete'),
        destructive: true,
        message: t('settings.dependentsDeleteConfirm'),
      }))
    ) {
      return;
    }

    setEditIndex(undefined);

    const v = [...value];
    v.splice(editIndex!, 1)
    onChange(v);
  };

  if (editIndex !== undefined) {
    const val = (() => {
      if (isAdd) {
        return {
          ...personalDataToPersonalDataFormData(),
          ...pick(patient, ['zip', 'city', 'county', 'state']),
        };
      } else {
        return value[editIndex];
      }
    })();

    if (!val) {
      return null;
    }

    return (
      <PersonalDataInput
        value={val}
        dependentIntent={isAdd ? 'add' : 'edit'}
        onSubmit={onEditSubmit}
        onDelete={onDelete}
        onBack={() => setEditIndex(undefined)}
      />
    );
  }

  return (
    <>
      <VTText paragraph>{t('settings.dependentsInfo')}</VTText>
      {value.map((d, i) => (
        <IconTextButton
          iconName="edit"
          label={`${d.firstName} ${d.lastName}`}
          onPress={() => setEditIndex(i)}
        />
      ))}
      {value.length < MAX_DEPENDENTS && (
        <IconTextButton
          iconName="add"
          label={t('settings.dependentsAdd')}
          onPress={onAdd}
        />
      )}

      <VTButton
        variant="contained"
        title={value.length ? t('action.next') : t('action.skip')}
        onPress={() => onSubmit(value)}
        margin
      />
      <VTButton title={t('action.back')} onPress={onBack} />
    </>
  );
};

export default DependentsInput;
