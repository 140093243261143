import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import useBreakpoint from '../../hooks/useBreakpoint';
import theme from '../../util/theme';
import VTText from './VTText';

interface Props extends PropsWithChildren<ViewProps> {
  /**
   * Do not add or remove title at runtime
   */
  title?: string;
  padding?: 'normal' | 'dense' | 'none';
  margin?: boolean;
}

export default ({
  padding = 'normal',
  title,
  margin = false,
  children,
  ...props
}: Props) => {
  // on iOS the title is centered anyway
  const useTitle = Platform.OS !== 'ios' && !!title;

  // this optimization is dangerous, do not add or remove title at runtime
  const isSm = useTitle ? useBreakpoint('sm') : false;

  return (
    <View
      {...props}
      style={[
        styles.root,
        styles[padding],
        margin && styles.margin,
        props.style,
      ]}
    >
      {useTitle && isSm && <VTText variant="heading1">{title}</VTText>}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    ...theme.surface.card,
  },
  normal: {
    padding: theme.spacing(2),
  },
  dense: {
    padding: theme.spacing(1),
  },
  none: {
    padding: 0,
    // overflow: 'hidden',
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
});
