import * as React from 'react';
import { forwardRef } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
} from 'react-native';
import FlashMessage from '../Main/FlashMessage';
import Background from './Background';
import Container, { ContainerProps } from './Container';

interface Props {
  children: React.ReactNode;
  scrollable?: boolean;
  containerMaxWidth?: ContainerProps['maxWidth'];
}

export default forwardRef<ScrollView, Props>(
  ({ children, scrollable = false }, ref) => {
    const inner = (
      <Container>
        <FlashMessage />
        {children}
      </Container>
    );
    const content = scrollable ? (
      <ScrollView ref={ref} style={styles.scrollView}>
        {inner}
      </ScrollView>
    ) : (
      inner
    );

    return (
      <Background>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          keyboardVerticalOffset={60}
          style={styles.keyboardAvoidingView}
        >
          {content}
        </KeyboardAvoidingView>
      </Background>
    );
  },
);

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
  scrollView: {
    ...Platform.select({
      web: {
        maxHeight: 'calc(100vh - 64px)',
      },
    }),
  },
});
