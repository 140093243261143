import * as React from 'react';
import { PropsWithChildren } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import theme from '../../util/theme';
import VTText from './VTText';

interface Props {
  error?: boolean;
  style?: StyleProp<TextStyle>;
}

const HelperText = ({ error, style, children }: PropsWithChildren<Props>) => (
  <VTText
    variant="caption"
    style={[styles.helperText, error && styles.error, style]}
  >
    {children}
  </VTText>
);

const styles = StyleSheet.create({
  error: {
    color: theme.palette.error,
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    marginHorizontal: theme.spacing(1) + 2,
  },
});

export default HelperText;
