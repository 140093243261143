import useAuthUser from './useAuthUser';
import useUser from './useUser';

export default () => {
  const authUser = useAuthUser();
  const user = useUser();

  if (!authUser || user === undefined) {
    return undefined;
  }

  return !!user?.msp?.verifiedTimestamp;
};
