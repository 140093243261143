import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UsersFunctionsService from '../../services/UsersFunctionsService';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTText from '../UI/VTText';
import { RootNavigatorParamList } from './RootNavigator';

export default ({
  navigation,
}: StackScreenProps<RootNavigatorParamList, 'EmailVerifiedScreen'>) => {
  const { t } = useTranslation();

  useEffect(() => {
    async function confirm() {
      await UsersFunctionsService.instance.usersEmailConfirm();
      navigation.navigate('MainNavigator');
    }

    void confirm();
  }, [navigation]);

  return (
    <DefaultLayout>
      <Card>
        <VTText variant="heading1">{t('settings.verifyingEmail')}</VTText>
        <VTText paragraph>{t('action.pleaseWait')}</VTText>
      </Card>
    </DefaultLayout>
  );
};
