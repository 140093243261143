import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../UI/Card';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';

interface Props {
  onDecide: (value: boolean) => void;
  pending: boolean;
}

export default ({ onDecide, pending }: Props) => {
  const { t } = useTranslation();

  return (
    <Card margin>
      <VTText paragraph>{t('certificates.pushPromptText')}</VTText>
      <VTButton
        variant="contained"
        startIcon="info"
        pending={pending}
        title={t('certificates.pushGetUpdates')}
        onPress={() => onDecide(true)}
      />
      <VTButton
        title={t('action.noThanks')}
        disabled={pending}
        onPress={() => onDecide(false)}
      />
    </Card>
  );
};
