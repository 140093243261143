import { Broadcast } from 'vacctrack';
import FirestoreService from './FirestoreService';

export default class BroadcastsService extends FirestoreService<Broadcast> {
  static instance = new BroadcastsService();

  constructor() {
    super('broadcasts');
  }
}
