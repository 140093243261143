import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import theme from '../../../util/theme';
import BreakoutSection from '../../UI/BreakoutSection';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import { CertificatesNavigationProp } from '../CertificatesNavigator';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
}

export default ({ isExpanded, setIsExpanded }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();

  if (!isExpanded) {
    return (
      <VTButton
        variant="contained"
        startIcon="add"
        title={`${t('action.createNew')}…`}
        onPress={() => setIsExpanded(true)}
        style={styles.button}
      />
    );
  }

  return (
    <>
      <BreakoutSection paddingBottom>
        <VTText paragraph>{t('certificates.landingInfo')}</VTText>
        <VTButton
          variant="contained"
          startIcon="certificates"
          title={t('certificates.create')}
          onPress={() => {
            setIsExpanded(false);

            navigation.navigate('CertificatesGetNewScreen');
          }}
        />
      </BreakoutSection>
      <BreakoutSection paddingBottom>
        <VTText paragraph>{t('certificates.reportInfo')}</VTText>
        <VTButton
          variant="contained"
          title={t('certificates.selfReport')}
          startIcon="report"
          onPress={() => {
            setIsExpanded(false);

            navigation.navigate('ReportsCreateScreen');
          }}
        />
      </BreakoutSection>
      <BreakoutSection paddingBottom>
        <VTText paragraph>{t('certificates.createReminderText')}</VTText>
        <VTButton
          variant="contained"
          title={t('certificates.createReminder')}
          startIcon="reminder"
          onPress={() => {
            setIsExpanded(false);

            navigation.navigate('RemindersCreateScreen');
          }}
        />
      </BreakoutSection>
      <VTButton
        title={t('action.cancel')}
        onPress={() => setIsExpanded(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    marginBottom: theme.spacing(2),
  },
});
