import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../UI/IconButton';
import { MainNavigatorParamList } from './MainNavigator';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<
    DrawerNavigationProp<MainNavigatorParamList>
  >();

  return (
    <IconButton
      color="background"
      iconName="menu"
      label={t('action.menu')}
      onPress={() => navigation.openDrawer()}
      edge="end"
    />
  );
};
