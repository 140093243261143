import { Platform } from 'react-native';

// so we don't have to include the whole expo-barcode-scanner package just for one enum
export const BARCODE_TYPES = [
  Platform.select({
    android: '256', // https://developers.google.com/android/reference/com/google/android/gms/vision/barcode/Barcode#public-static-final-int-qr_code
    ios: 'org.iso.QRCode', // https://developer.apple.com/documentation/avfoundation/avmetadataobject/objecttype/1618819-qr
    web: 'qr',
  })!,
];
