import { Picker } from '@react-native-community/picker';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IssueAgeGroup, IssueBloodType, PatientGender } from 'vacctrack';
import { GENDER_DATA } from '../../../util/constants';
import { StepValueProps } from '../../Navigation/Navigation';
import VTButton from '../../UI/VTButton';
import VTPicker from '../../UI/VTPicker';

export interface DataOne {
  gender: PatientGender;
  ageGroup: IssueAgeGroup;
  bloodType: IssueBloodType;
}

const AGE_GROUP_DATA: IssueAgeGroup[] = [
  '0-4',
  '5-14',
  '15-24',
  '25-64',
  '65+',
];

const BLOOD_TYPE_DATA: IssueBloodType[] = [
  'A+',
  'A-',
  'B+',
  'B-',
  'AB+',
  'AB-',
  'O+',
  'O-',
  'unknown',
];

export default ({ value, onSubmit, onBack }: StepValueProps<DataOne>) => {
  const { t } = useTranslation();

  const [iGender, setIGender] = useState(
    GENDER_DATA.findIndex((el) => el === value.gender),
  );
  const [iAgeGroup, setIAgeGroup] = useState(
    AGE_GROUP_DATA.findIndex((el) => el === value.ageGroup),
  );
  const [iBloodType, setIBloodType] = useState(
    BLOOD_TYPE_DATA.findIndex((el) => el === value.bloodType),
  );

  const _onSubmit = () => {
    if (!everythingSelected) {
      return;
    }

    onSubmit({
      gender: GENDER_DATA[iGender],
      ageGroup: AGE_GROUP_DATA[iAgeGroup],
      bloodType: BLOOD_TYPE_DATA[iBloodType],
    });
  };

  const everythingSelected = iGender >= 0 && iAgeGroup >= 0 && iBloodType >= 0;

  return (
    <>
      <VTPicker
        value={iGender}
        onChange={(i) => {
          setIGender(i - 1);
        }}
        label={t('issues.selectGender')}
      >
        <Picker.Item label="---" value={-1} />
        {GENDER_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={t('issues.gender', { context: name })}
            value={i}
          />
        ))}
      </VTPicker>
      <VTPicker
        value={iAgeGroup}
        onChange={(i) => {
          setIAgeGroup(i - 1);
        }}
        label={t('issues.selectAgeGroup')}
      >
        <Picker.Item label="---" value={-1} />
        {AGE_GROUP_DATA.map((name, i) => (
          <Picker.Item key={i} label={name} value={i} />
        ))}
      </VTPicker>
      <VTPicker
        value={iBloodType}
        onChange={(i) => {
          setIBloodType(i - 1);
        }}
        label={t('issues.selectBloodType')}
      >
        <Picker.Item label="---" value={-1} />
        {BLOOD_TYPE_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={name === 'unknown' ? t('issues.unknown') : name}
            value={i}
          />
        ))}
      </VTPicker>
      <VTButton
        variant="contained"
        title={t('action.next')}
        onPress={_onSubmit}
        disabled={!everythingSelected}
      />
      {onBack && <VTButton title={t('action.back')} onPress={onBack} />}
    </>
  );
};
