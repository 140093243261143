import { Picker } from '@react-native-community/picker';
import truncate from 'lodash/truncate';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { EthnicityInfo, RaceInfo } from 'vacctrack';
import { ETHNICITY_DATA, RACE_DATA } from '../../../util/constants';
import { StepValueProps } from '../../Navigation/Navigation';
import VTButton from '../../UI/VTButton';
import VTPicker from '../../UI/VTPicker';

export interface DataTwo {
  race: RaceInfo;
  ethnicity: EthnicityInfo;
}

const MAX_CHARS = Platform.select({
  ios: 24,
  android: 40,
  default: 60,
});

export default ({ value, onSubmit, onBack }: StepValueProps<DataTwo>) => {
  const { t } = useTranslation();

  const [iRace, setIRace] = useState(
    RACE_DATA.findIndex((el) => el === value.race),
  );
  const [iEthnicity, setIEthnicity] = useState(
    ETHNICITY_DATA.findIndex((el) => el === value.ethnicity),
  );

  const everythingSelected = iRace >= 0 && iEthnicity >= 0;

  const _onSubmit = () => {
    if (!everythingSelected) {
      return;
    }
    onSubmit({
      race: RACE_DATA[iRace],
      ethnicity: ETHNICITY_DATA[iEthnicity],
    });
  };

  return (
    <>
      <VTPicker
        value={iRace}
        onChange={(i) => setIRace(i - 1)}
        label={t('issues.selectRace')}
      >
        <Picker.Item label="---" value={-1} />
        {RACE_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={truncate(t('issues.race', { context: name }), {
              length: MAX_CHARS,
            })}
            value={i}
          />
        ))}
      </VTPicker>
      <VTPicker
        value={iEthnicity}
        onChange={(i) => setIEthnicity(i - 1)}
        label={truncate(t('issues.selectEthnicity'), {
          length: MAX_CHARS,
        })}
      >
        <Picker.Item label="---" value={-1} />
        {ETHNICITY_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={t('issues.ethnicity', { context: name })}
            value={i}
          />
        ))}
      </VTPicker>
      <VTButton
        variant="contained"
        title={t('action.next')}
        onPress={_onSubmit}
        disabled={!everythingSelected}
      />
      {onBack && <VTButton title={t('action.back')} onPress={onBack} />}
    </>
  );
};
