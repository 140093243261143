import firebase from 'firebase/app';

export default class FunctionsService {
  protected async call<T extends object, R extends object = object>(
    name: string,
    data?: T,
  ) {
    return (await firebase.functions().httpsCallable(name)(data ?? {}))
      .data as R;
  }

  testPush(token: string) {
    return this.call('testPush', { token });
  }
}
