import { useNavigation } from '@react-navigation/native';
import format from 'date-fns/format';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import useAuthUser from '../../hooks/useAuthUser';
import RemindersService from '../../services/RemindersService';
import dialog from '../../util/dialog';
import strings from '../../util/strings';
import Card from '../UI/Card';
import { certificateLayoutStyles } from '../UI/CertificateLayout';
import DefaultLayout from '../UI/DefaultLayout';
import MicroReminder from '../UI/SVG/MicroReminder';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import { CertificatesNavigationProp } from './CertificatesNavigator';
import useRouteServiceItem from './hooks/useRouteServiceItem';

export default () => {
  const { t } = useTranslation();
  const uid = useAuthUser()!.uid;
  const { serviceItem, loading } = useRouteServiceItem();
  const navigation = useNavigation<CertificatesNavigationProp>();

  const onDelete = async () => {
    if (
      !(await dialog.confirm(t, {
        message: t('certificates.reminderDeleteConfirm'),
        destructive: true,
      }))
    ) {
      return;
    }

    await RemindersService.instance
      .collection(uid)
      .doc(serviceItem!.id)
      .delete();

    navigation.popToTop();
  };

  if (loading || !serviceItem || !RemindersService.isReminder(serviceItem)) {
    return null;
  }

  return (
    <DefaultLayout scrollable>
      <Card>
        <View
          style={[certificateLayoutStyles.row, certificateLayoutStyles.section]}
        >
          <MicroReminder style={certificateLayoutStyles.icon} />
          <View>
            <VTText variant="heading2">{t('certificates.reminder')}</VTText>
            <VTText>
              {strings.productTitle(serviceItem.description, serviceItem)}
            </VTText>
            <VTText variant="caption" paragraph>
              {format(serviceItem.reminderDate, 'P')}
            </VTText>
          </View>
        </View>
        <VTButton
          startIcon="delete"
          title={t('certificates.reminderDelete')}
          onPress={onDelete}
        />
      </Card>
    </DefaultLayout>
  );
};
