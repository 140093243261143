import { MaterialCommunityIcons } from '@expo/vector-icons';
import { IconProps } from '@expo/vector-icons/build/createIconSet';
import * as React from 'react';
import theme from '../../util/theme';

export type VTIconName = keyof typeof NAME_LOOKUP_TABLE;

type PresetSize = 'buttonIcon' | 'buttonIconSmall';

export interface VTIconProps extends Omit<IconProps<string>, 'name'> {
  name: VTIconName;
  presetSize?: PresetSize;
}

const SIZE_LOOKUP_TABLE: Record<PresetSize, number> = {
  buttonIcon: theme.spacing(2.75),
  buttonIconSmall: theme.spacing(2),
};

const NAME_LOOKUP_TABLE = {
  add: 'plus',
  certificates: 'certificate',
  changeLanguage: 'translate',
  checkEmpty: 'checkbox-blank-outline',
  checkFull: 'checkbox-marked',
  close: 'close',
  delete: 'delete',
  download: 'download',
  edit: 'pencil',
  hidePassword: 'eye-off-outline',
  info: 'information',
  issue: 'alert-outline',
  link: 'link-variant',
  menu: 'menu',
  more: 'dots-vertical',
  mspRegister: 'medical-bag',
  notifications: 'bell-outline',
  notificationsUnread: 'bell-alert',
  print: 'printer',
  qrCode: 'qrcode-scan',
  refresh: 'refresh',
  reminder: 'calendar-alert',
  report: 'clipboard-check-outline',
  sendFile: 'file-send',
  signHipaa: 'draw',
  share: 'share-variant',
  showPassword: 'eye-outline',
  test: 'test-tube',
  verify: 'check-decagram',
  verifyError: 'alert-decagram',
};

export default ({ presetSize, ...props }: VTIconProps) => (
  <MaterialCommunityIcons
    color={theme.palette.text}
    size={presetSize ? SIZE_LOOKUP_TABLE[presetSize] : undefined}
    {...props}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name={NAME_LOOKUP_TABLE[props.name] as any}
  />
);
