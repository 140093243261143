import { ReportsCreateRequest } from 'vacctrack';
import FunctionsService from './FunctionsService';

export default class ReportsFunctionsService extends FunctionsService {
  static instance = new ReportsFunctionsService();

  async reportsCreate(data: ReportsCreateRequest) {
    return this.call<ReportsCreateRequest>('reportsCreate', data);
  }
}
