import { TFunction } from 'i18next';
import { Alert, Platform } from 'react-native';

export default {
  alert: (message: string, title?: string) => {
    if (Platform.OS === 'web') {
      window.alert(`${title ? `${title}: ` : ''}${message}`);
      return Promise.resolve();
    }

    return new Promise((resolve) =>
      Alert.alert(title ?? '', message, [{ onPress: resolve }]),
    );
  },
  confirm: (
    t: TFunction,
    {
      message,
      title,
      destructive,
    }: { message: string; title?: string; destructive?: boolean },
  ) => {
    if (Platform.OS === 'web') {
      return Promise.resolve(window.confirm(message));
    }

    return new Promise((resolve) =>
      Alert.alert(title ?? '', message, [
        {
          text: t('action.cancel'),
          onPress: () => resolve(false),
          style: 'cancel',
        },
        {
          text: t('action.confirm'),
          onPress: () => resolve(true),
          style: destructive ? 'destructive' : 'default',
        },
      ]),
    );
  },
};
