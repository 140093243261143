import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Platform, StyleSheet, Text, TextProps } from 'react-native';
import theme from '../../util/theme';

export default (props: PropsWithChildren<TextProps>) => (
  <Text {...props} style={[styles.text, props.style]} />
);

const styles = StyleSheet.create({
  text: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: 24,
    backgroundColor: theme.palette.backgroundSecondary,
    color: theme.palette.error,
    ...Platform.select({
      ios: {
        fontFamily: 'Courier',
      },
      default: {
        fontFamily: 'monospace',
      },
    }),
  },
});
