import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/app';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../../hooks/useAuthUser';
import { useAppSelector } from '../../store';
import config from '../../util/config';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

export default () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userLoading = useAppSelector(
    ({ root: { user } }) => user === undefined,
  );

  const navigation = useNavigation<RootNavigationProp>();
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const resendEmail = async () => {
    setError(false);
    setLoading(true);

    try {
      await firebase.auth().currentUser?.sendEmailVerification({
        url: config.emailVerificationContinueUrl,
      });

      setVerifySuccess(true);

      window.setTimeout(() => {
        setVerifySuccess(false);
      }, 6000);
    } catch (e) {
      console.error(e, e.code);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (userLoading) {
    return null;
  }

  if (authUser === null) {
    return (
      <>
        <VTText variant="heading1">{t('loginPrompt.pleaseLogIn')}</VTText>
        <VTText paragraph>{t('loginPrompt.prompt')}</VTText>
        <VTButton
          title={t('loginPrompt.goToLogin')}
          variant="contained"
          disabled={loading}
          onPress={() =>
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                mode: 'signUp',
              },
            })
          }
        />
      </>
    );
  }

  if (authUser === undefined || userLoading) {
    return null;
  }

  return (
    <>
      <VTText variant="heading1">{t('loginPrompt.verifyEmail')}</VTText>
      <VTText paragraph>
        {error
          ? t('error.generic')
          : t('loginPrompt.verifyPrompt', { email: authUser?.email })}
      </VTText>
      <VTButton
        title={
          verifySuccess
            ? t('loginPrompt.verifySuccess')
            : t('loginPrompt.resendEmail')
        }
        disabled={loading}
        variant="contained"
        onPress={resendEmail}
      />
    </>
  );
};
