import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { Certificate, Reminder, Report } from 'vacctrack';
import CertificatesService from '../services/CertificatesService';
import { Id } from '../services/FirestoreService';
import { AppThunkApiConfig } from './index';

interface CertificateDetail {
  certificate: Certificate & Id;
  pdfUrl: string;
}

interface State {
  certificateDetail?: CertificateDetail;
  certificates?: (Certificate & Id)[];
  reminders?: (Reminder & Id)[];
  reports?: (Report & Id)[];
}

const loadCertificateDetail = createAsyncThunk<
  string,
  Certificate & Id,
  AppThunkApiConfig
>('certificates/loadCertificateDetail', (certificate, { getState }) => {
  const current = getState().certificates.certificateDetail;

  if (current?.certificate.id === certificate.id) {
    return current.pdfUrl;
  }

  const defaultReleaseCode = CertificatesService.instance.getDefaultReleaseCode(
    certificate,
  );

  const fileName = CertificatesService.instance.getPdfFileName(
    defaultReleaseCode,
  );

  return firebase.storage().ref(fileName).getDownloadURL();
});

const INITIAL_STATE: State = {};

const slice = createSlice({
  name: 'certificates',
  initialState: INITIAL_STATE,
  reducers: {
    setCertificates: (state, action: PayloadAction<State['certificates']>) => {
      state.certificates = action.payload;
    },
    setReminders: (state, action: PayloadAction<State['reminders']>) => {
      state.reminders = action.payload;
    },
    setReports: (state, action: PayloadAction<State['reports']>) => {
      state.reports = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCertificateDetail.fulfilled, (state, action) => {
      state.certificateDetail = {
        certificate: action.meta.arg,
        pdfUrl: action.payload,
      };
    });

    builder.addCase(loadCertificateDetail.rejected, (state) => {
      delete state.certificateDetail;
    });
  },
});

export const actions = {
  loadCertificateDetail,
  ...slice.actions,
};

export default slice.reducer;
