import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import theme from '../../util/theme';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import IconTextButton from '../UI/IconTextButton';
import LogoHeader from '../UI/LogoHeader';
import VTButton from '../UI/VTButton';
import Subheading from './Subheading';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<RootNavigationProp>();

  return (
    <DefaultLayout scrollable={Platform.OS === 'web'}>
      <LogoHeader />
      <Subheading />
      <Card>
        <IconTextButton
          iconName="certificates"
          label="VaccTrack"
          subtitle={t('landing.vaccTrackSubtitle')}
          onPress={() => {
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                screen: 'LoginScreen',
                params: {
                  mode: 'signUp',
                  type: 'patient',
                },
              },
            });
          }}
        />
        <IconTextButton
          iconName="test"
          label="VaccTest"
          subtitle={t('landing.vaccTestSubtitle')}
          onPress={() => {
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                screen: 'LoginScreen',
                params: {
                  mode: 'signUp',
                  type: 'patient',
                },
              },
            });
          }}
        />
        <IconTextButton
          iconName="issue"
          label="VaccWatch"
          subtitle={t('landing.vaccWatchSubtitle')}
          onPress={() => {
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                screen: 'LoginScreen',
                params: {
                  mode: 'signUp',
                  type: 'patient',
                },
              },
            });
          }}
        />
        <IconTextButton
          iconName="verify"
          label="VaccCheck"
          subtitle={t('landing.vaccCheckSubtitle')}
          onPress={() =>
            navigation.navigate('MainNavigator', {
              screen: 'VerifyNavigator',
            })
          }
        />
        <VTButton
          style={{ marginTop: theme.spacing(2.5) }}
          startIcon="mspRegister"
          title={t('landing.mspRegister')}
          onPress={() => {
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                screen: 'LoginScreen',
                params: {
                  mode: 'signUp',
                  type: 'msp',
                },
              },
            });
          }}
        />
        <VTButton
          style={{ marginTop: theme.spacing(1) }}
          size="small"
          title={t('landing.alreadyAUser')}
          onPress={() => {
            navigation.navigate('MainNavigator', {
              screen: 'LoginNavigator',
              params: {
                screen: 'LoginScreen',
                params: {
                  mode: 'login',
                },
              },
            });
          }}
        />
        {Platform.OS === 'web' && (
          <VTButton
            style={{ marginTop: theme.spacing(1) }}
            size="small"
            startIcon="changeLanguage"
            title={t('settings.changeLanguage')}
            onPress={() => {
              navigation.navigate('MainNavigator', {
                screen: 'SettingsNavigator',
                params: {
                  screen: 'ChangeLanguageScreen',
                  initial: false,
                },
              });
            }}
          />
        )}
      </Card>
    </DefaultLayout>
  );
};
