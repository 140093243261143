import firebase from 'firebase/app';
import { typeConverter } from './FirestoreService';

export default class FirestoreSubcollectionService<T extends object = object> {
  protected converter = typeConverter<T>();

  constructor(
    protected collectionName: string,
    protected subcollectionName: string,
  ) {}
  collection(id: string) {
    return firebase
      .firestore()
      .collection(this.collectionName)
      .doc(id)
      .collection(this.subcollectionName)
      .withConverter(this.converter);
  }

  doc(collectionId: string, docId: string) {
    return this.collection(collectionId).doc(docId);
  }
}
