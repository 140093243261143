import * as React from 'react';
import { Image, StyleSheet } from 'react-native';
import theme from '../../util/theme';
import Card from './Card';

export default () => (
  <Card style={styles.root}>
    <Image
      style={styles.logo}
      source={require('../../../assets/images/logo.png')}
    />
  </Card>
);

const styles = StyleSheet.create({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    width: 120,
    height: 120,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 100,
    height: 100,
  },
});
