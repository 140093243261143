import AsyncStorage from '@react-native-async-storage/async-storage';
import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createSelectorHook } from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import certificates from './certificates.slice';
import root from './root.slice';

export type AppState = ReturnType<typeof reducer>;
// export type AppThunk<T = void> = ThunkAction<T, AppState, undefined, Action>;

export const useAppSelector = createSelectorHook<AppState, Action>();
// export const useAppDispatch: () => typeof store.dispatch = useDispatch;

export type AppThunkApiConfig = {
  state: AppState;
  dispatch: typeof store.dispatch;
};

const reducer = combineReducers({
  root: persistReducer({ key: 'root', storage: AsyncStorage }, root),
  certificates: persistReducer(
    { key: 'certificates', storage: AsyncStorage },
    certificates,
  ),
});

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export default store;
