import * as Notifications from 'expo-notifications';
import { StatusBar } from 'expo-status-bar';
import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '../store';
import { actions as rootActions } from '../store/root.slice';
import Navigation from './Navigation';
import Background from './UI/Background';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

Notifications.addNotificationResponseReceivedListener((response) => {
  store.dispatch(
    rootActions.setNotificationPath(
      response.notification.request.content.data.path as string | undefined,
    ),
  );
});

export default () => {
  return (
    <PersistGate persistor={persistor} loading={null}>
      <ReduxProvider store={store}>
        <Suspense fallback={<Background />}>
          <Navigation />
        </Suspense>
      </ReduxProvider>
      <StatusBar style="light" />
    </PersistGate>
  );
};
