import { UserType } from 'vacctrack';
import { useAppSelector } from '../store';
import useAuthUser from './useAuthUser';
import useUser from './useUser';

export default (type?: UserType) => {
  const authUser = useAuthUser();
  const user = useUser();

  const userTypeOverride = useAppSelector(
    ({ root: { userTypeOverride } }) => userTypeOverride,
  );

  const inverse = (() => {
    switch (type) {
      case 'patient':
        return 'msp';
      case 'msp':
        return 'patient';
      default:
        return 'undetermined';
    }
  })();

  if (userTypeOverride) {
    return userTypeOverride !== inverse;
  }

  if (!authUser || user === undefined) {
    return undefined;
  }

  return user?.type !== inverse;
};
