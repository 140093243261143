import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as rootActions } from '../store/root.slice';

export default () => {
  const dispatch = useDispatch();

  return useCallback(
    (message: string, routeName: string) => {
      dispatch(
        rootActions.setFlashMessage({
          message,
          routeName,
        }),
      );
    },
    [dispatch],
  );
};
