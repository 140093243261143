import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import {
  EthnicityInfo,
  IssueAgeGroup,
  IssueBloodType,
  IssueSource,
  PatientGender,
  RaceInfo,
} from 'vacctrack';
import CertificatesService from '../../../services/CertificatesService';
import IssuesFunctionsService from '../../../services/IssuesFunctionsService';
import RemindersService from '../../../services/RemindersService';
import dialog from '../../../util/dialog';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import VTText from '../../UI/VTText';
import { CertificatesNavigationProp } from '../CertificatesNavigator';
import useRouteServiceItem from '../hooks/useRouteServiceItem';
import StepDataOne, { DataOne } from './StepDataOne';
import StepDataThree, { DataThree } from './StepDataThree';
import StepDataTwo, { DataTwo } from './StepDataTwo';
import StepEffectInput, { EffectData } from './StepEffectInput';

enum Step {
  EffectInput,
  DataOne,
  DataTwo,
  DataThree,
}

const INITIAL_EFFECT_DATA: EffectData = {
  effects: [],
  otherEffect: '',
};

// sorry, this is a shortcut to avoid overly-strict type casting later on
const INITIAL_DATA_ONE: DataOne = {
  ageGroup: '' as IssueAgeGroup,
  gender: '' as PatientGender,
  bloodType: '' as IssueBloodType,
};

const INITIAL_DATA_TWO: DataTwo = {
  race: '' as RaceInfo,
  ethnicity: '' as EthnicityInfo,
};

const INITIAL_DATA_THREE: DataThree = {
  preexistingConditions: '',
  comment: '',
};

const INITIAL_STEP = 0;

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const { serviceItem } = useRouteServiceItem();

  const [step, setStep] = useState<Step>(INITIAL_STEP);
  const [effectData, setEffectData] = useState<EffectData>(INITIAL_EFFECT_DATA);
  const [dataOne, setDataOne] = useState<DataOne>(INITIAL_DATA_ONE);
  const [dataTwo, setDataTwo] = useState<DataTwo>(INITIAL_DATA_TWO);
  const [dataThree, setDataThree] = useState<DataThree>(INITIAL_DATA_THREE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const scrollView = useRef<ScrollView>(null);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setStep(INITIAL_STEP);
        setEffectData(INITIAL_EFFECT_DATA);
        setDataOne(INITIAL_DATA_ONE);
        setDataTwo(INITIAL_DATA_TWO);
        setDataThree(INITIAL_DATA_THREE);
        setIsSubmitting(false);
      };
    }, []),
  );

  useEffect(() => {
    scrollView.current?.scrollTo({ y: 0, animated: false });
  }, [step]);

  const submit = async (data: DataThree) => {
    if (!serviceItem || RemindersService.isReminder(serviceItem)) {
      return;
    }

    setDataThree(data);
    setIsSubmitting(true);

    const source: IssueSource = CertificatesService.isCertificate(serviceItem)
      ? 'certificate'
      : 'report';

    const {
      id: productId,
      lotNumber: productLotNumber = null,
      expiryDate: productExpiryDate = null,
    } = serviceItem.product;

    try {
      await IssuesFunctionsService.instance.issuesCreate({
        ...effectData,
        ...dataOne,
        ...dataTwo,
        ...data,
        source,
        productId,
        productLotNumber,
        productExpiryDate,
        shotNumber: serviceItem.shotNumber,
        serviceDate: serviceItem.serviceDate,
        serviceItemId: serviceItem.id,
      });

      await dialog.alert(t('issues.createSuccess'));

      navigation.popToTop();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const next = () =>
    setStep((s) => Math.min(s + 1, Object.keys(Step).length - 1));
  const prev = () => setStep((s) => Math.max(0, s - 1));

  const content = (() => {
    switch (step) {
      case Step.EffectInput:
        return (
          <StepEffectInput
            value={effectData}
            onSubmit={(value) => {
              setEffectData(value);
              next();
            }}
          />
        );
      case Step.DataOne:
        return (
          <StepDataOne
            value={dataOne}
            onSubmit={(data) => {
              setDataOne(data);
              next();
            }}
            onBack={prev}
          />
        );
      case Step.DataTwo:
        return (
          <StepDataTwo
            value={dataTwo}
            onSubmit={(data) => {
              setDataTwo(data);
              next();
            }}
            onBack={prev}
          />
        );
      case Step.DataThree:
        return (
          <StepDataThree
            value={dataThree}
            onSubmit={submit}
            isSubmitting={isSubmitting}
            onBack={prev}
          />
        );
      default:
        return <VTText>COMING SOON</VTText>;
    }
  })();

  return (
    <DefaultLayout ref={scrollView} scrollable>
      <Card title={t('issues.headerTitle')} margin>
        {content}
      </Card>
    </DefaultLayout>
  );
};
