import { Camera } from 'expo-camera';
import * as React from 'react';
import { BarcodeScanViewInnerProps } from '.';
// eslint-disable-next-line import/order
import { BARCODE_TYPES } from './shared';

// on
export default ({
  type,
  style,
  onBarCodeScanned,
}: BarcodeScanViewInnerProps) => (
  <Camera
    type={type}
    barCodeScannerSettings={{
      barCodeTypes: BARCODE_TYPES,
    }}
    onBarCodeScanned={onBarCodeScanned}
    style={style}
  />
);
