import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../../hooks/useAuthUser';
import ReportsService from '../../services/ReportsService';
import dialog from '../../util/dialog';
import theme from '../../util/theme';
import IssuesCreateSection from '../Shared/IssuesCreateSection';
import Card from '../UI/Card';
import CertificateLayout from '../UI/CertificateLayout';
import DefaultLayout from '../UI/DefaultLayout';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import { CertificatesNavigationProp } from './CertificatesNavigator';
import useRouteServiceItem from './hooks/useRouteServiceItem';

export default () => {
  const { t } = useTranslation();
  const { serviceItem, loading } = useRouteServiceItem();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const uid = useAuthUser()!.uid;

  const onDelete = async () => {
    if (
      !(await dialog.confirm(t, {
        message: t('certificates.reportDeleteConfirm'),
        destructive: true,
      }))
    ) {
      return;
    }

    await ReportsService.instance.collection(uid).doc(serviceItem!.id).delete();

    navigation.popToTop();
  };

  if (loading || !serviceItem || !ReportsService.isReport(serviceItem)) {
    return null;
  }

  return (
    <DefaultLayout scrollable>
      <Card>
        <CertificateLayout
          variant="report"
          data={serviceItem}
          title={
            <VTText variant="heading2">{t('certificates.reportTitle')}</VTText>
          }
        />
        <IssuesCreateSection />
        <VTButton
          startIcon="delete"
          title={t('certificates.reportDelete')}
          onPress={onDelete}
          style={{ marginTop: theme.spacing(2) }}
        />
      </Card>
    </DefaultLayout>
  );
};
