import { Camera } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import { MediaTypeOptions } from 'expo-image-picker';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import dialog from '../../../../util/dialog';
import { StepValueProps } from '../../../Navigation/Navigation';
import VTButton from '../../../UI/VTButton';
import VTText from '../../../UI/VTText';

interface Props extends StepValueProps<string> {
  isSubmitting: boolean;
}

export default ({ onBack, onSubmit, isSubmitting }: Props) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState<boolean | undefined>(
    Platform.OS === 'web' ? true : undefined,
  );

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        return;
      }

      const { status } = await Camera.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const pickImage = async () => {
    try {
      const res = await ImagePicker.launchCameraAsync({
        base64: true,
        mediaTypes: MediaTypeOptions.Images,
        quality: 0.5,
      });

      if (res.cancelled) {
        return;
      }

      onSubmit(
        Platform.select({
          web: res.uri.split(',')[1],
          default: res.base64!,
        }),
      );
    } catch (e) {
      // noinspection ES6MissingAwait
      dialog.alert(t('error.generic'));
    }
  };

  return (
    <>
      {hasPermission ? (
        <>
          <VTText paragraph>
            {t('certificates.proofPhotoUploadDescription')}
          </VTText>
          <VTButton
            variant="contained"
            pending={isSubmitting}
            title={
              Platform.OS === 'web'
                ? t('action.imagePick')
                : t('action.imageTake')
            }
            onPress={pickImage}
          />
        </>
      ) : (
        <>
          <VTText paragraph>{t('permissions.cameraPermissionTake')}</VTText>
          <VTButton
            variant="contained"
            title={t('permissions.openSettings')}
            onPress={() => Linking.openSettings()}
          />
        </>
      )}
      {isSubmitting ? (
        <VTText variant="caption" style={{ textAlign: 'center' }}>
          {t('certificates.createPending')}
        </VTText>
      ) : (
        <VTButton
          title={t('action.back')}
          disabled={isSubmitting}
          onPress={onBack}
        />
      )}
    </>
  );
};
