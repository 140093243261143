import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import theme from '../../util/theme';
import VTText from './VTText';

interface Props {
  value: number;
  label: string;
  style?: StyleProp<ViewStyle>;
}

export default ({ value, label, style }: Props) => (
  <View style={[styles.root, style]}>
    <View style={styles.outer}>
      <View style={[styles.inner, { width: `${value * 100}%` }]} />
    </View>
    <VTText variant="label">{label}</VTText>
  </View>
);

const HEIGHT = 8;

const styles = StyleSheet.create({
  root: {
    paddingBottom: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
  },
  outer: {
    flex: 1,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primaryLight,
    height: HEIGHT,
  },
  inner: {
    position: 'absolute',
    backgroundColor: theme.palette.primary,
    left: 0,
    top: 0,
    height: HEIGHT,
  },
});
