import Constants from 'expo-constants';
import { Env } from '../../types';

const config = {
  ...(Constants.manifest.extra as Env),
};

export default {
  ...config,
  emailVerificationContinueUrl: `${config.appUrl}/email-verified`,
};
