import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { object, SchemaOf, string } from 'yup';
import useDisplayFlashMessage from '../../hooks/useDisplayFlashMessage';
import PasswordField from '../Login/PasswordField';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTButton from '../UI/VTButton';
import { SettingsNavigationProp } from './SettingsNavigator';

interface FormData {
  oldPassword: string;
  newPassword: string;
}

const SCHEMA = (): SchemaOf<FormData> => object({
  oldPassword: string().required(),
  newPassword: string().newPassword().required(),
});

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<SettingsNavigationProp>();
  const newPasswordField = useRef<TextInput>(null);
  const displayFlashMessage = useDisplayFlashMessage();

  const onSubmit = async (
    { oldPassword, newPassword }: FormData,
    { setFieldError, setSubmitting }: FormikHelpers<FormData>,
  ) => {
    try {
      await firebase
        .auth()
        .currentUser!.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser!.email!,
            oldPassword,
          ),
        );

      await firebase.auth().currentUser!.updatePassword(newPassword);

      displayFlashMessage(
        t('settings.changePasswordSuccess'),
        'SettingsScreen',
      );

      navigation.pop();
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        setFieldError('oldPassword', e.code);
      } else {
        setFieldError('newPassword', e.code);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DefaultLayout>
      <Card title={t('settings.changePassword')}>
        <Formik
          initialValues={{ oldPassword: '', newPassword: '' }}
          validationSchema={SCHEMA}
          onSubmit={onSubmit}
        >
          <>
            <PasswordField
              formik="oldPassword"
              nextField={newPasswordField}
              placeholder={t('settings.currentPassword')}
            />
            <PasswordField
              formik="newPassword"
              ref={newPasswordField}
              placeholder={t('settings.newPassword')}
              textContentType="newPassword"
              helperText={t('login.passwordHelperText')}
            />
            <VTButton
              formik="submit"
              variant="contained"
              title={t('settings.changePassword')}
            />
          </>
        </Formik>
      </Card>
    </DefaultLayout>
  );
};
