import { Certificate, Report, ServiceItem } from 'vacctrack';
import FirestoreSubcollectionService from './FirestoreSubcollectionService';

export default class ReportsService extends FirestoreSubcollectionService<Report> {
  static instance = new ReportsService();

  static isReport(item: ServiceItem): item is Report {
    return !(item as Certificate).msp;
  }

  constructor() {
    super('users', 'reports');
  }
}
