import { UsersCreateRequest, UsersPushTokenAddRequest, UsersSetPatientDataRequest } from 'vacctrack';
import FunctionsService from './FunctionsService';

export default class UsersFunctionsService extends FunctionsService {
  static instance = new UsersFunctionsService();

  usersCreate(data: UsersCreateRequest) {
    return this.call<UsersCreateRequest>('usersCreate', data);
  }

  /**
   * This method can be used to confirm as well as invalidate
   */
  usersEmailConfirm() {
    return this.call('usersEmailConfirm');
  }

  usersSetPatientData(data: UsersSetPatientDataRequest) {
    return this.call<
      UsersSetPatientDataRequest
    >('usersSetPatientData', data);
  }

  usersPushTokenAdd(token: string) {
    return this.call<UsersPushTokenAddRequest>('usersPushTokenAdd', {
      token,
    });
  }

  usersPushTokenRemove(token: string) {
    return this.call<UsersPushTokenAddRequest>('usersPushTokenRemove', {
      token,
    });
  }

  usersSetNotificationsRead() {
    return this.call('usersSetNotificationsRead');
  }
}
