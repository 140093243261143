import {
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
  SourceSansPro_700Bold,
  useFonts,
} from '@expo-google-fonts/source-sans-pro';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAuthUser from '../../../hooks/useAuthUser';
import UsersService from '../../../services/UsersService';
import { useAppSelector } from '../../../store';
import { actions as rootActions } from '../../../store/root.slice';

export default function useInitialLoading() {
  const dispatch = useDispatch();
  const [fontsLoaded] = useFonts({
    SourceSansPro_400Regular,
    SourceSansPro_600SemiBold,
    SourceSansPro_700Bold,
  });
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const authUser = useAuthUser();

  const userLoading = useAppSelector(
    ({ root: { user } }) => user === undefined,
  );

  const authUserLoading = authUser === undefined;

  const isReady =
    !userLoading && !authUserLoading && fontsLoaded && isLoadingComplete;

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await Promise.all([
          Font.loadAsync(MaterialCommunityIcons.font),
          dispatch(rootActions.loadMainScreenForUser(authUser?.uid ?? '')),
        ]);
      } catch (e) {
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    void loadResourcesAndDataAsync();
  }, [authUser?.uid, dispatch]);

  useEffect(() => {
    void SplashScreen.preventAutoHideAsync();
  }, []);

  useEffect(() => {
    if (isReady) {
      // this is to prevent a flash of background
      window.setTimeout(() => {
        void SplashScreen.hideAsync();
      }, 100);
    }
  }, [isReady]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    return UsersService.instance.get$(authUser.uid)((user) => {
      dispatch(rootActions.setUser(user ?? null));
    });
  }, [authUser, dispatch]);

  return isReady;
}
