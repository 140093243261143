import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import theme from '../../util/theme';
import Divider from './Divider';
import VTIcon from './VTIcon';
import VTText from './VTText';

interface Props {
  value: boolean;
  divider: boolean;
  onPress: () => void;
  text: string;
}

export default ({ value, onPress, text, divider }: Props) => {
  return (
    <>
      {divider && <Divider />}
      <TouchableOpacity style={styles.root} onPress={onPress}>
        <VTIcon
          style={styles.icon}
          name={value ? 'checkFull' : 'checkEmpty'}
          size={theme.spacing(2)}
          color={value ? theme.palette.primary : theme.palette.divider}
        />
        <VTText>{text}</VTText>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacing(1),
  },
  icon: {
    marginHorizontal: theme.spacing(1),
  },
});
