import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { object, SchemaOf, string } from 'yup';
import useDisplayFlashMessage from '../../hooks/useDisplayFlashMessage';
import UsersFunctionsService from '../../services/UsersFunctionsService';
import config from '../../util/config';
import PasswordField from '../Login/PasswordField';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTButton from '../UI/VTButton';
import VTTextInput, { COMMON_TEXT_INPUT_PROPS } from '../UI/VTTextInput';
import { SettingsNavigationProp } from './SettingsNavigator';

interface FormData {
  email: string;
  password: string;
}

const SCHEMA = (): SchemaOf<FormData> => object({
  email: string().email().required(),
  password: string().required(),
});

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<SettingsNavigationProp>();
  const passwordField = useRef<TextInput>(null);
  const displayFlashMessage = useDisplayFlashMessage();

  const onSubmit = async (
    { email, password }: FormData,
    { setFieldError, setSubmitting }: FormikHelpers<FormData>,
  ) => {
    try {
      await firebase
        .auth()
        .currentUser!.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser!.email!,
            password,
          ),
        );

      await firebase.auth().currentUser!.updateEmail(email);

      await firebase.auth().currentUser!.reload();

      await Promise.all([
        firebase.auth().currentUser!.sendEmailVerification({
          url: config.emailVerificationContinueUrl,
        }),
        firebase.auth().currentUser?.getIdToken(true),
      ]);

      await UsersFunctionsService.instance.usersEmailConfirm();

      displayFlashMessage(t('settings.changeEmailSuccess'), 'SettingsScreen');

      navigation.pop();
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        setFieldError('password', e.code);
      } else {
        setFieldError('email', e.code);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DefaultLayout>
      <Card title={t('settings.changeEmail')}>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={SCHEMA}
          onSubmit={onSubmit}
        >
          <>
            <VTTextInput
              {...COMMON_TEXT_INPUT_PROPS.email}
              formik="email"
              nextField={passwordField}
              placeholder={t('settings.newEmail')}
              helperText={t('settings.changeEmailHelp')}
            />
            <PasswordField
              formik="password"
              ref={passwordField}
              placeholder={t('settings.currentPassword')}
            />
            <VTButton
              formik="submit"
              variant="contained"
              title={t('settings.changeEmail')}
            />
          </>
        </Formik>
      </Card>
    </DefaultLayout>
  );
};
