import { useNavigation } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import useAuthUser from '../../hooks/useAuthUser';
import useUser from '../../hooks/useUser';
import theme from '../../util/theme';
import CloseButton from '../Main/CloseButton';
import { MainNavigatorParamList } from '../Main/MainNavigator';
import { navigationHeaderOptions } from '../Main/shared';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import VTIcon from '../UI/VTIcon';
import ChangeEmailScreen from './ChangeEmailScreen';
import ChangeLanguageScreen from './ChangeLanguageScreen';
import ChangePasswordScreen from './ChangePasswordScreen';
import MspInfoScreen from './MspInfoScreen';
import PatientInfoScreen from './PatientInfoScreen';
import SettingsScreen from './SettingsScreen';

export type SettingsNavigatorParamList = {
  SettingsScreen: undefined;
  ChangeEmailScreen: undefined;
  ChangePasswordScreen: undefined;
  ChangeLanguageScreen: undefined;
  PatientInfoScreen?: {
    returnTo?: keyof MainNavigatorParamList;
  };
  MspInfoScreen: undefined;
};

export type SettingsNavigationProp = StackNavigationProp<SettingsNavigatorParamList>;

const Stack = createStackNavigator<SettingsNavigatorParamList>();

export default () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const user = useUser();
  const navigation = useNavigation<RootNavigationProp>();

  return (
    <Stack.Navigator initialRouteName="SettingsScreen">
      <Stack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{
          title: t('settings.title'),
          ...navigationHeaderOptions(true),
        }}
      />
      {authUser && (
        <>
          <Stack.Screen
            name="ChangeEmailScreen"
            component={ChangeEmailScreen}
            options={{
              title: t('settings.changeEmail'),
              ...navigationHeaderOptions(),
            }}
          />
          <Stack.Screen
            name="ChangePasswordScreen"
            component={ChangePasswordScreen}
            options={{
              title: t('settings.changePassword'),
              ...navigationHeaderOptions(),
            }}
          />
        </>
      )}
      <Stack.Screen
        name="ChangeLanguageScreen"
        component={ChangeLanguageScreen}
        options={{
          title: t('settings.changeLanguage'),
          ...navigationHeaderOptions(),
          headerLeft: authUser
            ? undefined
            : () => (
                <CloseButton
                  onPress={() => navigation.navigate('LandingScreen')}
                />
              ),
        }}
      />
      {authUser && (
        <Stack.Screen
          name="PatientInfoScreen"
          component={PatientInfoScreen}
          options={{
            title: t('settings.patientInfo'),
            gestureEnabled: false,
            ...Platform.select({
              ios: {
                headerBackTitle: t('action.cancel'),
              },
              android: {
                headerBackImage: () => (
                  <VTIcon
                    name="close"
                    color={theme.palette.background}
                    size={theme.spacing(3.5)}
                  />
                ),
              },
            }),
            ...navigationHeaderOptions(),
            headerLeft: () => <CloseButton popToTopFallback="SettingsScreen" />,
          }}
        />
      )}
      {user?.msp?.verifiedTimestamp && (
        <Stack.Screen
          name="MspInfoScreen"
          component={MspInfoScreen}
          options={{
            title: t('settings.mspInfo'),
            ...navigationHeaderOptions(),
          }}
        />
      )}
    </Stack.Navigator>
  );
};
