import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTText from '../UI/VTText';

export default () => {
  const { t } = useTranslation();

  const mspNpiInfo = useAppSelector(({ root: { user } }) => user?.msp?.npiInfo);

  if (!mspNpiInfo) {
    return null;
  }

  return (
    <DefaultLayout>
      <Card title={t('settings.mspInfo')}>
        <VTText>{mspNpiInfo.basic.name}</VTText>
        <VTText>
          {t('settings.mspNpiNumber')}: {mspNpiInfo.number}
        </VTText>
      </Card>
    </DefaultLayout>
  );
};
