import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import VTTextInput from '../../UI/VTTextInput';
import { CodeInputProps } from './CertificatesGetNewScreen';

export const CODE_REGEX = /[A-Z0-9]{12}/;

export default ({ onResult, onCancel, loading }: CodeInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const onSubmitEditing = () => {
    setError(false);

    const v = value.replace(/-/g, '');

    if (v.match(CODE_REGEX)) {
      onResult(v);
      setValue('');
    } else {
      setError(true);
    }
  };

  const onChangeText = (text: string) => {
    const value = text
      .toUpperCase()
      .replace(/[^A-Z0-9-]/g, '')
      .replace(/[01IO]/g, '');

    const netLength = value.replace(/-/g, '').length;
    const numDashes = (value.match(/-/g) ?? []).length;

    if (netLength > 12 || numDashes > 2) {
      return;
    }

    setValue(value);
  };

  return (
    <>
      <VTTextInput
        value={value}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
        label={t('certificates.enterCodeLabel')}
        enablesReturnKeyAutomatically
        error={error}
        placeholder={t('certificates.enterCodePlaceholder')}
        helperText={
          error
            ? t('certificates.error', { context: 'invalid-argument' })
            : t('certificates.enterCodeHelp')
        }
        keyboardType="visible-password"
        autoCompleteType="off"
        autoCorrect={false}
        autoFocus
      />
      <VTButton
        variant="contained"
        title={t('certificates.submitCode')}
        onPress={onSubmitEditing}
        pending={loading}
      />
      {loading ? (
        <VTText variant="caption">{t('certificates.createPending')}</VTText>
      ) : (
        <VTButton
          title={t('action.cancel')}
          onPress={onCancel}
          disabled={loading}
        />
      )}
    </>
  );
};
