import { useEffect } from 'react';
import { STORAGE_MAIN_SCREEN } from '../util/constants';
import useUserSpecificStorage from './useUserSpecificStorage';

export default (name: string) => {
  const userSpecificStorage = useUserSpecificStorage();

  useEffect(() => {
    void userSpecificStorage.setItem(STORAGE_MAIN_SCREEN, name);
  }, [userSpecificStorage, name]);
};
