import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import theme from '../../../util/theme';
import VTButton from '../../UI/VTButton';
import VTQRCode from '../../UI/VTQRCode';
import VTText from '../../UI/VTText';

interface Props {
  value: string;
  onBack: () => void;
}

export default ({ value, onBack }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <VTQRCode
        value={value}
        logo={require('../../../../assets/images/certificate-outline.png')}
      />
      <VTText style={styles.description} paragraph>
        {t('certificates.shareCodeDescription')}
      </VTText>
      <VTButton variant="contained" title={t('action.back')} onPress={onBack} />
    </>
  );
};

const styles = StyleSheet.create({
  description: {
    marginTop: theme.spacing(2),
  },
});
