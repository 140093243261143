import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, ParamListBase } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import useAuthUser from '../../hooks/useAuthUser';
import useUserSpecificStorage from '../../hooks/useUserSpecificStorage';
import useUserTypeCheck from '../../hooks/useUserTypeCheck';
import UsersFunctionsService from '../../services/UsersFunctionsService';
import { useAppSelector } from '../../store';
import { STORAGE_PUSH_TOKEN } from '../../util/constants';
import theme from '../../util/theme';
import LandingScreen from '../Landing/LandingScreen';
import MainNavigator, { MainNavigatorParamList } from '../Main/MainNavigator';
import EmailVerifiedScreen from './EmailVerifiedScreen';
import useCheckForEmailVerification from './hooks/useCheckForEmailVerification';
import NotFoundScreen from './NotFoundScreen';

export type NestedNavigateParams<T, P = never> = {
  screen?: keyof T;
  params?: P;
  initial?: boolean;
};

type SubNavigator<T extends ParamListBase> = {
  [K in keyof T]: {
    screen: K;
    params?: T[K] | SubNavigator<ParamListBase>;
  };
}[keyof T];

export type RootNavigatorParamList = {
  LandingScreen: undefined;
  MainNavigator?: SubNavigator<MainNavigatorParamList>;
  NotFoundScreen: undefined;
  EmailVerifiedScreen: undefined;
};

export type RootNavigationProp = CompositeNavigationProp<
  DrawerNavigationProp<MainNavigatorParamList>,
  StackNavigationProp<RootNavigatorParamList>
>;

const Root = createStackNavigator<RootNavigatorParamList>();

export default () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const loggedIn = !!authUser;
  const mainScreen = useAppSelector(({ root: { mainScreen } }) => mainScreen);
  const isMsp = useUserTypeCheck('msp');
  const [hasPermission, setHasPermission] = useState<boolean>();
  const userSpecificStorage = useUserSpecificStorage();

  useEffect(() => {
    (async () => {
      setHasPermission(
        (await Notifications.getPermissionsAsync()).status === 'granted',
      );
    })();
  }, []);

  useEffect(() => {
    async function getPushToken() {
      try {
        const [tokenResult, existingToken] = await Promise.all([
          Notifications.getExpoPushTokenAsync(),
          userSpecificStorage.getItem(STORAGE_PUSH_TOKEN),
        ]);

        const token = tokenResult.data;

        if (token === existingToken) {
          return;
        }

        // noinspection ES6MissingAwait
        UsersFunctionsService.instance.usersPushTokenAdd(token);
        // noinspection ES6MissingAwait
        userSpecificStorage.setItem(STORAGE_PUSH_TOKEN, token);

        if (Platform.OS === 'android') {
          // noinspection ES6MissingAwait
          Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            lightColor: theme.palette.primary,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (!Constants.isDevice || !loggedIn || isMsp !== false || !hasPermission) {
      return;
    }

    void getPushToken();
  }, [isMsp, loggedIn, hasPermission, userSpecificStorage]);

  useCheckForEmailVerification(false);

  return (
    <Root.Navigator
      initialRouteName={
        authUser && mainScreen ? 'MainNavigator' : 'LandingScreen'
      }
      mode="modal"
      screenOptions={{ headerShown: false }}
    >
      {!authUser && (
        <Root.Screen
          name="LandingScreen"
          component={LandingScreen}
          options={{
            title: t('landing.title'),
          }}
        />
      )}
      <Root.Screen
        name="MainNavigator"
        component={MainNavigator}
        // disable swipe down to close
        options={{ gestureEnabled: false }}
      />
      <Root.Screen
        name="NotFoundScreen"
        component={NotFoundScreen}
        options={{ title: t('error.notFound') }}
      />
      {!!authUser && (
        <Root.Screen
          name="EmailVerifiedScreen"
          component={EmailVerifiedScreen}
          options={{ title: t('settings.verifyingEmail') }}
        />
      )}
    </Root.Navigator>
  );
};
