import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RootNavigationProp } from '../../Navigation/RootNavigator';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';

interface Props {
  onProceed: () => void;
}

export default ({ onProceed }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<RootNavigationProp>();

  return (
    <>
      <VTText paragraph>{t('settings.patientSuccess')}</VTText>
      <VTButton
        title={t('action.proceed')}
        onPress={() => {
          navigation.popToTop();
          onProceed();
          navigation.navigate('MainNavigator', {
            screen: 'CertificatesNavigator',
          });
        }}
      />
    </>
  );
};
