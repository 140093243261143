import format from 'date-fns/format';
import pick from 'lodash/pick';
import { PersonalData } from 'vacctrack';
import { stringToDate } from '../../../../util/date';
import { PersonalDataFormData } from '../PersonalDataInput';

export function personalDataToPersonalDataFormData(
  data?: PersonalData,
): PersonalDataFormData {
  return {
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    dateOfBirth: data?.dateOfBirth ?? Date.now(),
    dateOfBirthString: data?.dateOfBirth
      ? format(data.dateOfBirth, 'MM/dd/yyyy')
      : '',
    ethnicity: data?.ethnicity,
    firstName: '',
    gender: data?.gender as 'F', // sorry, we need to have an empty input,
    groupPolicyNumber: '',
    insuranceProvider: '',
    insuredIsDifferent: !!data?.insured,
    lastName: '',
    middleName: '',
    phone: '',
    race: data?.race,
    state: data?.state ?? '',
    zip: '',
    ...pick(data, [
      'addressLine1',
      'addressLine2',
      'city',
      'county',
      'firstName',
      'gender',
      'groupPolicyNumber',
      'insuranceProvider',
      'lastName',
      'middleName',
      'phone',
      'state',
      'zip',
    ]),

    ins_addressLine1: data?.insured?.addressLine1 ?? '',
    ins_addressLine2: data?.insured?.addressLine2 ?? '',
    ins_city: data?.insured?.city ?? '',
    ins_county: data?.insured?.county ?? '',
    ins_dateOfBirthString: data?.insured?.dateOfBirth
      ? format(data.insured.dateOfBirth, 'MM/dd/yyyy')
      : '',
    ins_firstName: data?.insured?.firstName ?? '',
    ins_lastName: data?.insured?.lastName ?? '',
    ins_middleName: data?.insured?.middleName ?? '',
    ins_phone: data?.insured?.phone ?? '',
    ins_state: data?.insured?.state ?? '',
    ins_zip: data?.insured?.zip ?? '',
  };
}

export function personalDataFormDataToPersonalData(
  personalData: PersonalDataFormData,
): PersonalData {
  const {
    dateOfBirthString,
    ins_addressLine1,
    ins_addressLine2,
    ins_city,
    ins_county,
    ins_dateOfBirthString,
    ins_firstName,
    ins_lastName,
    ins_middleName,
    ins_phone,
    ins_state,
    ins_zip,
    insuredIsDifferent,
    ...personalDataRest
  } = personalData!;

  const insured: PersonalData['insured'] = insuredIsDifferent
    ? {
        addressLine1: ins_addressLine1,
        addressLine2: ins_addressLine2,
        city: ins_city,
        county: ins_county,
        dateOfBirth: stringToDate(ins_dateOfBirthString!)!.getTime(),
        firstName: ins_firstName!,
        lastName: ins_lastName!,
        middleName: ins_middleName!,
        phone: ins_phone,
        state: ins_state,
        zip: ins_zip,
      }
    : null;

  return {
    ...personalDataRest,
    dateOfBirth: stringToDate(dateOfBirthString)!.getTime(),
    insured,
  };
}
