import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../../hooks/useAuthUser';
import { STORAGE_LANGUAGE } from '../../util/constants';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import Divider from '../UI/Divider';
import VTButton from '../UI/VTButton';

const LANGUAGES = [
  {
    key: 'en',
    name: 'English',
  },
  {
    key: 'es',
    name: 'Español',
  },
  {
    key: 'fr',
    name: 'Français',
  },
];

export default () => {
  const { i18n } = useTranslation();
  const navigation = useNavigation<RootNavigationProp>();
  const authUser = useAuthUser();

  const changeLanguage = (language: string) => {
    void AsyncStorage.setItem(STORAGE_LANGUAGE, language);
    void i18n.changeLanguage(language);

    if (authUser) {
      navigation.pop();
    } else {
      navigation.navigate('LandingScreen');
    }
  };

  return (
    <DefaultLayout>
      <Card padding="dense">
        {LANGUAGES.map((l, i) => (
          <React.Fragment key={l.key}>
            <VTButton
              title={l.name}
              disabled={i18n.language === l.key}
              onPress={() => changeLanguage(l.key)}
              margin
            />
            {i < LANGUAGES.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Card>
    </DefaultLayout>
  );
};
