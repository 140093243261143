import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TextInput, View } from 'react-native';
import theme from '../../util/theme';
import VTText from '../UI/VTText';
import VTTextInput from '../UI/VTTextInput';

interface Props {
  nextField?: React.RefObject<TextInput> | boolean;
}

export default ({ nextField }: Props) => {
  const { t } = useTranslation();
  const shotNumberOfField = useRef<TextInput>(null);

  return (
    <>
      <VTText paragraph>{t('tickets.shotNumberHelperText')}</VTText>
      <View style={styles.row}>
        <VTTextInput
          keyboardType="numeric"
          style={[styles.cell, styles.cellLeft]}
          formik="shotNumber"
          nextField={shotNumberOfField}
          label={t('tickets.shotNumber')}
        />
        <VTTextInput
          keyboardType="numeric"
          style={styles.cell}
          formik="shotNumberOf"
          ref={shotNumberOfField}
          nextField={nextField}
          label={t('tickets.shotNumberOf')}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  cell: {
    flex: 1,
  },
  cellLeft: {
    marginRight: theme.spacing(2),
  },
});
