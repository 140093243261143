// noinspection DuplicatedCode
import firebase from 'firebase/app';

export interface Id {
  id: string;
}

export function unwrapQuerySnapshot<T>(
  snapshot: firebase.firestore.QuerySnapshot<T>,
) {
  return snapshot.docs.map((d) => d.data());
}

export function query$<T>(query: firebase.firestore.Query<T>) {
  return (callback: (data: T[]) => void) =>
    query.onSnapshot((data) => callback(unwrapQuerySnapshot(data)));
}

export function ref$<T>(ref: firebase.firestore.DocumentReference<T>) {
  return (callback: (data: T | undefined) => void) =>
    ref.onSnapshot((data) => callback(data.data()));
}

export function typeConverter<T>(): firebase.firestore.FirestoreDataConverter<
  T & Id
> {
  return {
    toFirestore(data: T & Id): firebase.firestore.DocumentData {
      const { id, ...rest } = data;
      return rest;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): T & Id {
      const data = snapshot.data(options)!;
      return { ...data, id: snapshot.id } as T & Id;
    },
  };
}

export default class FirestoreService<T extends object = object> {
  protected converter = typeConverter<T>();

  constructor(protected collectionName: string) {}

  collection() {
    return firebase
      .firestore()
      .collection(this.collectionName)
      .withConverter(this.converter);
  }

  doc(id: string) {
    return this.collection().doc(id);
  }

  async list() {
    return unwrapQuerySnapshot(await this.collection().get());
  }
}
