import {
  MspConfirmPhoneVerificationRequest,
  MspGetNpiInfoRequest,
  MspGetNpiInfoResponse,
  MspRequestPhoneVerificationRequest,
  PhoneNumberWithToken,
} from 'vacctrack';
import FunctionsService from './FunctionsService';

export default class MspFunctionsService extends FunctionsService {
  static instance = new MspFunctionsService();

  mspGetNpiInfo(npi: string) {
    return this.call<MspGetNpiInfoRequest, MspGetNpiInfoResponse>(
      'mspGetNpiInfo',
      { npi },
    );
  }

  mspRequestPhoneVerification(npi: string, phoneNumber: PhoneNumberWithToken) {
    return this.call<MspRequestPhoneVerificationRequest>(
      'mspRequestPhoneVerification',
      {
        npi,
        ...phoneNumber,
      },
    );
  }

  mspConfirmVerificationCode(code: string) {
    return this.call<MspConfirmPhoneVerificationRequest>(
      'mspConfirmPhoneVerification',
      {
        code,
      },
    );
  }
}
