import { addMethod, setLocale, string, StringSchema } from 'yup';
import { stringToDate } from './date';

export interface DateOptions {
  min?: number;
  max?: number;
  optional?: boolean;
}

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StringSchema {
    dateString(options: DateOptions): StringSchema;
    dateOfBirthString(options?: DateOptions): StringSchema;
    newPassword(): StringSchema;
  }
}

const MIN_PASSWORD_LENGTH = 6;

const MAX_DOB_AGE = 5049112320000; // 160 years (who knows what the future may hold)

function getDate(value?: string | null) {
  if (!value) {
    return null;
  }

  const date = stringToDate(value);

  if (!date) {
    return null;
  }

  return date;
}

function checkDate(value: string | null | undefined, options: DateOptions) {
  if (!value) {
    return !!options.optional;
  }

  const date = getDate(value);

  if (!date) {
    return false;
  }

  const now = Date.now();

  const fromOk =
    options.min !== undefined ? date.getTime() > now - options.min : true;
  const toOk =
    options.max !== undefined ? date.getTime() < now + options.max : true;

  return fromOk && toOk;
}

function checkDateOfBirthString(
  value: string | null | undefined,
  options: DateOptions = {},
) {
  if (!value) {
    return !!options.optional;
  }

  const date = getDate(value);

  if (!date) {
    return false;
  }

  const ms = date.getTime();

  // date must be between max age and now
  return !(Date.now() - ms > MAX_DOB_AGE || date.getTime() > Date.now());
}

export function initSchema() {
  setLocale({
    mixed: {
      required: () => ({
        key: 'error.required',
      }),
    },
    string: {
      email: 'auth/invalid-email',
      matches: 'format',
    },
  });

  addMethod(string, 'newPassword', function (this: StringSchema) {
    return this.min(MIN_PASSWORD_LENGTH, 'auth/weak-password');
  });

  addMethod(
    string,
    'dateOfBirthString',
    function (this: StringSchema, options?: DateOptions) {
      return this.test('is-valid-dob', 'invalid', (v) =>
        checkDateOfBirthString(v, options),
      );
    },
  );

  addMethod(
    string,
    'dateString',
    function (this: StringSchema, options: DateOptions) {
      return this.test('is-valid-date', 'invalid', (v) =>
        checkDate(v, options),
      ) as StringSchema;
    },
  );
}
