import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import QRCode, { QRCodeProps } from 'react-native-qrcode-svg';
import theme from '../../util/theme';
import { getContainerWidth } from './Container';

export default (props: QRCodeProps) => {
  const { width } = useWindowDimensions();
  const containerWidth = getContainerWidth(width) - theme.spacing(6);

  return (
    <QRCode logoSize={containerWidth / 5} size={containerWidth} {...props} />
  );
};
