import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { ServiceItem } from 'vacctrack';
import CertificatesService from '../../../services/CertificatesService';
import { Id } from '../../../services/FirestoreService';
import { useAppSelector } from '../../../store';
import { actions as certificateActions } from '../../../store/certificates.slice';
import { CertificatesNavigatorParamList } from '../CertificatesNavigator';

export default function useRouteServiceItem() {
  const dispatch = useDispatch();
  const route = useRoute<
    RouteProp<CertificatesNavigatorParamList, 'CertificatesDetailScreen'>
  >();

  const id = route.params.id;

  const { serviceItem, pdfUrl, loading } = useAppSelector(
    ({
      certificates: { certificates, certificateDetail, reports, reminders },
    }) => {
      const items: (ServiceItem & Id)[] | undefined =
        certificates && reports && reminders
          ? [...certificates, ...reports, ...reminders]
          : undefined;

      return {
        serviceItem: items?.find((c) => c.id === id),
        pdfUrl:
          certificateDetail && certificateDetail.certificate.id === id
            ? certificateDetail.pdfUrl
            : undefined,
        loading: !certificates || !reports,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (!serviceItem || !CertificatesService.isCertificate(serviceItem)) {
      return;
    }

    dispatch(certificateActions.loadCertificateDetail(serviceItem));
  }, [serviceItem, dispatch]);

  return useMemo(() => ({ serviceItem, pdfUrl, loading }), [
    serviceItem,
    loading,
    pdfUrl,
  ]);
}
