import { RouteProp, useRoute } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import CertificatesFunctionsService from '../../services/CertificatesFunctionsService';
import { VERIFY_PARAM_NAME } from '../../util/constants';
import BarcodeScanView from '../UI/BarcodeScanView';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VerifyActivate from './VerifyActivate';
import { VerifyNavigatorParamList } from './VerifyNavigator';
import VerifyResult, { VerifyResultType } from './VerifyResult';
import VerifyUpload from './VerifyUpload';

export default () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [result, setResult] = useState<VerifyResultType | undefined>();
  const route = useRoute<RouteProp<VerifyNavigatorParamList, 'VerifyScreen'>>();

  const onVerify = useCallback(async (code: string) => {
    setLoading(true);

    try {
      setResult(
        await CertificatesFunctionsService.instance.certificatesVerifyWithCode({
          code,
        }),
      );
    } catch (e) {
      setResult({
        result: null,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const c = route.params?.[VERIFY_PARAM_NAME];

    if (c) {
      void onVerify(c);
    }
  }, [onVerify, route]);

  const content = (() => {
    if (result) {
      return <VerifyResult data={result} onBack={() => setResult(undefined)} />;
    }

    if (!active) {
      return (
        <VerifyActivate loading={loading} onActivate={() => setActive(true)} />
      );
    }

    return (
      <BarcodeScanView
        paramName={VERIFY_PARAM_NAME}
        onResult={onVerify}
        loading={loading}
        helperText={loading ? t('verify.verifyPending') : t('verify.helpText')}
        onCancel={() => setActive(false)}
      />
    );
  })();

  return (
    <DefaultLayout scrollable>
      <Card margin>{content}</Card>
      {!active && !result && Platform.OS === 'web' && (
        <Card>
          <VerifyUpload onResult={(valid) => setResult({ result: valid })} />
        </Card>
      )}
    </DefaultLayout>
  );
};
