import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { navigationHeaderOptions } from '../Main/shared';
import MspRegisterScreen from './MspRegisterScreen';

export type MspRegisterNavigatorParamList = {
  MspRegisterScreen: undefined;
};

const Stack = createStackNavigator<MspRegisterNavigatorParamList>();

export default () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MspRegisterScreen"
        component={MspRegisterScreen}
        options={{
          title: t('mspRegister.title'),
          ...navigationHeaderOptions(true),
        }}
      />
    </Stack.Navigator>
  );
};
