import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import theme from '../../util/theme';
import VTText from '../UI/VTText';

export default () => {
  const { t } = useTranslation();

  return (
    <VTText variant="heading2" style={styles.subheading}>
      {t('landing.subheading')}
    </VTText>
  );
};

const styles = StyleSheet.create({
  subheading: {
    color: theme.palette.background,
    textAlign: 'center',
    paddingHorizontal: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});
