import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Updates from 'expo-updates';
import firebase, { User } from 'firebase/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AppState, AppStateStatus, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import BroadcastsService from '../services/BroadcastsService';
import { query$ } from '../services/FirestoreService';
import { actions as rootActions, AuthUser } from '../store/root.slice';
import { STORAGE_LAST_UPDATE_CHECK } from '../util/constants';
import useAuthUser from './useAuthUser';

const UPDATE_COOLDOWN = 1800000; // 30 min

export default () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const authUser = useAuthUser();

  useEffect(() => {
    async function checkForUpdates() {
      if (Constants.appOwnership !== 'standalone') {
        return;
      }

      try {
        const lastUpdateStr = await AsyncStorage.getItem(
          STORAGE_LAST_UPDATE_CHECK,
        );
        const lastUpdate = lastUpdateStr
          ? new Date(parseInt(lastUpdateStr, 10)).getTime()
          : 0;

        if (lastUpdate + UPDATE_COOLDOWN > Date.now()) {
          return;
        }

        const { isNew } = await Updates.fetchUpdateAsync();

        if (isNew) {
          Alert.alert(
            t('app.updateAvailableTitle'),
            t('app.updateAvailableMessage'),
            [
              {
                text: t('app.restartNow'),
                onPress: Updates.reloadAsync,
              },
              {
                text: t('app.noThanks'),
                style: 'cancel',
              },
            ],
            { cancelable: false },
          );
        }
      } catch (e) {
        // reasons for failure include non-standalone
        console.log(e);
      } finally {
        // noinspection ES6MissingAwait
        void AsyncStorage.setItem(
          STORAGE_LAST_UPDATE_CHECK,
          Date.now().toString(),
        );
      }
    }

    async function onAppStateChange(status: AppStateStatus) {
      switch (status) {
        case 'active':
          if (Platform.OS === 'ios') {
            // noinspection ES6MissingAwait
            Notifications.setBadgeCountAsync(0);
          }

          // noinspection ES6MissingAwait
          checkForUpdates();

          break;
      }
    }

    if (Platform.OS === 'web') {
      return;
    }

    checkForUpdates().then(() => {
      AppState.addEventListener('change', onAppStateChange);
    });

    return () => {
      AppState.removeEventListener('change', onAppStateChange);
    };
  }, [t]);

  useEffect(() => {
    async function authChangeHandler(user: User | null) {
      if (!user) {
        dispatch(rootActions.setAuthUser(null));
        return;
      }

      dispatch(rootActions.setAuthUser(user.toJSON() as AuthUser));
    }

    return firebase.auth().onAuthStateChanged(authChangeHandler);
  }, [dispatch]);

  useEffect(() => {
    if (authUser) {
      return query$(
        BroadcastsService.instance.collection().orderBy('created', 'desc'),
      )((b) => dispatch(rootActions.setBroadcasts(b)));
    }
  }, [authUser, dispatch]);

  useEffect(() => {
    firebase.auth().languageCode = language.replace('-', '_');
  }, [language]);
};
