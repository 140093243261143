import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Share, ShareContent, ShareOptions } from 'react-native';
import CodeText from '../../UI/CodeText';
import VTButton, { VTButtonProps } from '../../UI/VTButton';
import VTText from '../../UI/VTText';

interface Props {
  formattedCode: string;
  codeValue: string;
  onShowTicket: () => void;
}

export default ({ formattedCode, codeValue, onShowTicket }: Props) => {
  const { t } = useTranslation();

  const title = t('tickets.shareMessage');

  const onShare = async () => {
    const message = `${title}:
  
${codeValue}
   
   ${t('tickets.shareMessagePromotion')}`;

    const content: ShareContent = {
      message,
      url: codeValue,
      title,
    };

    const options: ShareOptions = {
      subject: title,
      dialogTitle: t('tickets.shareTicket'),
    };

    await Share.share(content, options);
  };

  const buttonProps: VTButtonProps = {
    title: t('tickets.shareTicket'),
    startIcon: 'share',
  };

  const shareButton =
    Platform.OS === 'web' && !window.navigator.share ? (
      <a
        href={`mailto:?subject=${title}&body=${encodeURIComponent(
          `${title}: \r\n${formattedCode}\r\n\r\n${codeValue}\r\n\r\n${t(
            'tickets.shareMessagePromotion',
          )}`,
        )}`}
        style={{ textDecoration: 'none' }}
      >
        <VTButton {...buttonProps} />
      </a>
    ) : (
      <VTButton onPress={onShare} {...buttonProps} />
    );

  return (
    <>
      <VTText>{t('tickets.createSuccess')}</VTText>
      <CodeText>{formattedCode}</CodeText>
      <VTText paragraph>
        {t('tickets.createSuccessDescription')}
        {Platform.OS === 'web' ? t('tickets.printHint') : ''}
      </VTText>
      <VTButton
        variant="contained"
        title={t('tickets.showCode')}
        startIcon="qrCode"
        onPress={onShowTicket}
      />
      {shareButton}
    </>
  );
};
