import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import useUser from '../../../hooks/useUser';
import UsersFunctionsService from '../../../services/UsersFunctionsService';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import ProgressBar from '../../UI/ProgressBar';
import DependentsInput, { DependentsFormData } from './DependentsInput';
import HipaaDisclaimer from './HipaaDisclaimer';
import PersonalDataInput, { PersonalDataFormData } from './PersonalDataInput';
import SuccessPage from './SuccessPage';
import { personalDataFormDataToPersonalData, personalDataToPersonalDataFormData } from './util/helpers';

type Step = 'personalData' | 'dependents' | 'disclaimer' | 'success';

const TOTAL_STEPS = 3;

export default () => {
  const { t } = useTranslation();

  const scrollView = useRef<ScrollView>(null);
  const [step, setStep] = useState<Step>('personalData');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [personalData, setPersonalData] = useState<PersonalDataFormData>();
  const [dependents, setDependents] = useState<DependentsFormData>([]);

  const user = useUser();

  const patient = user?.patient;

  useEffect(() => {
    setPersonalData(personalDataToPersonalDataFormData(patient ?? undefined));

    if (patient?.dependents?.length) {
      setDependents(patient.dependents.map(personalDataToPersonalDataFormData));
    }
  }, [patient]);

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await UsersFunctionsService.instance.usersSetPatientData({
        ...personalDataFormDataToPersonalData(personalData!),
        dependents: dependents.map(personalDataFormDataToPersonalData),
      });

      setStep('success');
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [content, stepNumber] = ((): [React.ReactNode, number | undefined] => {
    switch (step) {
      case 'personalData':
        return [
          !!personalData && (
            <PersonalDataInput
              value={personalData}
              onSubmit={(data) => {
                setPersonalData(data);

                setStep('dependents');
              }}
            />
          ),
          1,
        ];
      case 'dependents': {
        if (!personalData) {
          return [null, 2];
        }

        return [
          <DependentsInput
            patient={personalData}
            value={dependents}
            onChange={setDependents}
            onBack={() => setStep('personalData')}
            onSubmit={(data) => {
              setDependents(data);

              setStep('disclaimer');
            }}
          />,
          2,
        ];
      }
      case 'disclaimer':
        return [
          <HipaaDisclaimer
            onNext={onSubmit}
            onBack={() => setStep('dependents')}
            isSubmitting={isSubmitting}
          />,
          3,
        ];
      case 'success':
        return [
          <SuccessPage onProceed={() => setStep('personalData')} />,
          undefined,
        ];
    }
  })();

  useEffect(() => {
    scrollView.current?.scrollTo({ y: 0, animated: false });
  }, [step]);

  return (
    <DefaultLayout ref={scrollView} scrollable>
      <Card title={t('settings.patientInfo')}>
        {stepNumber && (
          <ProgressBar
            value={stepNumber / TOTAL_STEPS}
            label={t('settings.patientInfoSteps', {
              stepNumber,
              totalSteps: TOTAL_STEPS,
            })}
          />
        )}
        {content}
      </Card>
    </DefaultLayout>
  );
};
