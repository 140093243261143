import * as React from 'react';
import { PropsWithChildren } from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
} from 'react-native';
import theme from '../../util/theme';

interface Props extends TextProps {
  variant?: keyof typeof theme.typography;
  paragraph?: boolean;
}

export default ({
  variant = 'body1',
  paragraph,
  style,
  ...props
}: PropsWithChildren<Props>) => (
  <Text
    {...props}
    style={[
      theme.typography[variant] as StyleProp<TextStyle>,
      paragraph && styles.paragraph,
      style,
    ]}
  />
);

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: theme.spacing(2),
  },
});
