import format from 'date-fns/format';
import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Broadcast } from 'vacctrack';
import { Id } from '../../../services/FirestoreService';
import { openLink } from '../../../util/platform';
import theme from '../../../util/theme';
import VTText from '../../UI/VTText';

interface Props {
  notification: Broadcast & Id;
  read: boolean;
}

export default ({ notification, read }: Props) => {
  const onPress = () => {
    if (notification.url) {
      openLink(notification.url);
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.root, !read && styles.unread]}
    >
      <View style={styles.inner}>
        <VTText variant="listHeading">{notification.title}</VTText>
        <VTText variant="caption" paragraph>
          {format(notification.created, 'P')}
        </VTText>
        <VTText>{notification.body}</VTText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  unread: {
    backgroundColor: theme.palette.primaryLight,
  },
  inner: {
    flex: 1,
    padding: theme.spacing(1),
  },
});
