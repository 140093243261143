import * as React from 'react';
import Svg, { Circle, Path, Polygon, Rect, SvgProps } from 'react-native-svg';

export default function MicroReminder(props: SvgProps) {
  return (
    <Svg viewBox="0 0 348.94 256" {...props}>
      <Polygon points="348.9 256 0 256 0 0 349.3 0 348.9 256" fill="#275873" />
      <Rect x="14.4" y="11.1" width="320.6" height="233.7" fill="#fafafa" />
      <Circle cx="230.7" cy="71" r="7.6" fill="#fafafa" />
      <Path
        d="M127.6,49.8V65.4h-7.8a15.53,15.53,0,0,0-15.6,15.5V190.4A15.62,15.62,0,0,0,119.8,206H229.3a15.56,15.56,0,0,0,15.6-15.6V81.1a15.62,15.62,0,0,0-15.6-15.6h-7.8V49.8H205.9V65.4H143.4V49.8H127.6m-7.8,54.7H229.3v86H119.8V96.7m46.9,15.7v39.1h15.6V112.4H166.7m0,54.7v15.6h15.6V167.1Z"
        fill="#275873"
      />
    </Svg>
  );
}
