import { TFunction } from 'i18next';
import { Platform } from 'react-native';
import { VTTextInputProps } from '../components/UI/VTTextInput';
import { DATE_REGEX } from './constants';

export function dateInputProps(t: TFunction): Partial<VTTextInputProps> {
  return {
    keyboardType: Platform.OS === 'ios' ? 'numbers-and-punctuation' : undefined,
    helperText: t('settings.dateHelperText'),
  };
}

export function stringToDate(value: string) {
  const match = value.match(DATE_REGEX);

  if (!match) {
    return null;
  }

  const [, month, day, year] = match;

  const date = new Date(`${year}-${month}-${day}T12:00:00.000Z`);

  if (Number.isNaN(date.getTime())) {
    return null;
  }

  return date;
}
