import * as React from 'react';
import Svg, { Polygon, Polyline, Rect, SvgProps } from 'react-native-svg';

export default function MicroCertTest(props: SvgProps) {
  return (
    <Svg viewBox="0 0 348.94 256" {...props}>
      <Polygon
        points="348.94 256 0 256 0 0.01 349.3 0.01 348.94 256"
        fill="#057556"
      />
      <Rect x="14.43" y="11.15" width="320.61" height="233.72" fill="#fafafa" />
      <Rect x="29.94" y="224.63" width="118.76" height="6.36" fill="#057556" />
      <Rect x="29.94" y="210.71" width="98.65" height="6.36" fill="#057556" />
      <Rect x="29.94" y="196.8" width="109.78" height="6.36" fill="#057556" />
      <Polygon
        points="0 179.82 109.68 70.02 38.82 0.01 0 0.01 0 179.82"
        fill="#6ddeb2"
      />
      <Polygon
        points="162.81 0.01 135.54 27.16 122.43 13.59 131.64 0.01 162.81 0.01"
        fill="#25b88f"
      />
      <Polygon
        points="68.95 72.15 142.62 0.01 36.01 0.01 51.6 55.85 68.95 72.15"
        fill="#057556"
      />
      <Polygon
        points="47.34 0.01 77.64 30.9 9.18 101.03 0 92.34 0 0.01 47.34 0.01"
        fill="#25b88f"
      />
      <Polygon
        points="348.94 104.57 260.95 192.07 325.76 256 348.94 256 348.94 104.57"
        fill="#25b88f"
      />
      <Polygon
        points="153.78 256 223.98 183.82 297.58 256 153.78 256"
        fill="#25b88f"
      />
      <Polygon
        points="348.94 245.57 289.35 183.82 215.96 256 348.94 256 348.94 245.57"
        fill="#057556"
      />
      <Polyline
        points="222.8 247.01 247.86 234.49 272.92 247.01 272.92 159.31 222.8 159.31"
        fill="#f2b416"
      />
      <Polygon
        points="299.86 99.97 288.33 86.83 289.94 69.43 272.87 65.55 263.93 50.52 247.86 57.42 231.78 50.52 222.85 65.55 205.78 69.38 207.39 86.78 195.85 99.97 207.39 113.11 205.78 130.56 222.85 134.43 231.78 149.47 247.86 142.52 263.93 149.42 272.87 134.38 289.94 130.51 288.33 113.11 299.86 99.97"
        fill="#f2b416"
      />
    </Svg>
  );
}
