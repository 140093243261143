import { useFormikContext } from 'formik';
import * as React from 'react';
import { StyleSheet, Switch, SwitchProps, View } from 'react-native';
import theme from '../../util/theme';
import VTText from './VTText';

interface Props extends SwitchProps {
  label?: React.ReactNode;
  formik?: string;
}

export default ({ formik, label, ...props }: Props) => {
  const ctx = formik
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useFormikContext<{
        [key: string]: boolean;
      }>()
    : undefined;

  return (
    <View style={styles.root}>
      <Switch
        value={ctx ? ctx.values[formik!] : undefined}
        onValueChange={(value) => ctx?.setFieldValue(formik!, value)}
        accessibilityLabel={label}
        {...props}
      />
      {label && (
        <VTText variant="label" style={styles.label}>
          {label}
        </VTText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: theme.spacing(2),
  },
});
