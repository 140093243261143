import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TICKET_PARAM_NAME } from '../../../util/constants';
import BarcodeScanView from '../../UI/BarcodeScanView';
import { CodeInputProps } from './CertificatesGetNewScreen';
import { CODE_REGEX } from './CodeInputManual';

export default ({ onResult, ...props }: CodeInputProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const _onResult = (res: string) => {
    if (!res.match(CODE_REGEX)) {
      setError(true);
      return;
    }

    onResult(res);
  };

  return (
    <BarcodeScanView
      paramName={TICKET_PARAM_NAME}
      helperText={
        props.loading
          ? t('certificates.createPending')
          : error
          ? t('certificates.error', { context: 'invalid-argument' })
          : t('certificates.scanHelp')
      }
      onResult={_onResult}
      {...props}
    />
  );
};
