import { Product } from 'vacctrack';
import FirestoreService, { unwrapQuerySnapshot } from './FirestoreService';

export default class ProductsService extends FirestoreService<Product> {
  static instance = new ProductsService();

  constructor() {
    super('products');
  }

  async getProducts() {
    return unwrapQuerySnapshot(await this.collection().get());
  }
}
