import { Picker } from '@react-native-community/picker';
import { FormikProvider, useFormik } from 'formik';
import truncate from 'lodash/truncate';
import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TextInput } from 'react-native';
import { InsuranceInfo, PersonalData } from 'vacctrack';
import { lazy, number, object, SchemaOf, string } from 'yup';
import { ETHNICITY_DATA, GENDER_DATA, RACE_DATA, STATE_ABBR } from '../../../util/constants';
import { dateInputProps } from '../../../util/date';
import dialog from '../../../util/dialog';
import { StepValueProps } from '../../Navigation/Navigation';
import VTButton from '../../UI/VTButton';
import VTPicker from '../../UI/VTPicker';
import VTSwitch from '../../UI/VTSwitch';
import VTText from '../../UI/VTText';
import VTTextInput from '../../UI/VTTextInput';
import Spacer from './Spacer';

interface Insured {
  ins_addressLine1: InsuranceInfo['addressLine1'];
  ins_addressLine2: InsuranceInfo['addressLine2'];
  ins_city: InsuranceInfo['city'];
  ins_county: InsuranceInfo['county'];
  ins_dateOfBirthString: string;
  ins_firstName: InsuranceInfo['firstName'];
  ins_lastName: InsuranceInfo['lastName'];
  ins_middleName: InsuranceInfo['middleName'];
  ins_phone: InsuranceInfo['phone'];
  ins_state: InsuranceInfo['state'];
  ins_zip: InsuranceInfo['zip'];
}

interface PersonalFormData extends Omit<PersonalData, 'insured'> {
  dateOfBirthString: string;
  insuredIsDifferent: boolean;
}

export type PersonalDataFormData = PersonalFormData & Insured;

type PickerData = {
  iEthnicity: number;
  iGender: number;
  iRace: number;
  iState: number;
};

type InsPickerData = {
  ins_iState: number;
};

type FormData = PersonalDataFormData & PickerData & InsPickerData;

type SchemaInsured = Omit<
  Insured,
  'ins_state' | 'ins_addressLine2' | 'ins_middleName'
>;

type SchemaPersonalData = Omit<
  PersonalFormData,
  | 'addressLine2'
  | 'dateOfBirth'
  | 'county'
  | 'ethnicity'
  | 'gender'
  | 'groupPolicyNumber'
  | 'insuredIsDifferent'
  | 'middleName'
  | 'race'
  | 'state'
>;

interface Props extends StepValueProps<PersonalDataFormData> {
  dependentIntent?: 'add' | 'edit';
  onDelete?: () => void;
}

const MAX_CHARS = Platform.select({
  ios: 24,
  android: 40,
  default: 60,
});

const ZIP_REGEX = /^\d{5}$/;

export default ({
  dependentIntent,
  value,
  onSubmit,
  onBack,
  onDelete,
}: Props) => {
  const { t } = useTranslation();

  const refs = [
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
    useRef<TextInput>(null),
  ];

  const _onSubmit = ({
    iGender,
    iRace,
    iEthnicity,
    iState,
    ins_iState,
    ...values
  }: FormData) =>
    onSubmit({
      ...values,
      ins_state: STATE_ABBR[ins_iState],
      state: STATE_ABBR[iState],
      gender: GENDER_DATA[iGender],
      race: RACE_DATA[iRace],
      ethnicity: ETHNICITY_DATA[iEthnicity],
    });

  const ctx = useFormik({
    initialValues: {
      ...value,
      ins_iState: STATE_ABBR.findIndex((o) => o === value.ins_state),
      iEthnicity: ETHNICITY_DATA.findIndex((el) => el === value.ethnicity),
      iGender: GENDER_DATA.findIndex((el) => el === value.gender),
      iRace: RACE_DATA.findIndex((el) => el === value.race),
      iState: STATE_ABBR.findIndex((o) => o === value.state),
    },
    validationSchema: () => schema,
    onSubmit: _onSubmit,
  });

  const schema = useMemo(() => {
    return lazy((val: PersonalDataFormData) => {
      const personalDataSpec = {
        addressLine1: string().required(),
        city: string().required(),
        dateOfBirthString: string().dateOfBirthString().required(),
        firstName: string().required(),
        insuranceProvider: string().required(),
        lastName: string().required(),
        phone: string().required(),
        iEthnicity: number().min(0, 'required').required(),
        iGender: number().min(0, 'required').required(),
        iRace: number().min(0, 'required').required(),
        iState: number().min(0, 'required').required(),
        zip: string().matches(ZIP_REGEX).required(),
      };

      if (val.insuredIsDifferent) {
        return ((): SchemaOf<SchemaPersonalData & PickerData & SchemaInsured> =>
          object({
            ...personalDataSpec,

            ins_addressLine1: string().required(),
            ins_city: string().required(),
            ins_county: string().required(),
            ins_dateOfBirthString: string().dateOfBirthString().required(),
            ins_firstName: string().required(),
            ins_lastName: string().required(),
            ins_phone: string().required(),
            ins_iState: number().min(0, 'required').required(),
            ins_zip: string().matches(ZIP_REGEX).required(),
          }))();
      }

      return ((): SchemaOf<SchemaPersonalData & PickerData> =>
        object(personalDataSpec))();
    });
  }, []);

  const { values, setFieldValue, isSubmitting, isValid } = ctx;

  useEffect(() => {
    if (isSubmitting && !isValid) {
      dialog.alert(t('error.requiredAll'));
    }
  }, [isValid, isSubmitting, t]);

  return (
    <FormikProvider value={ctx}>
      <VTText paragraph>
        {dependentIntent
          ? t('settings.dependentsEditInfo')
          : t('settings.patientInfoDescription')}
      </VTText>
      <VTPicker formik="iGender" label={t('issues.selectGender')} hasEmptyItem>
        <Picker.Item label="---" value={-1} />
        {GENDER_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={t('issues.gender', { context: name })}
            value={i}
          />
        ))}
      </VTPicker>
      <VTTextInput
        formik="firstName"
        nextField={refs[0]}
        label={t('settings.patientFirstName')}
        autoCompleteType="name"
        autoCapitalize="words"
      />
      <VTTextInput
        formik="middleName"
        ref={refs[0]}
        nextField={refs[1]}
        label={t('settings.patientMiddleName')}
        autoCompleteType="name"
        autoCapitalize="words"
      />
      <VTTextInput
        formik="lastName"
        ref={refs[1]}
        nextField={refs[2]}
        label={t('settings.patientLastName')}
        autoCompleteType="name"
        autoCapitalize="words"
      />
      <Spacer />
      <VTTextInput
        formik="dateOfBirthString"
        ref={refs[2]}
        nextField={refs[3]}
        label={t('settings.patientDateOfBirth')}
        placeholder={t('settings.patientDateOfBirthPlaceholder')}
        {...dateInputProps(t)}
      />
      <VTTextInput
        formik="phone"
        ref={refs[3]}
        nextField={refs[4]}
        autoCompleteType="tel"
        keyboardType="phone-pad"
        label={t('settings.patientPhone')}
        placeholder={t('settings.patientPhonePlaceholder')}
      />
      <Spacer />
      <VTTextInput
        formik="addressLine1"
        ref={refs[4]}
        nextField={refs[5]}
        label={t('settings.addressLine1')}
      />
      <VTTextInput
        formik="addressLine2"
        ref={refs[5]}
        nextField={refs[6]}
        label={t('settings.addressLine2')}
      />
      <VTTextInput
        formik="zip"
        ref={refs[6]}
        nextField={refs[7]}
        keyboardType="decimal-pad"
        label={t('settings.patientZip')}
        placeholder={t('settings.patientZipPlaceholder')}
      />
      <VTTextInput
        formik="city"
        ref={refs[7]}
        nextField={refs[8]}
        label={t('settings.patientCity')}
        placeholder={t('settings.patientCityPlaceholder')}
        autoCapitalize="words"
      />
      <VTTextInput
        formik="county"
        ref={refs[8]}
        label={t('settings.county')}
        autoCapitalize="words"
        nextField
      />
      <VTPicker formik="iState" label={t('settings.patientState')} hasEmptyItem>
        <Picker.Item label="---" value={-1} />
        {STATE_ABBR.map((o, i) => (
          <Picker.Item key={o} label={o} value={i} />
        ))}
      </VTPicker>
      <Spacer />
      <VTTextInput
        formik="insuranceProvider"
        ref={refs[9]}
        nextField={refs[10]}
        label={t('settings.insuranceProvider')}
      />
      <VTTextInput
        formik="groupPolicyNumber"
        ref={refs[10]}
        nextField
        label={t('settings.groupPolicyNumber')}
      />
      <Spacer />
      <VTText paragraph>{t('settings.statisticsInfo')}</VTText>
      <VTPicker formik="iRace" label={t('issues.selectRace')} hasEmptyItem>
        <Picker.Item label="---" value={-1} />
        {RACE_DATA.map((name, i) => (
          <Picker.Item
            key={i}
            label={truncate(name, {
              length: MAX_CHARS,
            })}
            value={i}
          />
        ))}
      </VTPicker>
      <VTPicker
        formik="iEthnicity"
        label={truncate(t('issues.selectEthnicity'), {
          length: MAX_CHARS,
        })}
        hasEmptyItem
      >
        <Picker.Item label="---" value={-1} />
        {ETHNICITY_DATA.map((name, i) => (
          <Picker.Item key={i} label={name} value={i} />
        ))}
      </VTPicker>
      <Spacer />
      <VTSwitch
        label={t('settings.insuredIsDifferent')}
        value={values.insuredIsDifferent}
        onValueChange={(v) => {
          if (v) {
            if (values.zip && !values.ins_zip) {
              setFieldValue('ins_zip', values.zip);
            }

            if (values.city && !values.ins_city) {
              setFieldValue('ins_city', values.city);
            }

            if (values.county && !values.ins_county) {
              setFieldValue('ins_county', values.county);
            }

            if (values.iState && values.ins_iState === -1) {
              setFieldValue('ins_iState', values.iState);
            }
          }

          setFieldValue('insuredIsDifferent', v);
        }}
      />
      {values.insuredIsDifferent && (
        <>
          <VTTextInput
            formik="ins_firstName"
            nextField={refs[11]}
            label={`${t('settings.patientFirstName')} (${t(
              'settings.insured',
            )})`}
            autoCompleteType="name"
            autoCapitalize="words"
          />
          <VTTextInput
            formik="ins_middleName"
            ref={refs[11]}
            nextField={refs[12]}
            label={`${t('settings.patientMiddleName')} (${t(
              'settings.insured',
            )})`}
            autoCompleteType="name"
            autoCapitalize="words"
          />
          <VTTextInput
            formik="ins_lastName"
            ref={refs[12]}
            nextField={refs[13]}
            label={`${t('settings.patientLastName')} (${t(
              'settings.insured',
            )})`}
            autoCompleteType="name"
            autoCapitalize="words"
          />
          <Spacer />
          <VTTextInput
            formik="ins_dateOfBirthString"
            ref={refs[13]}
            nextField={refs[14]}
            label={`${t('settings.patientDateOfBirth')} (${t(
              'settings.insured',
            )})`}
            placeholder={t('settings.patientDateOfBirthPlaceholder')}
            {...dateInputProps(t)}
          />
          <VTTextInput
            formik="ins_phone"
            ref={refs[14]}
            nextField={refs[15]}
            autoCompleteType="tel"
            keyboardType="phone-pad"
            label={`${t('settings.patientPhone')} (${t('settings.insured')})`}
            placeholder={t('settings.patientPhonePlaceholder')}
          />
          <Spacer />
          <VTTextInput
            formik="ins_addressLine1"
            ref={refs[15]}
            nextField={refs[16]}
            label={`${t('settings.addressLine1')} (${t('settings.insured')})`}
          />
          <VTTextInput
            formik="ins_addressLine2"
            ref={refs[16]}
            nextField={refs[17]}
            label={`${t('settings.addressLine2')} (${t('settings.insured')})`}
          />
          <VTTextInput
            formik="ins_zip"
            ref={refs[17]}
            nextField={refs[18]}
            keyboardType="decimal-pad"
            label={`${t('settings.patientZip')} (${t('settings.insured')})`}
            placeholder={t('settings.patientZipPlaceholder')}
          />
          <VTTextInput
            formik="ins_city"
            ref={refs[18]}
            nextField={refs[19]}
            label={`${t('settings.patientCity')} (${t('settings.insured')})`}
            placeholder={t('settings.patientCityPlaceholder')}
            autoCapitalize="words"
          />
          <VTTextInput
            formik="ins_county"
            ref={refs[19]}
            label={`${t('settings.county')} (${t('settings.insured')})`}
            autoCapitalize="words"
            nextField
          />
          <VTPicker
            formik="ins_iState"
            label={`${t('settings.patientState')} (${t('settings.insured')})`}
            hasEmptyItem
          >
            <Picker.Item label="---" value={-1} />
            {STATE_ABBR.map((o, i) => (
              <Picker.Item key={o} label={o} value={i} />
            ))}
          </VTPicker>
        </>
      )}

      <VTButton
        formik="submit"
        variant="contained"
        title={(() => {
          switch (dependentIntent) {
            case 'add':
              return t('action.add');
            case 'edit':
              return t('action.confirm');
            default:
              return t('action.next');
          }
        })()}
        margin
      />

      {dependentIntent && (
        <>
          <VTButton title={t('action.back')} onPress={onBack} />
          {dependentIntent === 'edit' && (
            <VTButton
              title={t('settings.dependentsDelete')}
              onPress={onDelete!}
            />
          )}
        </>
      )}
    </FormikProvider>
  );
};
