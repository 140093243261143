import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { IssueEffect } from 'vacctrack';
import theme from '../../../util/theme';
import { StepValueProps } from '../../Navigation/Navigation';
import SelectableListItem from '../../UI/SelectableListItem';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import VTTextInput from '../../UI/VTTextInput';

export interface EffectData {
  effects: IssueEffect[];
  otherEffect: string;
}

const EFFECT_OTHER = 'other' as const;

const EFFECTS: IssueEffect[] = [
  'chills',
  'difficulty-breathing',
  'dizziness-and-weakness',
  'fast-heartbeat',
  'feeling-unwell',
  'fever',
  'headache',
  'injection-site-pain',
  'injection-site-redness',
  'injection-site-swelling',
  'joint-pain',
  'muscle-pain',
  'nausea',
  'rash-all-over-body',
  'swelling-of-face-or-throat',
  'swollen-lymph-nodes',
  'tiredness',
  EFFECT_OTHER,
];

export default ({ value, onSubmit, onBack }: StepValueProps<EffectData>) => {
  const { t } = useTranslation();
  const [effects, setEffects] = useState(value.effects);
  const [otherEffect, setOtherEffect] = useState(value.otherEffect);

  const _onSubmit = () => {
    onSubmit({
      effects,
      otherEffect,
    });
  };

  const otherSelected = effects.some((si) => si === EFFECT_OTHER);

  const isValid = effects.length && (!otherSelected || otherEffect.trim());

  return (
    <>
      <VTText paragraph>{t('issues.effectIntro')}</VTText>
      <View style={styles.list}>
        {EFFECTS.map((it, i) => {
          const value = effects.some((sel) => sel === it);

          return (
            <SelectableListItem
              key={it}
              text={t('issues.effect', { context: it })}
              value={value}
              onPress={() => {
                if (!value && it === EFFECT_OTHER) {
                  setOtherEffect('');
                }

                setEffects((si) =>
                  value ? si.filter((s) => s !== it) : [...si, it],
                );
              }}
              divider={!!i}
            />
          );
        })}
      </View>
      {otherSelected && (
        <VTTextInput
          label={t('issues.inputOther')}
          value={otherEffect}
          onChangeText={setOtherEffect}
        />
      )}
      <VTButton
        variant="contained"
        title={t('action.next')}
        onPress={_onSubmit}
        disabled={!isValid}
      />
      {onBack && <VTButton title={t('action.back')} onPress={onBack} />}
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    marginBottom: theme.spacing(2),
  },
});
