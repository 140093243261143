import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Share, ShareContent, ShareOptions } from 'react-native';
import { Certificate } from 'vacctrack';
import CertificatesService from '../../../services/CertificatesService';
import { Id } from '../../../services/FirestoreService';
import dialog from '../../../util/dialog';
import { openLink } from '../../../util/platform';
import Divider from '../../UI/Divider';
import VTButton from '../../UI/VTButton';
import { Mode } from './CertificatesShareScreen';

interface Props {
  certificate?: Certificate & Id;
  pdfUrl?: string;
  value: string;
  onSelect: (mode: Mode) => void;
}

export default ({ certificate, pdfUrl, value, onSelect }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const canShareVerifyLink = Platform.OS !== 'web' || window.navigator.share;

  const onShareWithLink = async () => {
    const title = t('certificates.shareMessage');

    const content: ShareContent = {
      message: value,
      url: value,
      title,
    };

    const options: ShareOptions = {
      subject: title,
      dialogTitle: t('certificates.shareTitle'),
    };

    await Share.share(content, options);
  };

  const downloadFileToCacheIfNotExists = async () => {
    if (!certificate || !pdfUrl) {
      throw Error('no data');
    }

    const defaultReleaseCode = CertificatesService.instance.getDefaultReleaseCode(
      certificate,
    );

    const [, fileNameOrig] = CertificatesService.instance
      .getPdfFileName(defaultReleaseCode)
      .split('/');

    const fileName = `Certificate-${fileNameOrig.substr(0, 4)}.pdf`;

    const absoluteFileName = `${FileSystem.cacheDirectory}${fileName}`;

    const dirInfo = await FileSystem.getInfoAsync(absoluteFileName);

    if (dirInfo.exists) {
      return absoluteFileName;
    }

    // we only want to have loading for download, not the above operations
    setLoading(true);

    try {
      await FileSystem.downloadAsync(pdfUrl, absoluteFileName);

      return absoluteFileName;
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onSendFile = async () => {
    try {
      const url = await downloadFileToCacheIfNotExists();

      await Sharing.shareAsync(url, {
        mimeType: 'application/pdf',
        dialogTitle: t('certificates.share', { context: 'sendFile' }),
        UTI: 'com.adobe.pdf',
      });
    } catch (e) {
      // noinspection ES6MissingAwait
      dialog.alert(t('certificates.shareError'));
    }
  };

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);

      // noinspection ES6MissingAwait
      dialog.alert(t('certificates.copiedToClipboard'));
    } catch (e) {}
  };

  const onDownload = () => {
    openLink(pdfUrl!);
  };

  return (
    <>
      <VTButton
        title={t('certificates.share', { context: 'qr' })}
        onPress={() => onSelect('qr')}
        startIcon="qrCode"
        margin
      />
      <Divider />
      {canShareVerifyLink ? (
        <VTButton
          title={t('certificates.share', { context: 'link' })}
          onPress={onShareWithLink}
          startIcon="link"
          margin
        />
      ) : (
        <VTButton
          title={t('certificates.share', { context: 'clipboardCopy' })}
          onPress={onCopyToClipboard}
          startIcon="link"
          margin
        />
      )}
      {Platform.OS !== 'web' && (
        <>
          <Divider />
          <VTButton
            title={t('certificates.share', { context: 'sendFile' })}
            onPress={onSendFile}
            startIcon="sendFile"
            disabled={!pdfUrl || loading}
            margin
          />
        </>
      )}
      <>
        <Divider />
        <VTButton
          title={t('certificates.share', { context: 'download' })}
          onPress={onDownload}
          disabled={!pdfUrl}
          startIcon="download"
          margin
        />
      </>
    </>
  );
};
