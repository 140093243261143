import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import useDisplayFlashMessage from '../../hooks/useDisplayFlashMessage';
import MspFunctionsService from '../../services/MspFunctionsService';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import VTTextInput from '../UI/VTTextInput';

interface Props {
  onCancel: () => void;
}

interface FormData {
  code: string;
}

const CODE_REGEX = /^\d{6}$/;
const CODE_INPUT_REGEX = /^\d{0,6}$/;

const SCHEMA = (): SchemaOf<FormData> => object({
  code: string()
    .matches(CODE_REGEX, () => ({
      key: 'mspRegister.codeInputHelp',
    }))
    .required(),
});

export default ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const displayFlashMessage = useDisplayFlashMessage();

  const onSubmit = async (
    { code }: FormData,
    { setFieldError, setSubmitting }: FormikHelpers<FormData>,
  ) => {
    try {
      await MspFunctionsService.instance.mspConfirmVerificationCode(code);

      displayFlashMessage(t('mspRegister.successMessage'), 'TicketsScreen');
    } catch (e) {
      setFieldError('code', 'invalid');
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <>
          <VTText variant="heading1">
            {t('mspRegister.enterVerificationCode')}
          </VTText>
          <VTText paragraph>{t('mspRegister.codeInputInfo')}</VTText>
          <VTTextInput
            formik="code"
            placeholder="XXXXXX"
            onChangeText={(value) => {
              if (value.match(CODE_INPUT_REGEX)) {
                setFieldValue('code', value);
              }
            }}
            keyboardType="numeric"
            autoFocus
          />
          <VTButton
            formik="submit"
            variant="contained"
            title={t('certificates.submitCode')}
          />
          <VTButton
            formik="button"
            title={t('mspRegister.requestAnotherCall')}
            onPress={onCancel}
          />
        </>
      )}
    </Formik>
  );
};
