import { Camera } from 'expo-camera';
import noop from 'lodash/noop';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { UseCameraValue } from '../index';

export default function useCamera(): UseCameraValue {
  const [cameraType, setCameraType] = useState<string | undefined>(
    Platform.OS === 'web' ? undefined : 'back',
  );
  const [hasTwoCameras, setHasTwoCameras] = useState(Platform.OS !== 'web');

  useEffect(() => {
    Camera.getAvailableCameraTypesAsync().then((resp) => {
      setCameraType(resp[0]);
      setHasTwoCameras(resp.length > 1);
    });
  }, []);

  const toggleCameraType = useCallback(() => {
    setCameraType((t) => (t === 'front' ? 'back' : 'front'));
  }, []);

  return {
    askPermission: noop,
    permission: {
      status: 'granted',
      canAskAgain: false,
    },
    cameraType,
    hasTwoCameras,
    toggleCameraType,
  };
}
