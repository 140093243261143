import * as React from 'react';
import Svg, { Path, Polygon, Rect, SvgProps } from 'react-native-svg';

export default function MicroCertValid(props: SvgProps) {
  return (
    <Svg viewBox="0 0 348.94 256" {...props}>
      <Polygon
        points="348.94 256 0 256 0 0.01 349.3 0.01 348.94 256"
        fill="#275873"
      />
      <Rect x="14.43" y="11.15" width="320.61" height="233.72" fill="#fafafa" />
      <Rect x="29.94" y="224.63" width="118.76" height="6.36" fill="#394d79" />
      <Rect x="29.94" y="210.71" width="98.65" height="6.36" fill="#394d79" />
      <Rect x="29.94" y="196.8" width="109.78" height="6.36" fill="#394d79" />
      <Polygon
        points="0 179.82 109.68 70.02 38.82 0.01 0 0.01 0 179.82"
        fill="#8dcddd"
      />
      <Polygon
        points="162.81 0.01 135.54 27.16 122.43 13.59 131.64 0.01 162.81 0.01"
        fill="#4b91b4"
      />
      <Polygon
        points="68.95 72.15 142.62 0.01 36.01 0.01 51.6 55.85 68.95 72.15"
        fill="#275873"
      />
      <Polygon
        points="47.34 0.01 77.64 30.9 9.18 101.03 0 92.34 0 0.01 47.34 0.01"
        fill="#4b91b4"
      />
      <Polygon
        points="348.94 104.57 260.95 192.07 325.76 256 348.94 256 348.94 104.57"
        fill="#5b96cc"
      />
      <Polygon
        points="153.78 256 223.98 183.82 297.58 256 153.78 256"
        fill="#5b96cc"
      />
      <Polygon
        points="348.94 245.57 289.35 183.82 215.96 256 348.94 256 348.94 245.57"
        fill="#394d79"
      />
      <Path
        d="M275.64,128,253.2,102.39l3.13-33.85L223.12,61,205.74,31.75,174.47,45.18,143.2,31.75,125.82,61,92.61,68.45l3.13,33.84L73.3,128l22.44,25.57-3.13,33.94L125.82,195l17.38,29.25,31.27-13.52,31.27,13.43,17.38-29.25,33.21-7.54-3.13-33.85L275.64,128m-119.57,46-36.78-36.79,13-13,23.82,23.73L216.69,87.3l13,13.06Z"
        fill="#f2b416"
      />
    </Svg>
  );
}
