import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CloseButton from '../Main/CloseButton';
import { navigationHeaderOptions } from '../Main/shared';
import CreateTicketScreen from './CreateTicketScreen';
import TicketsScreen from './TicketsScreen';

export type TicketsNavigatorParamList = {
  TicketsScreen: undefined;
  CreateTicketScreen: undefined;
};

export type TicketsNavigationProp = StackNavigationProp<TicketsNavigatorParamList>;

const Stack = createStackNavigator<TicketsNavigatorParamList>();

export default () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator initialRouteName="TicketsScreen">
      <Stack.Screen
        name="CreateTicketScreen"
        component={CreateTicketScreen}
        options={{
          title: t('tickets.confirmationCode'),
          ...navigationHeaderOptions(),
          headerLeft: () => <CloseButton popToTopFallback="TicketsScreen" />,
        }}
      />
      <Stack.Screen
        name="TicketsScreen"
        component={TicketsScreen}
        options={{
          title: t('tickets.title'),
          ...navigationHeaderOptions(true),
        }}
      />
    </Stack.Navigator>
  );
};
