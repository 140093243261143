import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User as FirebaseUser } from 'firebase/app';
import { Broadcast, User } from 'vacctrack';
import { MainNavigatorParamList } from '../components/Main/MainNavigator';
import { Id } from '../services/FirestoreService';
import { STORAGE_MAIN_SCREEN } from '../util/constants';

export type AuthUser = Pick<
  FirebaseUser,
  | 'uid'
  | 'email'
  | 'phoneNumber'
  | 'displayName'
  | 'emailVerified'
  | 'providerData'
> & {
  createdAt: string;
  lastLoginAt: string;
};

interface FlashMessage {
  message: string;
  routeName: string;
}

interface State {
  authUser?: AuthUser | null;
  broadcasts: (Broadcast & Id)[];
  flashMessage?: FlashMessage;
  mainScreen?: keyof MainNavigatorParamList | null;
  notificationPath?: string;
  user?: User | null;
  // this is to prevent a flash of indeterminate data after sign up
  userTypeOverride?: User['type'];
}

const INITIAL_STATE: State = {
  broadcasts: [],
};

const loadMainScreenForUser = createAsyncThunk<State['mainScreen'], string>(
  'root/loadMainScreenForUser',
  (uid) =>
    AsyncStorage.getItem(STORAGE_MAIN_SCREEN + uid) as Promise<
      State['mainScreen']
    >,
);

const slice = createSlice({
  name: 'root',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE,
      user: null,
      authUser: null,
      mainScreen: null,
    }),
    setAuthUser: (state, action: PayloadAction<State['authUser']>) => {
      state.authUser = action.payload;

      if (action.payload === null) {
        // if authUser is null, we don't attempt to load the user
        state.user = null;
      } else {
        // if authUser is loaded, loading the user will begin
        state.user = undefined;
      }
    },
    setBroadcasts: (state, action: PayloadAction<State['broadcasts']>) => {
      state.broadcasts = action.payload;
    },
    setFlashMessage: (state, action: PayloadAction<State['flashMessage']>) => {
      state.flashMessage = action.payload;
    },
    setMainScreen: (
      state,
      action: PayloadAction<NonNullable<State['mainScreen']>>,
    ) => {
      void AsyncStorage.setItem(STORAGE_MAIN_SCREEN, action.payload!);

      state.mainScreen = action.payload;
    },
    setNotificationPath: (
      state,
      action: PayloadAction<State['notificationPath']>,
    ) => {
      state.notificationPath = action.payload;
    },
    setUserTypeOverride: (
      state,
      action: PayloadAction<State['userTypeOverride']>,
    ) => {
      state.userTypeOverride = action.payload;
    },
    setUser: (state, action: PayloadAction<State['user']>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMainScreenForUser.fulfilled, (state, action) => {
      state.mainScreen = action.payload;
    });
    builder.addCase(loadMainScreenForUser.rejected, (state) => {
      state.mainScreen = null;
    });
  },
});

export const actions = {
  loadMainScreenForUser,
  ...slice.actions,
};

export default slice.reducer;
