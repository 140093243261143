import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Alert, Linking, Platform, ScrollView } from 'react-native';
import useAuthUser from '../../../hooks/useAuthUser';
import usePersistentScreen from '../../../hooks/usePersistentScreen';
import useUserSpecificStorage from '../../../hooks/useUserSpecificStorage';
import UsersFunctionsService from '../../../services/UsersFunctionsService';
import { useAppSelector } from '../../../store';
import { STORAGE_PUSH_PROMPT_HIDDEN } from '../../../util/constants';
import LoginPrompt from '../../Main/LoginPrompt';
import Card from '../../UI/Card';
import DefaultLayout from '../../UI/DefaultLayout';
import CertificatesListCard from './CertificatesListCard';
import HipaaPrompt from './HipaaPrompt';
import PushPromptCard from './PushPromptCard';

export default () => {
  const authUser = useAuthUser();
  const [hasPermission, setHasPermission] = useState<boolean>();
  const userSpecificStorage = useUserSpecificStorage();
  const scrollView = useRef<ScrollView>(null);
  const loading = useAppSelector(
    ({ certificates: { certificates, reports } }) => !certificates || !reports,
  );

  useEffect(() => {
    (async () => {
      setHasPermission(
        (await Notifications.getPermissionsAsync()).status === 'granted',
      );
    })();
  }, []);

  const [showPushPrompt, setShowPushPrompt] = useState<boolean | undefined>(
    Platform.OS === 'web' ? false : undefined,
  );

  const [pushPending, setPushPending] = useState(false);

  useEffect(() => {
    async function checkPushPromptHidden() {
      setShowPushPrompt(
        !hasPermission &&
          !(await userSpecificStorage.getItem(STORAGE_PUSH_PROMPT_HIDDEN)),
      );
    }

    if (Platform.OS === 'ios' && Constants.isDevice) {
      void checkPushPromptHidden();
    }
  }, [hasPermission, userSpecificStorage]);

  usePersistentScreen('CertificatesNavigator');

  const agreedToTerms = useAppSelector(
    ({ root: { user } }) => user?.agreedToTerms,
  );

  const onPushDecide = async (value: boolean) => {
    async function setPushPromptHidden() {
      // noinspection ES6MissingAwait
      userSpecificStorage.setItem(STORAGE_PUSH_PROMPT_HIDDEN, true.toString());
      setShowPushPrompt(false);
    }

    if (value) {
      setPushPending(true);

      try {
        const result = await Notifications.requestPermissionsAsync();

        if (result.ios?.allowsAlert) {
          const { data } = await Notifications.getExpoPushTokenAsync();
          // noinspection ES6MissingAwait
          UsersFunctionsService.instance.usersPushTokenAdd(data);
        } else {
          // noinspection ES6MissingAwait
          Linking.openSettings();
          return;
        }

        // noinspection ES6MissingAwait
        setPushPromptHidden();
      } catch (e) {
        Alert.alert('error');
      } finally {
        setPushPending(false);
      }
    } else {
      // noinspection ES6MissingAwait
      setPushPromptHidden();
    }
  };

  const content = (() => {
    if (!authUser) {
      return (
        <Card margin>
          <LoginPrompt />
        </Card>
      );
    }

    if (!agreedToTerms) {
      return (
        <Card margin>
          <HipaaPrompt />
        </Card>
      );
    }

    if (loading) {
      return null;
    }

    return (
      <>
        {showPushPrompt && (
          <PushPromptCard onDecide={onPushDecide} pending={pushPending} />
        )}
        <CertificatesListCard />
      </>
    );
  })();

  return (
    <DefaultLayout ref={scrollView} scrollable>
      {content}
    </DefaultLayout>
  );
};
