import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { useDispatch } from 'react-redux';
import { object, SchemaOf, string } from 'yup';
import UsersFunctionsService from '../../services/UsersFunctionsService';
import { actions as rootActions } from '../../store/root.slice';
import config from '../../util/config';
import { STORAGE_STORED_TICKET_PARAM } from '../../util/constants';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';
import VTTextInput, { COMMON_TEXT_INPUT_PROPS } from '../UI/VTTextInput';
import { LoginNavigatorParamList } from './LoginNavigator';
import PasswordField from './PasswordField';

interface LoginFormData extends EmailData {
  password: string;
}

interface SignUpFormData extends EmailData {
  password: string;
}

interface EmailData {
  email: string;
}

type LoginScreenRouteProp = RouteProp<LoginNavigatorParamList, 'LoginScreen'>;

export type LoginScreenMode = 'login' | 'signUp' | 'forgotPassword';

const loginSchema = (): SchemaOf<LoginFormData> =>
  object({
    email: string().email().required(),
    password: string().required(),
  });

const signUpSchema = (): SchemaOf<SignUpFormData> => object({
  email: string().email().required(),
  password: string().newPassword().required(),
});

const forgotSchema = (): SchemaOf<EmailData> => object({
  email: string().email().required(),
});

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation<RootNavigationProp>();
  const route = useRoute<LoginScreenRouteProp>();

  const passwordField = useRef<TextInput>(null);
  const [mode, setMode] = useState<LoginScreenMode>(
    route.params?.mode ?? 'login',
  );
  const type = route.params?.type;
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  const schema = (() => {
    switch (mode) {
      case 'login':
        return loginSchema;
      case 'signUp':
        return signUpSchema;
      case 'forgotPassword':
        return forgotSchema;
    }
  })();

  const title = (() => {
    switch (mode) {
      case 'login':
        return t('login.title');
      case 'signUp':
        return t('login.signUp');
      case 'forgotPassword':
        return t('login.forgotPassword');
    }
  })();

  const onSubmit = async (
    { email, password }: SignUpFormData,
    { setFieldError, setSubmitting }: FormikHelpers<SignUpFormData>,
  ) => {
    async function proceed() {
      if (await AsyncStorage.getItem(STORAGE_STORED_TICKET_PARAM)) {
        dispatch(rootActions.setMainScreen('CertificatesNavigator'));
      }

      navigation.navigate('MainNavigator');
    }

    try {
      switch (mode) {
        case 'login':
          await firebase.auth().signInWithEmailAndPassword(email, password);
          // noinspection ES6MissingAwait
          proceed();
          break;
        case 'signUp':
          await firebase.auth().createUserWithEmailAndPassword(email, password);

          await Promise.all([
            UsersFunctionsService.instance.usersCreate({
              type: type ?? 'undetermined',
            }),
            firebase.auth().currentUser?.sendEmailVerification({
              url: config.emailVerificationContinueUrl,
            }),
          ]);

          dispatch(rootActions.setUserTypeOverride(type));

          // noinspection ES6MissingAwait
          proceed();
          break;
        case 'forgotPassword':
          await firebase.auth().sendPasswordResetEmail(email, {
            url: `${config.appUrl}/login`,
          });
          setIsPasswordResetSuccess(true);
          break;
      }
    } catch (e) {
      if (
        mode === 'forgotPassword' ||
        [
          'auth/email-already-in-use',
          'auth/user-not-found',
          'auth/invalid-email',
          'auth/user-disabled',
        ].includes(e.code)
      ) {
        setFieldError('email', e.code);
      } else {
        setFieldError('password', e.code ?? '');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const primaryButtonText = (() => {
    switch (mode) {
      case 'login':
        return t('login.logIn');
      case 'signUp':
        return t('login.signUp', { context: type });
      case 'forgotPassword':
        return t('login.requestResetEmail');
    }
  })();

  const secondaryButtonText = (() => {
    switch (mode) {
      case 'login':
        return t('login.createAccount');
      case 'signUp':
        return t('login.alreadyHaveAccount');
      case 'forgotPassword':
        return t('action.cancel');
    }
  })();

  const secondaryButtonAction = (() => {
    switch (mode) {
      case 'login':
        return () => setMode('signUp');
      case 'signUp':
        return () => setMode('login');
      case 'forgotPassword':
        return () => setMode('login');
    }
  })();

  const content = (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <>
        <VTTextInput
          {...COMMON_TEXT_INPUT_PROPS.email}
          formik="email"
          nextField={mode === 'forgotPassword' ? false : passwordField}
          placeholder={t('login.email')}
          helperText={
            mode === 'forgotPassword' && t('login.passwordResetInstructions')
          }
        />
        {mode !== 'forgotPassword' && (
          <PasswordField
            formik="password"
            ref={passwordField}
            placeholder={t('login.password')}
            helperText={mode === 'signUp' && t('login.passwordHelperText')}
          />
        )}
        <VTButton
          formik="submit"
          variant="contained"
          title={primaryButtonText}
        />
        {(mode !== 'login' || type) && (
          <VTButton
            formik="button"
            title={secondaryButtonText}
            onPress={secondaryButtonAction}
          />
        )}
        {mode === 'login' && (
          <VTButton
            formik="button"
            title={t('login.forgotPassword')}
            size="small"
            onPress={() => {
              setMode('forgotPassword');
            }}
          />
        )}
      </>
    </Formik>
  );

  useEffect(() => {
    navigation.setOptions({
      title,
    });
  }, [title, navigation, t]);

  return (
    <DefaultLayout containerMaxWidth="sm" scrollable>
      <Card title={title}>
        {isPasswordResetSuccess ? (
          <>
            <VTText paragraph>{t('login.passwordResetSuccess')}</VTText>
            <VTButton
              variant="contained"
              title={t('loginPrompt.goToLogin')}
              onPress={() => {
                setIsPasswordResetSuccess(false);
                setMode('login');
              }}
            />
          </>
        ) : (
          content
        )}
      </Card>
    </DefaultLayout>
  );
};
