import { registerRootComponent } from 'expo';
import App from './components/App';
import { initFirebase } from './util/firebase';
import initI18n from './util/i18n';
import { initSchema } from './util/schema';

void initI18n();
initFirebase();
initSchema();

export default registerRootComponent(App);
