import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import theme from '../../util/theme';
import VTIcon, { VTIconProps } from './VTIcon';
import VTText from './VTText';

interface Props {
  iconName: VTIconProps['name'];
  label: string;
  subtitle?: string;
  onPress: () => void;
  disabled?: boolean;
}

export default ({
  iconName,
  label,
  subtitle,
  onPress,
  disabled = false,
}: Props) => (
  <TouchableOpacity
    accessibilityLabel={label}
    style={[styles.root, disabled && styles.disabled]}
    onPress={onPress}
    disabled={disabled}
  >
    <VTIcon
      color={theme.palette.primary}
      name={iconName}
      size={theme.spacing(4)}
    />
    <View style={styles.label}>
      <VTText style={styles.labelText}>{label}</VTText>
      {subtitle && (
        <VTText variant="caption" style={styles.labelSubtitleText}>
          {subtitle}
        </VTText>
      )}
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  disabled: {
    ...theme.action.disabled,
  },
  label: {
    marginLeft: theme.spacing(2),
  },
  labelSubtitleText: {
    color: theme.palette.textSecondary,
  },
  labelText: {
    color: theme.palette.primary,
    fontFamily: theme.typography.fontFamily.semiBold,
  },
});
