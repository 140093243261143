import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportsFunctionsService from '../../services/ReportsFunctionsService';
import {
  INITIAL_DATE_DATA,
  INITIAL_PRODUCT_DATA,
  INITIAL_PRODUCT_SELECTOR_DATA,
} from '../../util/constants';
import { stringToDate } from '../../util/date';
import dialog from '../../util/dialog';
import ProductDataInput, {
  ProductDataInputData,
} from '../Shared/ProductDataInput';
import ProductSelector, {
  ProductSelectorData,
} from '../Shared/ProductSelector';
import ServiceDateInput, { ServiceDateData } from '../Shared/ServiceDateInput';
import Card from '../UI/Card';
import DefaultLayout from '../UI/DefaultLayout';
import VTText from '../UI/VTText';
import { CertificatesNavigationProp } from './CertificatesNavigator';

enum Step {
  DateData,
  ProductSelect,
  ProductData,
}

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();

  const [step, setStep] = useState<Step>(0);
  const [dateData, setDateData] = useState<ServiceDateData>(
    INITIAL_DATE_DATA(),
  );
  const [
    productSelectorData,
    setProductSelectorData,
  ] = useState<ProductSelectorData>(INITIAL_PRODUCT_SELECTOR_DATA);
  const [productData, setProductData] = useState<ProductDataInputData>(
    INITIAL_PRODUCT_DATA,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setDateData(INITIAL_DATE_DATA());
        setStep(0);
        setProductSelectorData(INITIAL_PRODUCT_SELECTOR_DATA);
        setProductData(INITIAL_PRODUCT_DATA);
        setIsSubmitting(false);
      };
    }, []),
  );

  const submit = async (productData: ProductDataInputData) => {
    setProductData(productData);
    setIsSubmitting(true);

    const reminderDate = stringToDate(dateData.reminderDateString!)?.getTime();
    const expiryDate = stringToDate(productData.expiryDateString!)?.getTime();

    try {
      await ReportsFunctionsService.instance.reportsCreate({
        serviceDate: stringToDate(dateData.serviceDateString)!.getTime(),
        productId: productSelectorData.productId!,
        ...(productData.lotNumber && {
          lotNumber: productData.lotNumber,
        }),
        ...(expiryDate && { expiryDate }),
        ...(reminderDate && { reminderDate }),
        shotNumber: productData.shotNumber ?? 1,
        shotNumberOf: productData.shotNumberOf ?? 1,
      });

      await dialog.alert(t('certificates.reportCreateSuccess'));

      navigation.popToTop();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const next = () =>
    setStep((s) => Math.min(s + 1, Object.keys(Step).length - 1));
  const prev = () => setStep((s) => Math.max(0, s - 1));

  const content = (() => {
    switch (step) {
      case Step.DateData:
        return (
          <ServiceDateInput
            description={
              <VTText paragraph>
                {t('certificates.reportMetaDescription')}
              </VTText>
            }
            value={dateData}
            onSubmit={(v) => {
              setDateData(v);
              next();
            }}
          />
        );
      case Step.ProductSelect:
        return (
          <ProductSelector
            value={productSelectorData}
            onSubmit={(d) => {
              setProductSelectorData(d);

              if (d) {
                setProductData((pd) => ({
                  ...pd,
                  shotNumberOf: d.shotNumberOf,
                }));
                next();
              }
            }}
            onBack={prev}
          />
        );
      case Step.ProductData:
        return (
          <ProductDataInput
            submitTitle={t('certificates.selfReportSubmit')}
            value={productData}
            onSubmit={submit}
            onBack={prev}
            isSubmitting={isSubmitting}
          />
        );
    }
  })();

  return (
    <DefaultLayout scrollable>
      <Card title={t('certificates.selfReport')} margin>
        {content}
      </Card>
    </DefaultLayout>
  );
};
