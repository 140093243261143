import { Formik } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'react-native';
import { number, object, SchemaOf, string } from 'yup';
import { REASONABLE_REMINDER_DATE_RANGE } from '../../../util/constants';
import { dateInputProps } from '../../../util/date';
import { StepValueProps } from '../../Navigation/Navigation';
import ShotNumberInput from '../../Shared/ShotNumberInput';
import VTButton from '../../UI/VTButton';
import VTTextInput from '../../UI/VTTextInput';

export interface RemindersDateData {
  shotNumber: number;
  shotNumberOf: number;
  reminderDateString?: string;
}

interface Props extends StepValueProps<RemindersDateData> {
  isSubmitting: boolean;
}

const SCHEMA = (): SchemaOf<RemindersDateData> =>
  object({
    shotNumber: number().min(0).max(20).required(),
    shotNumberOf: number().min(1).max(20).required(),
    reminderDateString: string()
      .dateString(REASONABLE_REMINDER_DATE_RANGE)
      .required(),
  });

export default ({ value, onSubmit, isSubmitting }: Props) => {
  const { t } = useTranslation();
  const reminderDateField = useRef<TextInput>(null);

  return (
    <Formik initialValues={value} validationSchema={SCHEMA} onSubmit={onSubmit}>
      <>
        <ShotNumberInput nextField={reminderDateField} />
        <VTTextInput
          formik="reminderDateString"
          ref={reminderDateField}
          label={t('certificates.reminderDate')}
          placeholder={t('tickets.expiryDatePlaceholder')}
          nextField={false}
          {...dateInputProps(t)}
        />
        <VTButton
          formik="submit"
          variant="contained"
          title={t('certificates.createReminder')}
          pending={isSubmitting}
          margin
        />
      </>
    </Formik>
  );
};
