import { Reminder, ServiceItem } from 'vacctrack';
import FirestoreSubcollectionService from './FirestoreSubcollectionService';

export default class RemindersService extends FirestoreSubcollectionService<Reminder> {
  static instance = new RemindersService();

  static isReminder(item: ServiceItem): item is Reminder {
    return !!(item as Reminder).reminderDate;
  }

  constructor() {
    super('users', 'reminders');
  }
}
