import firebase from 'firebase/app';
import {
  CertificatesCreateRequest,
  CertificatesCreateResponse,
  CertificatesCreateTicketRequest,
  CertificatesCreateTicketResponse,
  CertificatesCreateWithProofRequest,
  CertificatesCreateWithProofResponse,
  CertificatesDeleteRequest,
  CertificatesVerifyWithCodeRequest,
  CertificatesVerifyWithCodeResponse,
} from 'vacctrack';
import FunctionsService from './FunctionsService';

export type FetchResponse<T> = T | FetchResponseError;
export type FetchResponseError = {
  error: string;
};

export function isError<T>(
  response: FetchResponse<T>,
): response is FetchResponseError {
  return !!(response as FetchResponseError).error;
}

export default class CertificatesFunctionsService extends FunctionsService {
  static instance = new CertificatesFunctionsService();

  certificatesCreate(data: CertificatesCreateRequest) {
    return this.call<CertificatesCreateRequest, CertificatesCreateResponse>(
      'certificatesCreate',
      data,
    );
  }

  certificatesCreateTicket(data: CertificatesCreateTicketRequest) {
    return this.call<
      CertificatesCreateTicketRequest,
      CertificatesCreateTicketResponse
    >('certificatesCreateTicket', data);
  }

  certificatesDelete(data: CertificatesDeleteRequest) {
    return this.call<CertificatesDeleteRequest>('certificatesDelete', data);
  }

  // certificatesCreateRelease(data: CertificatesCreateReleaseRequest) {
  //   return this.call<
  //     CertificatesCreateReleaseRequest,
  //     CertificatesCreateReleaseResponse
  //   >('certificatesCreateRelease', data);
  // }

  certificatesVerifyWithCode(data: CertificatesVerifyWithCodeRequest) {
    return this.call<
      CertificatesVerifyWithCodeRequest,
      CertificatesVerifyWithCodeResponse
    >('certificatesVerifyWithCode', data);
  }

  async uploadCertificatePdf(file: File) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = firebase.functions()._url('certificatesVerifyWithUpload');

    const result = await fetch(url, {
      method: 'POST',
      body: file,
      headers: {
        'Content-type': 'application/pdf',
      },
    });

    return result.status === 204;
  }

  async certificatesCreateWithProof(
    data: CertificatesCreateWithProofRequest,
  ): Promise<FetchResponse<CertificatesCreateWithProofResponse>> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = firebase.functions()._url('certificatesCreateWithProof');

    return (
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${await firebase
            .auth()
            .currentUser!.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      })
    ).json();
  }
}
