import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'vacctrack';
import CloseButton from '../Main/CloseButton';
import { navigationHeaderOptions } from '../Main/shared';
import { RootNavigationProp } from '../Navigation/RootNavigator';
import LoginScreen, { LoginScreenMode } from './LoginScreen';

export type LoginNavigatorParamList = {
  LoginScreen: {
    ref?: 'landing';
    mode?: LoginScreenMode;
    type?: UserType;
  };
};

const LoginStack = createStackNavigator<LoginNavigatorParamList>();

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<RootNavigationProp>();

  return (
    <LoginStack.Navigator>
      <LoginStack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{
          title: t('login.title'),
          ...navigationHeaderOptions(),
          headerLeft: () => (
            <CloseButton onPress={() => navigation.navigate('LandingScreen')} />
          ),
        }}
      />
    </LoginStack.Navigator>
  );
};
