import { useNavigation } from '@react-navigation/native';
import format from 'date-fns/format';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Certificate, ServiceItem } from 'vacctrack';
import { Id } from '../../../services/FirestoreService';
import RemindersService from '../../../services/RemindersService';
import ReportsService from '../../../services/ReportsService';
import strings from '../../../util/strings';
import theme from '../../../util/theme';
import MicroCert from '../../UI/SVG/MicroCert';
import MicroCertTest from '../../UI/SVG/MicroCertTest';
import MicroReminder from '../../UI/SVG/MicroReminder';
import MicroReport from '../../UI/SVG/MicroReport';
import VTIcon from '../../UI/VTIcon';
import VTText from '../../UI/VTText';
import { CertificatesNavigationProp } from '../CertificatesNavigator';

interface Props {
  item: ServiceItem & Id;
}

export default ({ item }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();

  const { icon, listHeading, caption, onPress } = (() => {
    if (RemindersService.isReminder(item)) {
      return {
        icon: <MicroReminder style={styles.icon} />,
        listHeading: strings.productTitle(item.description, item),
        caption: format(item.reminderDate, 'P'),
        onPress: () =>
          navigation.navigate('RemindersDetailScreen', {
            id: item.id,
          }),
      };
    }

    const isReport = ReportsService.isReport(item);

    const dateStr = format(item.serviceDate ?? item.created, 'P');
    const caption = isReport
      ? dateStr
      : `${t('certificates.serviceName', {
          context: strings.serviceContext(item as Certificate),
        })}, ${dateStr}`;

    return {
      icon: (() => {
        if (isReport) {
          return <MicroReport style={styles.icon} />;
        }

        if ((item as Certificate).isTest) {
          return <MicroCertTest style={styles.icon} />;
        }

        return <MicroCert style={styles.icon} />;
      })(),
      listHeading: strings.productTitle(item.product.name, item),
      caption,
      onPress: () =>
        navigation.navigate(
          isReport ? 'ReportsDetailScreen' : 'CertificatesDetailScreen',
          {
            id: item.id,
          },
        ),
    };
  })();

  return (
    <TouchableOpacity onPress={onPress} style={styles.root}>
      {icon}
      <View style={styles.inner}>
        <VTText variant="listHeading">{listHeading}</VTText>
        <VTText variant="caption">{caption}</VTText>
      </View>
      <VTIcon
        name="more"
        size={theme.spacing(3)}
        color={theme.palette.textSecondary}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(4.4),
    marginRight: theme.spacing(1),
  },
  inner: {
    flex: 1,
    padding: theme.spacing(1),
  },
});
