import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import CertificatesFunctionsService from '../../services/CertificatesFunctionsService';
import dialog from '../../util/dialog';
import theme from '../../util/theme';
import VTButton from '../UI/VTButton';
import VTText from '../UI/VTText';

interface Props {
  onResult: (valid: boolean) => void;
}

export default ({ onResult }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const fileInput = useRef<HTMLInputElement>(null);

  const onUpload = async () => {
    if (!file) {
      return;
    }

    setLoading(true);

    try {
      onResult(
        await CertificatesFunctionsService.instance.uploadCertificatePdf(file),
      );
    } catch (e) {
      // noinspection ES6MissingAwait
      dialog.alert(t('error.generic'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <VTText>
        {loading ? t('verify.uploadPending') : t('verify.uploadInstructions')}
      </VTText>
      <View
        style={{ padding: theme.spacing(2), marginBottom: theme.spacing(1) }}
      >
        <input
          ref={fileInput}
          type="file"
          accept="application/pdf"
          disabled={loading}
          onChange={(e) => setFile(e.currentTarget.files?.[0])}
        />
      </View>
      <VTButton
        variant="contained"
        title={t('verify.upload')}
        startIcon="sendFile"
        disabled={!file}
        onPress={onUpload}
        pending={loading}
      />
      {!loading && <VTText variant="caption">{t('verify.uploadHint')}</VTText>}
    </>
  );
};
