import AsyncStorage from '@react-native-async-storage/async-storage';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import { EthnicityInfo, PatientGender, RaceInfo } from 'vacctrack';
import { ProductDataInputData } from '../components/Shared/ProductDataInput';
import { ProductSelectorData } from '../components/Shared/ProductSelector';
import { ServiceDateData } from '../components/Shared/ServiceDateInput';
import { PatientDataInputData } from '../components/Tickets/CreateTicketScreen/PatientDataInput';
import { DateOptions } from './schema';

export const STORAGE_PUSH_PROMPT_HIDDEN = 'storage.show-push-prompt';
export const STORAGE_PUSH_TOKEN = 'storage.push-token';
export const STORAGE_MAIN_SCREEN = 'storage.main-screen';
export const STORAGE_LANGUAGE = 'storage.language';
export const STORAGE_LAST_UPDATE_CHECK = 'storage.last-update-check';
export const STORAGE_STORED_CERTIFICATE_ID = 'storage.stored-certificate-id';
export const STORAGE_STORED_TICKET_PARAM = 'storage.stored-ticket-param';
export const PROOF_PROVIDED_ID = 'proof-provided';

const EXPIRING_SEPARATOR = ':';
const STORED_PARAM_EXPIRY_MINUTES = 30;

export async function storeExpiring(key: string, value: string) {
  await AsyncStorage.setItem(
    key,
    [value, addMinutes(Date.now(), STORED_PARAM_EXPIRY_MINUTES).getTime()].join(
      EXPIRING_SEPARATOR,
    ),
  );
}

export async function getExpiring(key: string) {
  const storedStr = await AsyncStorage.getItem(key);

  if (!storedStr) {
    return undefined;
  }

  await AsyncStorage.removeItem(key);

  const [code, expiryStr] = storedStr.split(EXPIRING_SEPARATOR);

  if (!code || !expiryStr) {
    return undefined;
  }

  if (parseInt(expiryStr, 10) < Date.now()) {
    return undefined;
  }

  return code;
}

export const RACE_DATA: RaceInfo[] = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Native Hawaiian or Other Pacific Islander',
  'White',
  'Other',
];

export const ETHNICITY_DATA: EthnicityInfo[] = [
  'Hispanic or Latino',
  'Not Hispanic or Latino',
];

export const DATE_REGEX = /(\d{2})[-/ ]?(\d{2})[-/ ]?(\d{4})/;

export const STATE_ABBR = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const TICKET_PARAM_NAME = 't';
export const VERIFY_PARAM_NAME = 'c';

export const INITIAL_DATE_DATA = (): ServiceDateData => ({
  serviceDateString: format(Date.now(), 'MM-dd-yyyy'),
  reminderDateString: '',
});

export const INITIAL_PATIENT_DATA: PatientDataInputData = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirthString: '',
};

export const INITIAL_PRODUCT_SELECTOR_DATA: ProductSelectorData = {
  shotNumberOf: 1,
};

export const INITIAL_PRODUCT_DATA: ProductDataInputData = {
  lotNumber: '',
  expiryDateString: '',
  shotNumber: 1,
  shotNumberOf: 1,
};

export const REASONABLE_REMINDER_DATE_RANGE: DateOptions = {
  min: 0, // now
  max: 1262277040000, // 40 years in the future
};
export const GENDER_DATA: PatientGender[] = ['F', 'M'];
